import React, { useMemo, useState } from "react";
import T from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import callApi from "commons/util/callApi";
import { useToast } from "commons/util/useToast";
import Editor from "./Editor";
import { getBufferFromFile, getNextId } from "commons/util/helpers";
import { useRecipeList, useRecipeListDispatch } from "App/RecipeListContext";
import { useUpdateEffect } from "commons/util/useUpdateEffect";
import { useLocationBase } from "commons/util/useLocationBase";
import { useTranslation } from "react-i18next";
import { PLANS, RECIPE_LIMIT } from "commons/util/constants";
import { useAuth } from "App/AuthContext";

function EditorContainer({ className }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationBase = useLocationBase();
  const { showToast } = useToast();
  const dispatch = useRecipeListDispatch();
  const { user } = useAuth();

  const { recipeList } = useRecipeList();
  const recipeId = useParams().recipeId;

  const recipe = useMemo(() => {
    return recipeList.find(el => el.id === recipeId) || {};
  }, [recipeId, recipeList]);

  const {
    title: initTitle = "",
    notes: initNotes = "",
    imageURL: initImageURL = "",
    servings: initServings = 1,
    ingredients: initIngredients = [
      {
        id: 1,
        amount: null,
        unit: "",
        label: "",
        note: "",
      },
      {
        id: 2,
        amount: null,
        unit: "",
        label: "",
        note: "",
      },
      {
        id: 3,
        amount: null,
        unit: "",
        label: "",
        note: "",
      },
    ],
    steps: initSteps = [
      {
        id: 1,
        number: 1,
        text: "<p></p>",
      },
      {
        id: 2,
        number: 2,
        text: "<p></p>",
      },
      {
        id: 3,
        number: 3,
        text: "<p></p>",
      },
    ],
    isFavorite = false,
    neverCooked: initNeverCooked = true,
    source: initSource = "",
    activeTime: initActiveTime = 0,
    totalTime: initTotalTime = 0,
    tags: initTags = [],
    dateCreated,
  } = recipe;

  const [title, setTitle] = useState(initTitle);
  const [tags, setTags] = useState(initTags);
  const [imageURL, setImageURL] = useState(initImageURL);
  const [imageSizeError, setImageSizeError] = useState("");
  const [activeTime, setActiveTime] = useState(initActiveTime);
  const [totalTime, setTotalTime] = useState(initTotalTime);
  const [neverCooked, setNeverCooked] = useState(initNeverCooked);
  const [source, setSource] = useState(initSource);
  const [ingredients, setIngredients] = useState(initIngredients);
  const [steps, setSteps] = useState(initSteps);
  const [notes, setNotes] = useState(initNotes);
  const [servings, setServings] = useState(initServings);

  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [isImageUploadInProgress, setIsImageUploadInProgress] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  useUpdateEffect(() => {
    if (!hasChanged) setHasChanged(true);
  }, [title, tags, imageURL, activeTime, totalTime, neverCooked, source, ingredients, steps, notes, servings]);

  function closeModal() {
    if (!hasChanged || (hasChanged && window.confirm(t("Common.DiscardChanges")))) {
      navigate(locationBase);
    }
  }

  function goBackToPreview() {
    if (!hasChanged || (hasChanged && window.confirm(t("Common.DiscardChanges")))) {
      navigate(recipeId ? `${locationBase}/${recipeId}` : locationBase);
    }
  }

  function addStep() {
    setSteps(oldSteps => [
      ...oldSteps,
      {
        id: getNextId(oldSteps) + 1, // dnd-kit identifiers must be higher than 0
        number: oldSteps.length + 1,
        text: "<p></p>",
      },
    ]);
  }

  function setStepText(stepId, text) {
    setSteps(oldSteps => {
      return oldSteps.map(el => {
        if (el.id === stepId) {
          return { ...el, text: text };
        } else {
          return el;
        }
      });
    });
  }

  function deleteStep(stepId) {
    setSteps(oldSteps => oldSteps.filter(el => el.id !== stepId).map((el, i) => ({ ...el, number: i + 1 })));
  }

  function addIngredient() {
    setIngredients(oldIngredients => [
      ...oldIngredients,
      {
        id: !oldIngredients.length ? 1 : getNextId(oldIngredients), // dnd-kit identifiers must be bigger than 0
        amount: null,
        unit: "",
        label: "",
        note: "",
      },
    ]);
  }

  function setIngredient(ingredientId, property, value) {
    setIngredients(oldIngredients =>
      oldIngredients.map(el => {
        if (el.id === ingredientId) {
          return { ...el, [property]: value };
        } else {
          return el;
        }
      })
    );
  }

  function deleteIngredient(ingredientId) {
    setIngredients(oldIngredients => oldIngredients.filter(el => el.id !== ingredientId));
  }

  function addIngredientCategory() {
    setIngredients(oldIngredients => [
      ...oldIngredients,
      {
        id: getNextId(oldIngredients),
        label: "",
        isCategory: true,
      },
    ]);
  }

  function setIngredientCategory(ingredientCategoryId, value) {
    setIngredients(oldIngredients =>
      oldIngredients.map(el => {
        if (el.id === ingredientCategoryId) {
          return { ...el, label: value };
        } else return el;
      })
    );
  }

  function uploadImage(e) {
    setIsImageUploadInProgress(true);

    const file = e.target.files[0];
    if (file.size > 8000000) {
      setImageSizeError(t("Recipes.ImageSizeError"));
      setIsImageUploadInProgress(false);
    } else {
      setImageSizeError("");
      getBufferFromFile(file).then(async buffer => {
        try {
          const { imageUrl } = await callApi("images", "post", buffer, { "content-type": file.type });
          setImageURL(imageUrl);
          setIsImageUploadInProgress(false);
        } catch {
          showToast(t("Common.GenericError"), "error");
          setIsImageUploadInProgress(false);
        }
      });
    }
  }

  function deleteImage() {
    setImageURL("");
  }

  async function saveRecipe() {
    setIsSavingInProgress(true);
    // editing existing recipe:
    if (recipeId) {
      const recipe = {
        id: recipeId,
        title: title || t("Recipes.UntitledRecipe"),
        notes: notes,
        imageURL: imageURL,
        servings: servings,
        tagIds: tags.map(el => el.id),
        ingredients: ingredients,
        isFavorite: isFavorite,
        steps: steps,
        dateCreated: dateCreated,
        dateLastEdited: Date.now(),
        neverCooked: neverCooked,
        source: source,
        activeTime: activeTime,
        totalTime: totalTime,
      };

      try {
        await callApi("recipes", "put", recipe);
        dispatch({ type: "UPDATE", recipe: { ...recipe, tags } });
        setIsSavingInProgress(false);
        showToast(t("Recipes.RecipeSaved"));
        navigate(`${locationBase}/${recipeId}`);
      } catch (e) {
        showToast(t("Common.GenericError"), "error");
        setIsSavingInProgress(false);
      }
    }
    // creating new recipe:
    else {
      const recipe = {
        title: title || t("Recipes.UntitledRecipe"),
        notes: notes,
        imageURL: imageURL,
        servings: servings,
        tagIds: tags.map(el => el.id),
        ingredients: ingredients,
        isFavorite: isFavorite,
        steps: steps,
        dateCreated: Date.now(),
        dateLastEdited: Date.now(),
        neverCooked: neverCooked,
        source: source,
        activeTime: activeTime,
        totalTime: totalTime,
      };

      try {
        const res = await callApi("recipes", "post", recipe);
        dispatch({ type: "CREATE", recipe: { ...recipe, id: res.id, tags } });
        setIsSavingInProgress(false);
        showToast(t("Recipes.RecipeSaved"));

        if (recipeList.length === RECIPE_LIMIT - 1 && user.accountType === PLANS.free) {
          // last free recipe created
          navigate(`${locationBase}/upgrade`);
        } else {
          navigate(`${locationBase}/${res.id}`);
        }
      } catch (e) {
        showToast(t("Common.GenericError"), "error");
        setIsSavingInProgress(false);
      }
    }
  }

  return (
    <Editor
      className={className}
      title={title}
      setTitle={setTitle}
      notes={notes}
      setNotes={setNotes}
      imageURL={imageURL}
      deleteImage={deleteImage}
      uploadImage={uploadImage}
      imageSizeError={imageSizeError}
      servings={servings}
      setServings={setServings}
      tags={tags}
      setTags={setTags}
      ingredients={ingredients}
      setIngredients={setIngredients}
      addIngredient={addIngredient}
      setIngredient={setIngredient}
      deleteIngredient={deleteIngredient}
      addIngredientCategory={addIngredientCategory}
      setIngredientCategory={setIngredientCategory}
      steps={steps}
      setSteps={setSteps}
      setStepText={setStepText}
      addStep={addStep}
      deleteStep={deleteStep}
      neverCooked={neverCooked}
      setNeverCooked={setNeverCooked}
      source={source}
      setSource={setSource}
      activeTime={activeTime}
      setActiveTime={setActiveTime}
      totalTime={totalTime}
      setTotalTime={setTotalTime}
      //
      closeModal={closeModal}
      goBackToPreview={goBackToPreview}
      saveRecipe={saveRecipe}
      isSavingInProgress={isSavingInProgress}
      isImageUploadInProgress={isImageUploadInProgress}
    />
  );
}

EditorContainer.propTypes = {
  className: T.string,
};

export default EditorContainer;
