import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components/macro";
import Modal from "commons/components/Modal";
import Button from "commons/components/Button";
// import Tabs from "commons/components/Tabs";
import Steps from "./components/Steps";
import Ingredients from "./components/Ingredients";
import Flexbox from "commons/components/Flexbox";
import Divider from "commons/components/Divider";
import { useKeyPress } from "commons/util/useKeyPress";
import Popover from "commons/components/Popover";
import ActionList from "commons/components/ActionList";
import Typography from "commons/components/Typography";
import Tag from "commons/components/Tag";
import Image from "commons/components/Image";
import { format, formatDistanceToNowStrict } from "date-fns";
import { formatTime, formatStep } from "commons/util/helpers";
import { useNavigate, useParams } from "react-router-dom";
import Detail from "./Detail";
import { useRecipeActions } from "../useRecipeActions";
import Tooltip from "commons/components/Tooltip";
import SanitizedHtml from "commons/components/SanitizedHtml";
import { useRecipeList } from "App/RecipeListContext";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { AnimatePresence, motion } from "framer-motion";
import { useLocationBase } from "commons/util/useLocationBase";
import RecipeImage from "../components/RecipeImage";
import { useTranslation } from "react-i18next";
import { useCurrentLocale } from "commons/util/useCurrentLocale";
import { Helmet } from "react-helmet";
import LinkifyText from "commons/components/LinkifyText";

const StyledModal = styled(Modal)`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1136px;
`;

const OverflowFlexbox = styled(Flexbox)`
  height: 100%;
  overflow: auto;
  padding: 24px 48px 48px;
  border-radius: var(--border-radius-0);

  @media (max-width: ${BREAKPOINTS.small}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const RecipeImageStyled = styled(RecipeImage)`
  width: calc(100% + 96px);
  margin-top: -24px;
  margin-left: -48px;
  margin-right: -48px;
  margin-bottom: 32px;
  cursor: zoom-in;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }
`;

const FullscreenImage = styled(Image)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 90%;
  max-height: 90%;
  z-index: 1;
  margin: auto;
  border-radius: var(--border-radius-3);
  cursor: zoom-out;
`;

const FullscreenImageBackground = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--backdrop);
  z-index: var(--z-index-above);
`;

const DividerWithSpacing = styled(Divider)`
  margin-top: 32px;
  margin-bottom: 24px;
  width: calc(100% + 96px);
  margin-left: -48px;
  margin-right: -48px;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }
`;

const DarkButton = styled(Button)`
  position: absolute;
  top: 50%;
  z-index: var(--z-index-0);

  ${({ position }) => {
    if (position === "left") return "left: -64px;";
    if (position === "right") return "right: -64px;";
  }}

  color: var(--neutral-180);
  background-color: var(--neutral-120);

  &:hover,
  &:focus-visible {
    background-color: var(--neutral-140);
  }
  &:active {
    background-color: var(--neutral-70);
  }
`;

function Preview() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const locationBase = useLocationBase();
  const overflowBox = useRef();
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);
  const currentLocale = useCurrentLocale();

  const [isFullscreenImageShown, setIsFullscreenImageShown] = useState(false);

  const { recipeList } = useRecipeList();

  const recipeIdList = recipeList.map(el => el.id);
  const recipeId = useParams().recipeId;

  const recipe = useMemo(() => {
    return recipeList.find(el => el.id === recipeId);
  }, [recipeId, recipeList]);

  const { recipeActions } = useRecipeActions(recipeId, closePreview);
  const filteredRecipeActions = recipeActions.filter(
    action => action.id !== "start-cooking" && (isScreenMaxSmall || action.id !== "edit")
  );

  const canGoPrevious = useMemo(() => {
    return recipeId !== recipeIdList[0];
  }, [recipeId, recipeIdList]);

  const canGoNext = useMemo(() => {
    return recipeId !== recipeIdList[recipeList.length - 1];
  }, [recipeId, recipeIdList]);

  function goPrevious() {
    const recipeIndex = recipeIdList.findIndex(el => el === recipe.id);
    const previousRecipeId = recipeIdList[recipeIndex - 1];
    navigate(`${locationBase}/${previousRecipeId}`);
  }

  function goNext() {
    const recipeIndex = recipeIdList.findIndex(el => el === recipe.id);
    const nextRecipeId = recipeIdList[recipeIndex + 1];
    navigate(`${locationBase}/${nextRecipeId}`);
  }

  useKeyPress("ArrowLeft", () => {
    if (canGoPrevious) goPrevious();
  });
  useKeyPress("ArrowRight", () => {
    if (canGoNext) goNext();
  });

  function closePreview() {
    navigate(`${locationBase}`);
  }

  const {
    title,
    notes,
    imageURL,
    servings,
    tags,
    ingredients,
    steps,
    dateCreated,
    dateLastEdited,
    source,
    activeTime,
    totalTime,
  } = recipe || {};

  const stepsWithHighlights = steps?.map(el => ({ ...el, text: formatStep(el.text, ingredients) }));

  useEffect(() => {
    if (overflowBox.current && imageURL) overflowBox.current.scrollTop = 240;
  }, [imageURL]);

  useEffect(() => {
    if (!recipe) {
      navigate("/404");
    }
  }, [recipe]);

  if (!recipe) {
    return;
  }

  return (
    <>
      <Helmet>
        <title>{title} | Cook Your Goose</title>
      </Helmet>
      <StyledModal handleClose={closePreview}>
        <Flexbox alignItems="center" justifyContent="space-between" padding="8px 32px">
          <Button onClick={closePreview} icon="close" variant="tertiary">
            {t("Common.Close")}
          </Button>
          {/* <Button icon="open_in_full" variant="tertiary">
          Open as page
        </Button> */}
          {/* <Tabs
          activeTabId="steps"
          tabs={[
            { id: "steps", label: "Steps" },
            { id: "diagram", label: "Diagram" },
          ]}
          setActiveTabId={() => {}}
        /> */}
          <Flexbox gap={12}>
            <Button link={`/app/cooking-mode/${recipeId}`} icon="play_arrow" isIconFilled>
              {t("Recipes.StartCooking")}
            </Button>
            {!isScreenMaxSmall && (
              <Button link={`${locationBase}/${recipeId}/edit`} icon="edit" variant="secondary">
                {t("Common.Edit")}
              </Button>
            )}
            <Popover
              trigger={<Button icon="more_vert" variant="secondary" />}
              content={closePopover => <ActionList actions={filteredRecipeActions} closePopover={closePopover} />}
            />
          </Flexbox>
        </Flexbox>

        <Divider />

        <OverflowFlexbox flexDirection="column" ref={overflowBox}>
          {imageURL && (
            <RecipeImageStyled
              width="100%"
              height="400px"
              alt={t("Recipes.RecipeImage")}
              src={imageURL}
              onClick={() => setIsFullscreenImageShown(true)}
              isRemote
            />
          )}
          <AnimatePresence>
            {isFullscreenImageShown && (
              <FullscreenImageBackground
                onClick={() => setIsFullscreenImageShown(false)}
                key={isFullscreenImageShown}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.2,
                }}
              >
                <FullscreenImage
                  width="auto"
                  height="auto"
                  alt={t("Recipes.RecipeImage")}
                  src={imageURL}
                  isRemote
                  loader="spinner"
                />
              </FullscreenImageBackground>
            )}
          </AnimatePresence>
          <Typography variant={title.length < 42 && !isScreenMaxMedium ? "h1" : "h2"} as="h1" marginBottom={16}>
            {title}
          </Typography>
          {tags && !!tags.length && (
            <Flexbox gap={8} flexWrap="wrap">
              {tags.map(tag => (
                <Tag key={tag.id} icon={tag.icon} label={tag.label} color={tag.color} />
              ))}
            </Flexbox>
          )}
          <Flexbox flexDirection={isScreenMaxSmall ? "column" : "row"} gap={isScreenMaxSmall ? 24 : 40} marginTop={32}>
            <Detail variant="vertical" label={t("Recipes.ActiveTime")} value={formatTime(activeTime)} />
            <Detail variant="vertical" label={t("Recipes.TotalTime")} value={formatTime(totalTime)} />
            <Detail variant="vertical" label={t("Recipes.Source")} value={<LinkifyText>{source || "-"}</LinkifyText>} />
          </Flexbox>
          <DividerWithSpacing />
          <Flexbox
            flexDirection={isScreenMaxMedium ? "column-reverse" : "row"}
            alignItems="flex-start"
            gap={52}
            marginTop={24}
          >
            <Steps steps={stepsWithHighlights} />
            <Ingredients ingredients={ingredients} servings={servings} />
          </Flexbox>
          <DividerWithSpacing />
          <Flexbox flexDirection="column" gap={32}>
            {notes && (
              <Flexbox flexDirection="column" gap={8}>
                <Typography variant="h4">{t("Recipes.NotesAndTips")}</Typography>
                <Typography variant="paragraph">
                  <SanitizedHtml>{notes}</SanitizedHtml>
                </Typography>
              </Flexbox>
            )}

            <Flexbox flexDirection="column" gap={12}>
              <Typography variant="h4">{t("Recipes.Details")}</Typography>
              <Flexbox flexDirection="column" gap={8}>
                <Detail
                  variant="horizontal"
                  icon="event"
                  label={t("Recipes.Created")}
                  value={
                    <Tooltip
                      label={format(new Date(dateCreated), t("Common.FormattedDate"), { locale: currentLocale })}
                    >
                      {formatDistanceToNowStrict(new Date(dateCreated), { addSuffix: true, locale: currentLocale })}
                    </Tooltip>
                  }
                />
                <Detail
                  variant="horizontal"
                  icon="event"
                  label={t("Recipes.LastEdited")}
                  value={
                    dateLastEdited ? (
                      <Tooltip
                        label={format(new Date(dateLastEdited), t("Common.FormattedDate"), { locale: currentLocale })}
                      >
                        {formatDistanceToNowStrict(new Date(dateLastEdited), {
                          addSuffix: true,
                          locale: currentLocale,
                        })}
                      </Tooltip>
                    ) : (
                      "-"
                    )
                  }
                />
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </OverflowFlexbox>

        {canGoPrevious && <DarkButton size="medium" icon="arrow_back" onClick={goPrevious} position="left" />}
        {canGoNext && <DarkButton size="medium" icon="arrow_forward" onClick={goNext} position="right" />}
      </StyledModal>
    </>
  );
}

export default Preview;
