import React, { useMemo } from "react";
import T from "prop-types";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import { useKeyPress } from "commons/util/useKeyPress";
import SanitizedHtml from "commons/components/SanitizedHtml";
import { useTimer } from "commons/util/useTimer";
import { Marker, Step } from "App/Recipes/components/Recipes.styles";
import { useTranslation } from "react-i18next";

function Steps({ className, steps, activeStepNumber, setActiveStepNumber }) {
  const { t } = useTranslation();
  const { showTimer } = useTimer();

  const canGoPrevious = useMemo(() => {
    return activeStepNumber && activeStepNumber !== 1;
  }, [activeStepNumber]);

  const canGoNext = useMemo(() => {
    return activeStepNumber && activeStepNumber !== steps.length;
  }, [activeStepNumber]);

  function goPrevious() {
    setActiveStepNumber(activeStepNumber - 1);
  }

  function goNext() {
    setActiveStepNumber(activeStepNumber + 1);
  }

  useKeyPress("ArrowLeft", () => {
    if (canGoPrevious) goPrevious();
  });
  useKeyPress("ArrowUp", () => {
    if (canGoPrevious) goPrevious();
  });
  useKeyPress("ArrowRight", () => {
    if (canGoNext) goNext();
  });
  useKeyPress("ArrowDown", () => {
    if (canGoNext) goNext();
  });

  function onStepClick(event, stepNumber) {
    if (event.target.className === "timer") {
      showTimer(Number(event.target.value), stepNumber);
    } else if (stepNumber !== activeStepNumber) {
      setActiveStepNumber(stepNumber);
    }
  }

  return (
    <Flexbox className={className} gap={24} flexDirection="column" flexGrow={1}>
      <Flexbox alignItems="center" justifyContent="space-between">
        <Typography variant="h3">{t("Recipes.Directions")}</Typography>
        <Flexbox gap={8}>
          <Button onClick={goPrevious} variant="tertiary" icon="arrow_back" disabled={!canGoPrevious} />
          <Button onClick={goNext} variant="tertiary" icon="arrow_forward" disabled={!canGoNext} />
        </Flexbox>
      </Flexbox>
      <Flexbox flexDirection="column" justifyContent="center" gap={24} flexGrow={1}>
        {steps.map(step => (
          <Step
            key={step.number}
            alignItems="flex-start"
            gap={16}
            isHighlighted={step.number === activeStepNumber}
            onClick={event => onStepClick(event, step.number)}
          >
            <Marker>{step.number}.</Marker>
            <Typography variant="paragraph" as="div">
              <SanitizedHtml>{step.text}</SanitizedHtml>
            </Typography>
          </Step>
        ))}
      </Flexbox>
    </Flexbox>
  );
}

Steps.propTypes = {
  className: T.string,
  steps: T.arrayOf(T.object),
  activeStepNumber: T.number,
  setActiveStepNumber: T.func,
};

export default Steps;
