import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Button from "commons/components/Button";
import Modal from "commons/components/Modal";
import Divider from "commons/components/Divider";
import TextInput from "commons/components/TextInput";
import TypographyInput from "commons/components/TypographyInput";
import DurationInput from "commons/components/DurationInput";
import Checkbox from "commons/components/Checkbox";
import Ingredients from "./components/Ingredients/Ingredients";
import TagInput from "./components/TagInput";
import Steps from "./components/Steps/Steps";
import ImageInput from "./components/ImageInput";
import Tiptap from "commons/components/Tiptap";
import Typography from "commons/components/Typography";
import ErrorBoundary from "App/ErrorBoundary";
import { useKeyPress } from "commons/util/useKeyPress";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useTranslation } from "react-i18next";

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  /* width: 75%; */
  max-width: 100%;
  width: 1136px;
`;

const OverflowFlexbox = styled(Flexbox)`
  height: 100%;
  overflow: auto;
  overflow: overlay;
  padding: 24px 32px 40px;

  @media (max-width: ${BREAKPOINTS.small}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const DurationInputsWrap = styled(Flexbox)`
  max-width: 240px;
`;

const Label = styled(Typography)`
  display: inline-block;
  margin-bottom: 4px;
`;

function Editor({
  className,
  title,
  setTitle,
  notes,
  setNotes,
  imageURL,
  deleteImage,
  uploadImage,
  imageSizeError,
  servings,
  setServings,
  tags,
  setTags,
  ingredients,
  setIngredients,
  addIngredient,
  setIngredient,
  deleteIngredient,
  addIngredientCategory,
  setIngredientCategory,
  steps,
  setSteps,
  setStepText,
  addStep,
  deleteStep,
  neverCooked,
  setNeverCooked,
  source,
  setSource,
  activeTime,
  setActiveTime,
  totalTime,
  setTotalTime,
  //
  closeModal,
  goBackToPreview,
  saveRecipe,
  isSavingInProgress,
  isImageUploadInProgress,
}) {
  const { t } = useTranslation();
  useKeyPress(
    "s",
    e => {
      e.preventDefault();
      saveRecipe();
    },
    true
  );

  return (
    <StyledModal handleClose={closeModal} className={className}>
      <Flexbox alignItems="center" justifyContent="space-between" margin="8px 32px">
        <Button icon="close" variant="tertiary" onClick={goBackToPreview}>
          {t("Common.Cancel")}
        </Button>
        {/* <Tabs
          activeTabId="steps"
          tabs={[
            { id: "steps", label: "Steps" },
            { id: "diagram", label: "Diagram" },
          ]}
          setActiveTabId={() => {}}
        /> */}
        <Flexbox gap={12}>
          <Button onClick={saveRecipe} loading={isSavingInProgress}>
            {t("Common.Save")}
          </Button>
        </Flexbox>
      </Flexbox>

      <Divider />

      <OverflowFlexbox flexDirection="column" alignItems="stretch" gap={24}>
        <ImageInput
          imageURL={imageURL}
          deleteImage={deleteImage}
          uploadImage={uploadImage}
          imageSizeError={imageSizeError}
          loading={isImageUploadInProgress}
        />

        <TypographyInput
          value={title}
          onChange={setTitle}
          placeholder={t("Recipes.RecipeTitle")}
          variant="h2"
          autoFocus
          maxLength={200}
        />

        <TextInput
          label={t("Recipes.Source")}
          value={source}
          onChange={setSource}
          placeholder={t("Recipes.SourcePlaceholder")}
          maxLength={800}
        />

        <TagInput selectedTags={tags} setSelectedTags={setTags} />

        <DurationInputsWrap flexDirection="row" gap={16} justifyContent="flex-start" flexWrap="wrap">
          <DurationInput label={t("Recipes.ActiveTime")} value={activeTime} onChange={setActiveTime} />
          <DurationInput label={t("Recipes.TotalTime")} value={totalTime} onChange={setTotalTime} />
        </DurationInputsWrap>

        <Checkbox
          label={t("Recipes.NeverCookedBefore")}
          isChecked={neverCooked}
          onChange={isChecked => setNeverCooked(!isChecked)}
        />

        <Ingredients
          servings={servings}
          setServings={setServings}
          ingredients={ingredients}
          setIngredients={setIngredients}
          addIngredient={addIngredient}
          addIngredientCategory={addIngredientCategory}
          setIngredient={setIngredient}
          setIngredientCategory={setIngredientCategory}
          deleteIngredient={deleteIngredient}
        />

        <Steps steps={steps} setSteps={setSteps} setStepText={setStepText} deleteStep={deleteStep} addStep={addStep} />

        <div>
          <Label variant="label" color="neutral-120">
            {t("Recipes.NotesAndTips")}
          </Label>
          <Tiptap
            content={notes}
            placeholder={t("Recipes.NotesAndTipsPlaceholder")}
            onChange={value => setNotes(value)}
          />
        </div>
      </OverflowFlexbox>
    </StyledModal>
  );
}

Editor.propTypes = {
  className: T.string,
  id: T.number,
  title: T.string,
  notes: T.string,
  imageURL: T.string,
  servings: T.number,
  ingredients: T.array,
  steps: T.array,
  neverCooked: T.bool,
  source: T.string,
  activeTime: T.number,
  totalTime: T.number,
  tags: T.array,
  //
  setTitle: T.func,
  setNotes: T.func,
  deleteImage: T.func,
  uploadImage: T.func,
  imageSizeError: T.string,
  setServings: T.func,
  setTags: T.func,
  setIngredients: T.func,
  addIngredient: T.func,
  setIngredient: T.func,
  deleteIngredient: T.func,
  addIngredientCategory: T.func,
  setIngredientCategory: T.func,
  setSteps: T.func,
  addStep: T.func,
  setStepText: T.func,
  deleteStep: T.func,
  setNeverCooked: T.func,
  setSource: T.func,
  setActiveTime: T.func,
  setTotalTime: T.func,
  //
  closeModal: T.func,
  goBackToPreview: T.func,
  saveRecipe: T.func,
  isSavingInProgress: T.bool,
  isImageUploadInProgress: T.bool,
};

export default ErrorBoundary(Editor);
