import React from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import MealItem from "./MealItem";
import Popover from "commons/components/Popover";
import AddItemPopoverContent from "./AddItemPopoverContent";
import { MEAL_COLORS } from "commons/util/constants";
import Flexbox from "commons/components/Flexbox";
import Button from "commons/components/Button";
import Typography from "commons/components/Typography";
import { useTranslation } from "react-i18next";
import { useDroppable } from "@dnd-kit/core";

const Box = styled(Flexbox)`
  flex-direction: column;
  padding: 12px;
  border-bottom: 1px solid var(--neutral-180);
  border-radius: var(--border-radius-0);

  &:last-child {
    border-bottom: none;
  }

  ${({ isOver }) =>
    isOver &&
    css`
      background-color: var(--neutral-190);
    `}
`;

const Label = styled(Typography)`
  display: flex;
  align-items: center;

  &:before {
    content: "";
    display: block;
    background-color: ${({ dotColor }) => `var(--${dotColor}-100)`};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

function MobileMealBlock({ date, mealType, dateMealItems = [] }) {
  const { t } = useTranslation();

  const mealItems = dateMealItems.filter(meal => meal.mealType === mealType);

  const { setNodeRef, isOver } = useDroppable({
    id: `${date}-${mealType}`,
    data: { date: date, mealType: mealType },
  });

  return (
    <Box ref={setNodeRef} isOver={isOver}>
      <Flexbox alignItems="center" justifyContent="space-between">
        <Label variant="label" dotColor={MEAL_COLORS[mealType]}>
          {t(`Calendar.${mealType}`)}
        </Label>
        <Popover
          trigger={<Button variant="secondary" icon="add" onClick={() => {}} />}
          content={closePopover => <AddItemPopoverContent date={date} mealType={mealType} handleClose={closePopover} />}
        />
      </Flexbox>
      {!!mealItems.length && (
        <Flexbox flexDirection="column" gap={8} marginTop={12}>
          {mealItems.map(mealItem => {
            return <MealItem key={mealItem.id} mealItem={mealItem} />;
          })}
        </Flexbox>
      )}
    </Box>
  );
}

MobileMealBlock.propTypes = {
  date: T.instanceOf(Date).isRequired,
  mealType: T.string.isRequired,
  dateMealItems: T.array.isRequired,
};

export default MobileMealBlock;
