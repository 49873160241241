import React, { useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Popover from "commons/components/Popover";
import Button from "commons/components/Button";
import Typography from "commons/components/Typography";
import SanitizedHtml from "commons/components/SanitizedHtml";
import EmptyState from "commons/components/EmptyState";
import noNotesImage from "commons/images/emptyState/notes.png"; // TODO: Replace with an svg
import { useTranslation } from "react-i18next";
import Flexbox from "commons/components/Flexbox";
import Tiptap from "commons/components/Tiptap";
import callApi from "commons/util/callApi";
import { useRecipeListDispatch } from "App/RecipeListContext";
import { useToast } from "commons/util/useToast";
import { BREAKPOINTS } from "commons/util/breakpoints";

const Box = styled.div`
  width: 350px;

  @media (max-width: ${BREAKPOINTS.small}) {
    max-width: 100%;
  }
`;

const Header = styled(Flexbox)`
  position: sticky;
  top: 0;
  z-index: var(--z-index-above);
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background-color: var(--neutral-200);
  border-bottom: 1px solid var(--neutral-180);
  border-radius: var(--border-radius-0);
`;

const Content = styled.div`
  padding: 16px;
`;

function Notes({ recipe }) {
  const { t } = useTranslation();
  const [isNoteEditorOpen, setIsNoteEditorOpen] = useState(false);
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [notes, setNotes] = useState(recipe.notes);
  const dispatch = useRecipeListDispatch();
  const showToast = useToast();

  function openNoteEditor(e) {
    setIsNoteEditorOpen(true);
  }

  function closeNoteEditor(e) {
    setIsNoteEditorOpen(false);
  }

  async function saveNotes() {
    setIsSavingInProgress(true);
    try {
      await callApi("recipes", "put", { ...recipe, notes });
      dispatch({ type: "UPDATE", recipe: { ...recipe, notes } });
      closeNoteEditor();
    } catch (e) {
      showToast(t("Common.GenericError"), "error");
    } finally {
      setIsSavingInProgress(false);
    }
  }

  return (
    <Popover
      trigger={
        <Button variant="tertiary" icon="sticky_note_2">
          {t("Recipes.Notes")}
        </Button>
      }
      content={
        <Box>
          <Header>
            <Typography variant="h4">{t("Recipes.Notes")}</Typography>
            {isNoteEditorOpen ? (
              <Flexbox gap={8}>
                <Button variant="tertiary" onClick={closeNoteEditor}>
                  {t("Common.Cancel")}
                </Button>
                <Button variant="secondary" onClick={saveNotes} loading={isSavingInProgress}>
                  {t("Common.Save")}
                </Button>
              </Flexbox>
            ) : (
              <Button variant={"tertiary"} icon={recipe.notes ? "edit" : "add"} onClick={openNoteEditor}>
                {recipe.notes ? t("Common.Edit") : t("Recipes.AddNotes")}
              </Button>
            )}
          </Header>
          <Content>
            {!isNoteEditorOpen && (
              <>
                {recipe.notes ? (
                  <Typography variant="paragraph">
                    <SanitizedHtml>{recipe.notes}</SanitizedHtml>
                  </Typography>
                ) : (
                  <EmptyState image={noNotesImage} label={t("Recipes.NoNotes")} height="240px" />
                )}
              </>
            )}
            {isNoteEditorOpen && (
              <Tiptap
                content={notes}
                placeholder={t("Recipes.NotesAndTipsPlaceholder")}
                onChange={value => setNotes(value)}
              />
            )}
          </Content>
        </Box>
      }
      placement="bottom-end"
      contentStyles={{ maxHeight: "350px", maxWidth: "calc(100% - 48px)" }}
      onClose={closeNoteEditor}
    />
  );
}

Notes.propTypes = {
  notes: T.string,
  recipe: T.object,
};

export default Notes;
