import React, { useCallback, useEffect, useRef, useState } from "react";
import T from "prop-types";
import callApi from "commons/util/callApi";
import { getNextId } from "commons/util/helpers";
import Tags from "./Tags";
import { useTagGroups, useTagGroupsDispatch } from "App/TagGroupsContext";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

async function saveTagGroups(tagGroups) {
  try {
    await callApi("tags", "post", { tagGroups: tagGroups });
  } catch (e) {
    console.log(e);
  }
}

const getGroupColor = group => {
  if (group.tags.length === 0) {
    return group.color || "grey";
  }

  let firstColor = group.tags[0].color;

  if (group.tags.some(t => t.color !== firstColor)) {
    return "";
  }

  return firstColor;
};

function TagsContainer({ className }) {
  const { t } = useTranslation();
  const [editedTagId, setEditedTagId] = useState();
  const [editedTagGroupId, setEditedTagGroupId] = useState();
  const [tagGroups, setTagGroups] = useState();
  const initComplete = useRef(false);

  const { tagGroups: tagGroupsInit, isLoading } = useTagGroups();
  const dispatch = useTagGroupsDispatch();

  useEffect(() => {
    if (!isLoading && typeof tagGroups === "undefined") {
      setTagGroups(tagGroupsInit);
    }
  }, [isLoading, tagGroupsInit]);

  const syncData = useCallback(
    debounce(async groups => {
      await saveTagGroups(groups);
      dispatch({ type: "UPDATE", tagGroups: groups });
    }, 1000),
    []
  );

  useEffect(() => {
    if (!isLoading && typeof tagGroups !== "undefined") {
      if (initComplete.current) {
        syncData(tagGroups);
      } else {
        initComplete.current = true;
      }
    }
  }, [tagGroups]);

  async function addTagGroup() {
    const newGroup = {
      id: getNextId(tagGroups),
      label: t("Settings.NewTagGroup"),
      tags: [],
      //
      column: tagGroups.length % 2,
    };

    const newGroups = [...tagGroups, newGroup];
    setTagGroups(newGroups);

    window.scroll({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }

  async function deleteTagGroup(groupId) {
    const newGroups = tagGroups.filter(el => el.id !== groupId);

    setTagGroups(newGroups);
  }

  async function updateTagGroupLabel(groupId, value) {
    const newGroups = [...tagGroups];
    newGroups.find(el => el.id === groupId).label = value;

    setTagGroups(newGroups);
  }

  async function updateTagGroupColor(groupId, value) {
    const newGroups = [...tagGroups];
    const tagGroup = newGroups.find(g => g.id === groupId);
    const tags = tagGroup.tags;

    if (tags.length) {
      tags.forEach(tag => {
        tag.color = value;
      });
    } else {
      tagGroup.color = value;
    }

    setTagGroups(newGroups);
  }

  async function reorderTags(groupId, tags) {
    const newGroups = [...tagGroups];
    newGroups.find(el => el.id === groupId).tags = tags;

    setTagGroups(newGroups);
  }

  async function deleteTag(groupId, tagId) {
    const newGroups = [...tagGroups];
    const newGroupTags = newGroups.find(el => el.id === groupId).tags.filter(el => el.id !== tagId);
    newGroups.find(el => el.id === groupId).tags = newGroupTags;

    setTagGroups(newGroups);
  }

  async function addTag(groupId) {
    const group = tagGroups.find(el => el.id === groupId);
    const groupColor = getGroupColor(group);
    const newTag = {
      id: getNextId(tagGroups.map(el => el.tags).flat()) + 1, // dnd-kit identifiers must be higher than 0
      label: t("Settings.NewTag"),
      // icon: null,
      color: groupColor || "grey",
      groupId: groupId,
      count: 0,
    };
    const newGroups = [...tagGroups];
    newGroups.find(g => g.id === groupId).tags.push(newTag);

    setTagGroups(newGroups);
  }

  async function updateTag(groupId, tagId, tagData) {
    const newGroups = [...tagGroups];
    const tagGroup = newGroups.find(g => g.id === groupId);
    const tagIndex = tagGroup.tags.findIndex(t => t.id === tagId);
    tagGroup.tags[tagIndex] = { ...tagGroup.tags[tagIndex], ...tagData };

    setTagGroups(newGroups);
  }

  return (
    <>
      <Helmet>
        <title>{t("Navigation.Tags")} | Cook Your Goose</title>
      </Helmet>
      <Tags
        className={className}
        loading={isLoading}
        tagGroups={tagGroups || []}
        editedTagGroupId={editedTagGroupId}
        setEditedTagGroupId={setEditedTagGroupId}
        addTagGroup={addTagGroup}
        updateTagGroupLabel={updateTagGroupLabel}
        updateTagGroupColor={updateTagGroupColor}
        deleteTagGroup={deleteTagGroup}
        reorderTags={reorderTags}
        editedTagId={editedTagId}
        setEditedTagId={setEditedTagId}
        addTag={addTag}
        updateTag={updateTag}
        deleteTag={deleteTag}
        getGroupColor={getGroupColor}
      />
    </>
  );
}

TagsContainer.propTypes = {
  className: T.string,
};

export default TagsContainer;
