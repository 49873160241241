import React from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Icon from "commons/components/Icon";
import SanitizedHtml from "commons/components/SanitizedHtml";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useMediaQuery } from "commons/util/useMediaQuery";

const AddIcon = styled(Icon)``;

const Box = styled(Flexbox)`
  cursor: pointer;

  strong {
    color: var(--primary-100);
  }

  &:hover,
  &:focus {
    background-color: var(--neutral-190);

    ${AddIcon} {
      color: var(--primary-100);
    }
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: var(--neutral-190);
    `}

  @media (max-width: ${BREAKPOINTS.medium}) {
    text-wrap: nowrap;
    background-color: var(--neutral-190);
    border-radius: var(--border-radius-3);
  }
`;

function SuggestedItem({ className, id, label, isAdded, isHighlighted, toggleGroceryListItem, newItemLabel }) {
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);

  function getLabelWithHighlightedPart(label) {
    if (newItemLabel) {
      return label.replace(newItemLabel, `<strong>${newItemLabel}</strong>`);
    }
    return label;
  }

  return (
    <Box
      className={className}
      tabIndex="0"
      padding={isScreenMaxMedium ? "4px 8px 4px 4px" : "6px 4px"}
      marginX={isScreenMaxMedium ? 2 : -4}
      gap={isScreenMaxMedium ? 2 : 8}
      onClick={() => toggleGroceryListItem(id, label)}
      isHighlighted={isHighlighted}
    >
      <AddIcon
        size={20}
        name={isAdded ? "check_circle" : "add"}
        color={isAdded ? "red-100" : "neutral-120"}
        isFilled={isAdded}
      />
      <SanitizedHtml>{getLabelWithHighlightedPart(label)}</SanitizedHtml>
    </Box>
  );
}

SuggestedItem.propTypes = {
  className: T.string,
  id: T.string,
  label: T.string,
  isAdded: T.bool,
  isHighlighted: T.bool,
  toggleGroceryListItem: T.func,
  newItemLabel: T.string,
};

export default SuggestedItem;
