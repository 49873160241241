import React, { useState } from "react";
import styled, { css } from "styled-components/macro";
import T from "prop-types";
import Flexbox from "commons/components/Flexbox";
import Icon from "commons/components/Icon";
import { Link } from "react-router-dom";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import Popover from "commons/components/Popover";
import { useLongPress } from "use-long-press";
import EditGroceryItemPopoverContent from "./EditGroceryItemPopoverContent";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";

const HiddenButton = styled(Button)`
  @media (hover: hover) {
    opacity: 0;
  }

  &:focus-visible,
  &:focus-within {
    opacity: 1;
  }
`;

const Box = styled(Flexbox)`
  &:hover {
    @media (hover: hover) {
      background-color: var(--neutral-190);
    }
  }

  &:hover {
    ${HiddenButton} {
      opacity: 1;
    }
  }
`;

const ClickableArea = styled(Flexbox)`
  width: 100%;
  height: 36px;
  cursor: pointer;
`;

const Check = styled.div`
  width: 16px;
  height: 16px;
  background-color: var(--neutral-200);
  border: 1px solid var(--neutral-170);
  border-radius: 50%;
  color: var(--neutral-200);
  font-size: 16px;
  flex-shrink: 0;
  margin-top: 2px;

  ${({ isChecked }) =>
    isChecked &&
    css`
      border: none;
      background-color: var(--neutral-180);
    `}
`;

const RecipeTitle = styled(Link)`
  margin-left: auto;
  color: var(--primary-100);
`;

function GroceryItem({
  className,
  itemId,
  label,
  amount,
  unit,
  isChecked,
  recipeTitle,
  recipeId,
  toggleGroceryItem,
  removeGroceryItem,
  editGroceryItem,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);
  const onLongPress = useLongPress(e => {
    e.stopPropagation();
    e.preventDefault();
    setIsEditing(true);
  });

  return (
    <Box className={className} isChecked={isChecked} alignItems="center" marginLeft={-8}>
      <ClickableArea
        alignItems="center"
        onClick={() => {
          if (!isEditing) toggleGroceryItem(itemId);
        }}
        tabIndex="0"
        gap={8}
        padding={2}
        paddingLeft={8}
        {...onLongPress()}
      >
        <Check className="check" isChecked={isChecked}>
          {isChecked && <Icon name="check" />}
        </Check>
        <Typography color={isChecked ? "neutral-170" : "neutral-100"}>
          {label}
          <Typography color={isChecked ? "neutral-170" : "neutral-160"} as="span" marginLeft={8}>
            {amount && +amount.toFixed(2)} {unit}
          </Typography>
        </Typography>
        {recipeTitle && (
          <RecipeTitle onClick={e => e.stopPropagation()} to={`/app/groceries/${recipeId}`}>
            {recipeTitle}
          </RecipeTitle>
        )}
      </ClickableArea>
      <Flexbox padding="2px 4px 2px 0" gap={4}>
        <Popover
          trigger={isScreenMaxSmall ? null : <HiddenButton icon="edit" variant="tertiary" />}
          content={handleClose => {
            return (
              <EditGroceryItemPopoverContent
                itemId={itemId}
                label={label}
                amount={amount}
                unit={unit}
                editGroceryItem={editGroceryItem}
                onClose={handleClose}
              />
            );
          }}
          onClose={() => setIsEditing(false)}
          isOpen={isEditing}
        />
        <HiddenButton
          icon="close"
          variant="tertiary"
          onClick={e => {
            e.stopPropagation();
            removeGroceryItem(itemId);
          }}
        />
      </Flexbox>
    </Box>
  );
}

GroceryItem.propTypes = {
  className: T.string,
  itemId: T.string,
  label: T.string,
  amount: T.oneOfType([T.string, T.number]),
  unit: T.string,
  isChecked: T.bool,
  recipeTitle: T.string,
  recipeId: T.string,
  toggleGroceryItem: T.func,
  removeGroceryItem: T.func,
  editGroceryItem: T.func,
};

export default GroceryItem;
