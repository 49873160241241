import React, { useMemo, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import Button from "commons/components/Button";
import Divider from "commons/components/Divider";
import DateInput from "commons/components/DateInput";
import Tag from "commons/components/Tag";
import Loader from "commons/components/Loader";
import { useTagGroups } from "App/TagGroupsContext";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import Filter from "./Filter";
import callLocalStorage from "commons/util/callLocalStorage";
import { useTranslation } from "react-i18next";
import NumberInput from "commons/components/NumberInput";

const Box = styled.div`
  width: 944px;
  max-width: calc(100vw - 80px);

  @media (max-width: ${BREAKPOINTS.small}) {
    max-width: 100vw;
  }
`;

const RightColumnFlexbox = styled(Flexbox)`
  width: 400px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }
`;

function transformTagGroupsIntoTagOptions(tagGroups) {
  return tagGroups
    .map(el => {
      const rememberedIsExpanded = callLocalStorage(`tag-group-panel-expanded-${el.id}`, "get");
      return {
        id: el.id,
        label: el.label,
        items: el.tags.map(t => ({
          id: t.id,
          count: t.count,
          label: <Tag label={t.label} color={t.color} icon={t.icon} />,
          searchText: t.label.toLowerCase(),
        })),
        isExpanded: rememberedIsExpanded === null ? true : rememberedIsExpanded,
      };
    })
    .filter(el => !!el.items.length);
}

function FilterPopoverContent({ className, closePopover, filters, setFilters }) {
  const { t } = useTranslation();
  const { tagGroups, isLoading } = useTagGroups();
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);

  const [selectedTags, setSelectedTags] = useState(filters.tags);
  const [dateRangeCreated, setDateRangeCreated] = useState(filters.dateCreated || []);
  const [dateRangeLastModified, setDateRangeLastModified] = useState(filters.dateLastEdited || []);
  const [activeTimeMin, setActiveTimeMin] = useState(filters.activeTimeMin);
  const [activeTimeMax, setActiveTimeMax] = useState(filters.activeTimeMax);
  const [totalTimeMin, setTotalTimeMin] = useState(filters.totalTimeMin);
  const [totalTimeMax, setTotalTimeMax] = useState(filters.totalTimeMax);

  const tagOptions = useMemo(() => {
    return transformTagGroupsIntoTagOptions(tagGroups);
  }, [tagGroups]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box className={className}>
      <Typography variant="h3" margin={24}>
        {t("Recipes.FilterRecipes")}
      </Typography>
      <Flexbox margin={24} gap={40} flexWrap={isScreenMaxMedium ? "wrap" : "nowrap"}>
        <Filter
          label={t("Tags.Tags")}
          selectedItems={selectedTags}
          setSelectedItems={setSelectedTags}
          searchPlaceholder={t("Tags.SearchTags")}
          options={tagOptions}
        />
        <RightColumnFlexbox flexDirection="column" gap={24}>
          <DateInput
            label={t("Recipes.DateCreated")}
            placeholder={t("Common.SelectPlaceholder")}
            value={dateRangeCreated}
            onChange={setDateRangeCreated}
            isRange
          />
          <DateInput
            label={t("Recipes.DateLastEdited")}
            placeholder={t("Common.SelectPlaceholder")}
            value={dateRangeLastModified}
            onChange={setDateRangeLastModified}
            isRange
          />
          <Flexbox alignItems="flex-end" gap={8}>
            <NumberInput
              label={t("Recipes.ActiveTime")}
              placeholder={t("Common.Min")}
              value={activeTimeMin}
              onChange={setActiveTimeMin}
              unit={activeTimeMin ? "min" : null}
              min={0}
              step={5}
            />
            <NumberInput
              placeholder={t("Common.Max")}
              value={activeTimeMax}
              onChange={setActiveTimeMax}
              unit={activeTimeMax ? "min" : null}
              min={0}
              step={5}
            />
          </Flexbox>
          <Flexbox alignItems="flex-end" gap={8}>
            <NumberInput
              label={t("Recipes.TotalTime")}
              placeholder={t("Common.Min")}
              value={totalTimeMin}
              onChange={setTotalTimeMin}
              unit={totalTimeMin ? "min" : null}
              min={0}
              step={5}
            />
            <NumberInput
              placeholder={t("Common.Max")}
              value={totalTimeMax}
              onChange={setTotalTimeMax}
              unit={totalTimeMax ? "min" : null}
              min={0}
              step={5}
            />
          </Flexbox>
        </RightColumnFlexbox>
      </Flexbox>
      <Divider />

      <Flexbox justifyContent="space-between" padding="16px 24px" gap={8}>
        <Button
          onClick={() => {
            setSelectedTags([]);
            setDateRangeCreated([]);
            setDateRangeLastModified([]);
            setActiveTimeMin(undefined);
            setActiveTimeMax(undefined);
            setTotalTimeMin(undefined);
            setTotalTimeMax(undefined);
          }}
          variant="tertiary"
        >
          {t("Recipes.ClearAllFilters")}
        </Button>
        <Flexbox justifyContent="flex-end" gap={8}>
          <Button onClick={closePopover} variant="tertiary">
            {t("Common.Cancel")}
          </Button>
          <Button
            onClick={() => {
              setFilters(oldFilters => ({
                ...oldFilters,
                tags: selectedTags,
                dateCreated: dateRangeCreated,
                dateLastEdited: dateRangeLastModified,
                activeTimeMin,
                activeTimeMax,
                totalTimeMin,
                totalTimeMax,
              }));
              closePopover();
            }}
          >
            {t("Recipes.ApplyFilters")}
          </Button>
        </Flexbox>
      </Flexbox>
    </Box>
  );
}

FilterPopoverContent.propTypes = {
  className: T.string,
  closePopover: T.func,
  filters: T.object,
  setFilters: T.func,
};

export default FilterPopoverContent;
