import React, { useEffect, useState } from "react";
import { useFilteredRecipes } from "commons/util/useFilteredRecipes";
import Recipes from "./Recipes";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function RecipesContainer() {
  const { t } = useTranslation();
  const [activeTabId, setActiveTabId] = useState("all");
  const [activeViewTabId, setActiveViewTabId] = useState("grid");
  const [searchString, setSearchString] = useState("");

  const [searchParams] = useSearchParams();
  const searchParamsTagId = searchParams.get("tagId") && [+searchParams.get("tagId")];

  const [filters, setFilters] = useState({
    search: searchString,
    recent: false, // Recipes created less than a week ago
    favorite: false,
    neverCooked: false,
    tags: searchParamsTagId || [],
    dateCreated: [],
    dateLastEdited: [],
    activeTimeMin: undefined,
    activeTimeMax: undefined,
    totalTimeMin: undefined,
    totalTimeMax: undefined,
  });

  const hasActiveFilters =
    !!filters.tags.length ||
    !!filters.dateCreated.length ||
    !!filters.dateLastEdited.length ||
    !!filters.activeTimeMin ||
    !!filters.activeTimeMax ||
    !!filters.totalTimeMin ||
    !!filters.totalTimeMax;

  useEffect(() => {
    setFilters(oldFilters => ({ ...oldFilters, search: searchString }));
  }, [searchString]);

  useEffect(() => {
    setFilters(oldFilters => ({
      ...oldFilters,
      all: false,
      recent: false,
      favorite: false,
      neverCooked: false,
      [activeTabId]: true,
    }));
  }, [activeTabId]);

  const { filteredRecipeList, isLoading } = useFilteredRecipes(filters);

  return (
    <>
      <Helmet>
        <title>{t("Navigation.Recipes")} | Cook Your Goose</title>
      </Helmet>
      <Recipes
        recipeList={filteredRecipeList}
        isLoading={isLoading}
        filters={filters}
        hasActiveFilters={hasActiveFilters}
        setFilters={setFilters}
        activeTabId={activeTabId}
        setActiveTabId={setActiveTabId}
        activeViewTabId={activeViewTabId}
        setActiveViewTabId={setActiveViewTabId}
        searchString={searchString}
        setSearchString={setSearchString}
      />
    </>
  );
}

export default RecipesContainer;
