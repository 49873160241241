import React, { forwardRef } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import DragHandle from "commons/components/DragHandle";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Icon from "commons/components/Icon";
import Tiptap from "commons/components/Tiptap";
import Tooltip from "commons/components/Tooltip";
import { Marker } from "App/Recipes/components/Recipes.styles";
import { useTranslation } from "react-i18next";

const SortableBox = styled.div`
  width: 100%;
  min-height: 80px;
`;

const Box = styled(Flexbox)`
  position: relative;
`;

const DragHandleStyled = styled(DragHandle)`
  margin-top: 4px;
`;

const MarkerStyled = styled(Marker)`
  line-height: 32px;
  margin-top: 2px;
`;

const DeleteIcon = styled(Icon)`
  padding: 10px 0;
`;

// eslint-disable-next-line react/display-name
const Step = forwardRef(
  ({ id, number, text, className, setStepText, deleteStep, handleKeyDown, tipTapDependecies }, ref) => {
    const { t } = useTranslation();
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({ id });

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
    };

    return (
      <SortableBox
        className={className}
        ref={el => {
          setNodeRef(el);
          ref(el);
        }}
        style={style}
        {...attributes}
        tabIndex="-1"
      >
        <Box gap={8} alignItems="flex-start">
          <DragHandleStyled {...listeners} ref={setActivatorNodeRef} />
          <MarkerStyled>{number}.</MarkerStyled>
          <Tiptap
            content={text}
            defaultContent="<p></p>"
            placeholder={t("Recipes.Step", { stepNumber: number })}
            onChange={value => setStepText(id, value)}
            handleKeyDown={handleKeyDown}
            dependecies={tipTapDependecies}
          />
          <Tooltip label={t("Recipes.RemoveStep")}>
            <DeleteIcon size={20} name="close" onClick={() => deleteStep(id)} />
          </Tooltip>
        </Box>
      </SortableBox>
    );
  }
);

Step.propTypes = {
  className: T.string,
  id: T.number,
  number: T.number,
  text: T.string,
  setStepText: T.func,
  deleteStep: T.func,
  handleKeyDown: T.func,
  tipTapDependecies: T.array,
};

export default Step;
