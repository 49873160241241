import React from "react";
import styled from "styled-components/macro";
import Tabs from "commons/components/Tabs";
import { Outlet, useLocation } from "react-router-dom";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useTranslation } from "react-i18next";

const Box = styled.div`
  position: relative;
  width: 508px;
  max-width: 100%;
  margin: 40px auto 160px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    margin-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
  }
`;

const AbsoluteTabs = styled(Tabs)`
  position: absolute;
  left: -154px;
`;

function Settings() {
  const { t } = useTranslation();
  const location = useLocation();

  const tabs = [
    {
      id: "/app/settings/account",
      label: t("Settings.Account"),
      link: "/app/settings/account",
    },
    {
      id: "/app/settings/preferences",
      label: t("Settings.Preferences"),
      link: "/app/settings/preferences",
    },
    {
      id: "/app/settings/tags",
      label: t("Settings.Tags"),
      link: "/app/settings/tags",
    },
  ];

  return (
    <Box>
      <AbsoluteTabs direction="column" activeTabId={location.pathname} tabs={tabs} />
      <Outlet />
    </Box>
  );
}

export default Settings;
