import React, { useState } from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import TextInput from "commons/components/TextInput";
import Icon from "commons/components/Icon";
import Flexbox from "commons/components/Flexbox";
import { useUpdateEffect } from "commons/util/useUpdateEffect";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { TAG_COLOR_LIST } from "commons/util/constants";
import TagIcon from "commons/components/TagIcon";
import { presetIcons } from "./presetIcons.const";
import Typography from "commons/components/Typography";
import { Trans, useTranslation } from "react-i18next";

const Box = styled(Flexbox)`
  max-width: 348px;
  cursor: default;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: calc(100vw - 112px);
  }
`;

const ColorTile = styled.div`
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-1);
  background-color: ${({ color }) => color && `var(--${color}-100)`};
  cursor: pointer;

  ${({ isActive }) => isActive && css``}
`;

const TagOptionsWrap = styled(Flexbox)`
  max-height: 320px;
  padding: 0 24px 24px;
  margin: 0 -24px -24px;
  width: calc(100% + 48px);
  overflow-y: auto;
  border-top: 1px solid var(--neutral-180);
`;

const Category = styled(Typography)`
  flex-basis: 100%;
  margin-top: 16px;
  margin-bottom: 4px;
  text-transform: capitalize;
`;

const TagIconOption = styled(TagIcon)`
  cursor: pointer;
  border-radius: var(--border-radius-1);
  padding: 4px;

  &:hover {
    background-color: var(--neutral-190);
  }

  ${({ isActive, backgroundColor }) =>
    isActive &&
    css`
      background-color: var(--${backgroundColor}-100);
      & path,
      & circle {
        fill: var(--neutral-200);
      }

      &:hover {
        background-color: var(--${backgroundColor}-100);
      }
    `}
`;

const InfoBox = styled(Flexbox)`
  background-color: var(--info-190);
`;

const AnchorLink = styled.a`
  font-weight: 500;
  text-decoration: underline;
`;

function TagEditPopoverContent({
  className,
  id,
  label: initLabel,
  color: initColor,
  icon: initIcon,
  updateTag,
  closePopover,
}) {
  const { t } = useTranslation();
  const [label, setLabel] = useState(initLabel);
  const [color, setColor] = useState(initColor);
  const [icon, setIcon] = useState(initIcon);

  useUpdateEffect(() => {
    const tagData = {
      id,
      label,
      color,
      icon,
    };

    updateTag(tagData);
  }, [label, color, icon]);

  const categorizedIcons = presetIcons.reduce((result, icon) => {
    const { category, name } = icon;

    if (category) {
      if (!result[category]) {
        result[category] = [];
      }
      result[category].push(name);
    }

    return result;
  }, {});

  return (
    <Box className={className} gap={24} flexDirection="column" padding={24}>
      <TextInput value={label} onChange={setLabel} onConfirm={closePopover} maxLength={32} autoFocus />
      <Flexbox gap={8} flexWrap="wrap">
        {TAG_COLOR_LIST.map(presetColor => (
          <ColorTile
            key={presetColor}
            color={presetColor}
            isActive={presetColor === color}
            onClick={() => setColor(presetColor)}
          >
            {presetColor === color && <Icon name="check" color="neutral-200" size={20} />}
          </ColorTile>
        ))}
      </Flexbox>
      <TagOptionsWrap gap={6} flexWrap="wrap">
        {Object.entries(categorizedIcons).map(([category, presetIconList]) => (
          <React.Fragment key={category}>
            <Category variant="h4">{t(`TagIconCategories.${category}`)}</Category>
            {presetIconList.map(presetIcon => (
              <TagIconOption
                key={presetIcon}
                name={presetIcon}
                isActive={presetIcon === icon}
                onClick={() => setIcon(ico => (ico === presetIcon ? "" : presetIcon))}
                size={28}
                backgroundColor={color}
                color="neutral-120"
              />
            ))}
          </React.Fragment>
        ))}
        <InfoBox gap={8} padding={8} marginTop={16}>
          <Icon name="info" color="info-100" size={20} />
          <Typography variant="label" color="info-80">
            <Trans i18nKey="Settings.TagIconInfoBlock">
              Need more icons? Let us know at
              <AnchorLink href="/contact" target="_blank">
                cookyourgooose.eu/contact
              </AnchorLink>
            </Trans>
          </Typography>
        </InfoBox>
      </TagOptionsWrap>
    </Box>
  );
}

TagEditPopoverContent.propTypes = {
  className: T.string,
  id: T.number,
  label: T.string,
  color: T.string,
  icon: T.string,
  updateTag: T.func,
  closePopover: T.func,
};

export default TagEditPopoverContent;
