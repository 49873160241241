import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import RecipeCard from "./RecipeCard";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import { SkeletonArray } from "commons/components/Skeleton/Skeleton";

const Box = styled(motion.div)`
  display: grid;
  gap: 24px 24px;
  grid-template-columns: repeat(4, 1fr);

  @media (max-width: ${BREAKPOINTS.medium}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${BREAKPOINTS.small}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const MotionCard = styled(motion.div)`
  background: var(--neutral-200);
  border: 1px solid var(--neutral-180);
  border-radius: var(--border-radius-2);
  transition: box-shadow 0.1s;

  &:hover,
  &:focus-visible {
    box-shadow: var(--shadow-2);
  }
`;

// eslint-disable-next-line react/prop-types
const AnimatedItem = ({ children }) => {
  const isPresent = useIsPresent();
  const animations = {
    style: {
      position: isPresent ? "static" : "absolute",
    },
    initial: { scale: 0.2, opacity: 0 },
    animate: { scale: 1, opacity: 1 },
    exit: { scale: 0.2, opacity: 0 },
  };
  return (
    <MotionCard {...animations} layout>
      {children}
    </MotionCard>
  );
};

function RecipeGrid({ recipes, isLoading }) {
  return (
    <Box>
      {isLoading ? (
        <SkeletonArray count={12} width="100%" height="228px" />
      ) : (
        <AnimatePresence>
          {recipes.map(recipe => (
            <AnimatedItem key={recipe.id}>
              <RecipeCard
                recipeId={recipe.id}
                title={recipe.title}
                imageURL={recipe.imageURL}
                tags={recipe.tags}
                isFavorite={recipe.isFavorite}
                neverCooked={recipe.neverCooked}
                link={`/app/recipes/${recipe.id}`}
              />
            </AnimatedItem>
          ))}
        </AnimatePresence>
      )}
    </Box>
  );
}

RecipeGrid.propTypes = {
  recipes: T.array,
  isLoading: T.bool,
};

export default RecipeGrid;
