import React, { useState } from "react";
import T from "prop-types";
import Typography from "commons/components/Typography";
import { Trans, useTranslation } from "react-i18next";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Button from "commons/components/Button";
import { useAuth } from "App/AuthContext";
import { PLANS, PLAN_PRICES, RECIPE_LIMIT } from "commons/util/constants";
import Feature from "./Feature";
import CurveSVG from "commons/images/curve.svg";
import Question from "Website/components/Question";
import { questions } from "./Pricing.const";
import { Helmet } from "react-helmet";

const BackgroundWrap = styled.div`
  padding: 96px 0 40px;
  background-image: url(${CurveSVG});
  background-repeat: no-repeat;
  background-size: 100vw auto;
  background-position: 0 30vh;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 40px 0;
  }
`;

const Box = styled.div`
  width: 1032px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 32px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding-left: 32px;
    padding-right: 32px;
    align-items: stretch;
  }
`;

const Header = styled.div`
  text-align: center;
`;

const Card = styled.div`
  padding: 24px;
  border-radius: var(--border-radius-2);
  background-color: var(--neutral-200);
  box-shadow: var(--shadow-1);

  &:first-child {
    flex-basis: 35%;
  }
  &:last-child {
    flex-basis: 65%;
  }
`;

const Ul = styled.ul`
  list-style-type: disc;
  margin-bottom: 56px;
  font-size: 16px;
  line-height: 24px;
  padding-left: 20px;
`;

const Li = styled.li`
  margin-top: 4px;
`;

const Period = styled.span`
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: var(--neutral-160);
`;

const FAQSection = styled(Flexbox)`
  margin: 128px auto 96px;

  @media (max-width: ${BREAKPOINTS.small}) {
    margin: 80px auto 64px;
  }
`;

const CTASection = styled(Flexbox)`
  margin: 96px auto 40px;
  padding: 40px;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.small}) {
    margin: 64px auto 40px;
    padding: 16px;
  }
`;

function Pricing({ className }) {
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);
  const { t } = useTranslation();
  const { user } = useAuth();
  const [openQuestionIds, setOpenQuestionIds] = useState([]);

  function onQuestionClick(questionId) {
    setOpenQuestionIds(ids => {
      if (ids.includes(questionId)) {
        return ids.filter(id => id !== questionId);
      } else {
        return [...ids, questionId];
      }
    });
  }

  const plan = user ? user.accountType : null;

  return (
    <>
      <Helmet>
        <title>{t("Navigation.Pricing")} | Cook Your Goose</title>
      </Helmet>
      <BackgroundWrap>
        <Box className={className}>
          <Header>
            <Typography variant={isScreenMaxMedium ? "h2" : "h1"} as="h1">
              {t("Pricing.Title")}
            </Typography>
            <Typography variant="paragraph" marginTop={16}>
              <Trans i18nKey="Pricing.Paragraph">
                Start with Cook Your Grace for free.
                <br />
                Then upgrade when your recipe collection grows.
              </Trans>
            </Typography>
          </Header>

          <Flexbox
            gap={32}
            marginTop={72}
            alignItems={isScreenMaxMedium ? "stretch" : "flex-start"}
            flexDirection={isScreenMaxMedium ? "column" : "row"}
          >
            <Card>
              <Flexbox justifyContent="space-between" flexWrap="wrap">
                <Typography variant="h3">{t("Pricing.Basic")}</Typography>
                <Typography variant="h3" color="primary-100">
                  {t("Pricing.Free")}
                </Typography>
              </Flexbox>
              <Typography variant="paragraph" marginTop={32}>
                {t("Pricing.BasicDescription")}
              </Typography>
              <Typography variant="h4" marginTop={40} marginBottom={8}>
                {t("Pricing.WhatsIncluded")}
              </Typography>
              <Ul>
                <Li>{t("Pricing.FeatureRecipeStorage", { limit: RECIPE_LIMIT })}</Li>
                <Li>{t("Pricing.FeatureInteractiveCooking")}</Li>
                <Li>{t("Pricing.FeatureWebImport")}</Li>
                <Li>{t("Pricing.FeatureGroceryList")}</Li>
                <Li>{t("Pricing.FeatureMealPlanner")}</Li>
              </Ul>
              <Button variant="secondary" disabled={plan === PLANS.free} size="medium" fullWidth>
                {plan === PLANS.free ? t("Pricing.CurrentPlan") : t("Pricing.GetStarted")}
              </Button>
            </Card>

            <Card>
              <Flexbox justifyContent="space-between" flexWrap="wrap">
                <Typography variant="h3">{t("Pricing.Premium")}</Typography>
                <Typography variant="h3" color="primary-100">
                  €{PLAN_PRICES[PLANS.premium]}
                  <Period>{t("Pricing.PerMonth")}</Period>
                </Typography>
              </Flexbox>
              <Typography variant="paragraph" marginY={32}>
                <Trans i18nKey="Pricing.PremiumDescription">
                  Unlimited recipe storage for home cooks, households, and professional chefs.
                  <br />
                  Includes all <b>Basic</b> features and more:
                </Trans>
              </Typography>
              <Flexbox flexDirection="column" marginBottom={46} gap={24}>
                <Feature
                  icon="all_inclusive"
                  label={t("Pricing.UnlimitedRecipesLabel")}
                  paragraph={t("Pricing.UnlimitedRecipesParagraph")}
                />
                {/* <Feature
                  icon="photo_camera"
                  label={t("Pricing.ImageScanLabel")}
                  paragraph={t("Pricing.ImageScanParagraph")}
                />
                <Feature
                  icon="diversity_4"
                  label={t("Pricing.FamilyAccountLabel")}
                  paragraph={t("Pricing.FamilyAccountParagraph")}
                />
                <Feature
                  icon="calendar_month"
                  label={t("Pricing.AutoMealPlanLabel")}
                  paragraph={t("Pricing.AutoMealPlanParagraph")}
                />
                <Feature
                  icon="account_tree"
                  label={t("Pricing.FlowchartLabel")}
                  paragraph={t("Pricing.FlowchartParagraph")}
                />
                <Feature
                  icon="mic"
                  label={t("Pricing.VoiceControlLabel")}
                  paragraph={t("Pricing.VoiceControlParagraph")}
                /> */}
                <Feature
                  icon="support_agent"
                  label={t("Pricing.CustomerSupportLabel")}
                  paragraph={t("Pricing.CustomerSupportParagraph")}
                />
                <Feature icon="more_horiz" label={t("Pricing.MoreSoon")} paragraph={t("Pricing.MoreSoonParagraph")} />
              </Flexbox>
              <Button size="medium" fullWidth>
                {plan === PLANS.premium ? t("Pricing.CurrentPlan") : t("Pricing.GetStarted")}
              </Button>
            </Card>
          </Flexbox>

          <FAQSection forwardedAs="section" flexDirection="column" gap={24}>
            <Typography variant="h3">{t("Pricing.FaqTitle")}</Typography>
            <Flexbox flexDirection="column" gap={16}>
              {questions.map(question => (
                <Question
                  key={question.id}
                  title={question.title}
                  content={question.content}
                  isOpen={openQuestionIds.includes(question.id)}
                  onClick={() => onQuestionClick(question.id)}
                />
              ))}
            </Flexbox>
          </FAQSection>

          <CTASection flexDirection="column" alignItems="center">
            <Typography variant="h3" marginBottom={8}>
              {t("Pricing.CtaTitle")}
            </Typography>
            <Typography variant="paragraph" marginBottom={24}>
              {t("Pricing.CtaParagraph")}
            </Typography>
            <Button size="medium" link="/contact">
              {t("Pricing.CtaButton")}
            </Button>
          </CTASection>
        </Box>
      </BackgroundWrap>
    </>
  );
}

Pricing.propTypes = {
  className: T.string,
};

export default Pricing;
