import React, { useState } from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import { useDropzone } from "react-dropzone";
import Typography from "commons/components/Typography";
import Icon from "../Icon";
import { useTranslation } from "react-i18next";

const Label = styled(Typography)`
  display: inline-block;
  margin-bottom: 4px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 98px;
  gap: 4px;
  border-radius: var(--border-radius-1);
  border: 1px dashed var(--neutral-170);
  padding: 16px;
  cursor: pointer;

  ${({ isDragAccept }) =>
    isDragAccept &&
    css`
      background-color: var(--neutral-190);
    `}

  &:hover {
    border-color: var(--neutral-160);
  }
`;

const List = styled.ul`
  list-style-type: disc;
  padding: 8px 16px;
`;

const ListItem = styled(Typography)`
  word-break: break-all;
`;

function DragAndDropInput({ className, label }) {
  const { t } = useTranslation();
  const [files, setFiles] = React.useState([]);
  const [fileError, setFileError] = useState("");
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: onDropProxy,
  });

  async function onDropProxy(fileList) {
    setFileError("");
    const processedFileList = files;

    for (var i = 0; i < fileList.length; i++) {
      const file = fileList[i];

      if (file.size > 8000000) {
        setFileError(t("App.FileSizeTooLarge", { maxSize: 8 }));
      } else if (files.map(el => el.name).includes(file.name)) {
        setFileError(t("App.FileAlreadyAttached"));
      } else {
        processedFileList.push(file);
      }
    }

    setFiles(processedFileList);
  }

  return (
    <div>
      {label && (
        <Label variant="label" color="neutral-120">
          {label}
        </Label>
      )}
      <Box {...getRootProps({ isFocused, isDragAccept, isDragReject })} className={className}>
        <input {...getInputProps()} />
        <Icon name="attach_file_add" color="neutral-140" size={20} />
        <Typography variant="label" color="neutral-140">
          {t("App.AddFiles")}
        </Typography>
      </Box>
      <Typography color="red-100" marginTop={8}>
        {fileError}
      </Typography>
      <List>
        {files.map(file => (
          <ListItem key={file.name} variant="label" as="li">
            {file.name}
          </ListItem>
        ))}
      </List>
    </div>
  );
}

DragAndDropInput.propTypes = {
  className: T.string,
  label: T.string,
};

export default DragAndDropInput;
