export const predefinedAisles = {
  Produce: [
    "onion",
    "potatoes",
    "tomatoes",
    "lettuce",
    "carrots",
    "broccoli",
    "banana",
    "apple",
    "orange",
    "acorn squash",
    "apricots",
    "arugula",
    "asian pear",
    "asparagus spears",
    "avocado",
    "baby bell peppers",
    "baby bok choy",
    "baby carrots",
    "baby corn",
    "baby spinach leaves",
    "beets",
    "bell pepper",
    "berries",
    "blackberries",
    "blood orange",
    "blueberries",
    "bok choy",
    "broccoli florets",
    "brussel sprouts",
    "butterhead lettuce",
    "butternut squash",
    "cabbage",
    "cantaloupe",
    "cauliflower",
    "cauliflower florets",
    "celery",
    "celery ribs",
    "celery root",
    "cherry",
    "cherry tomatoes",
    "cilantro",
    "collard greens",
    "cucumber",
    "courgettes",
    "curry leaves",
    "dates",
    "dill",
    "eggplant",
    "english cucumber",
    "escarole",
    "fennel",
    "fresh basil",
    "fresh bean sprouts",
    "fresh chives",
    "fresh corn",
    "fresh corn kernels",
    "fresh figs",
    "fresh fruit",
    "fresh herbs",
    "fresh mint",
    "fresh rosemary",
    "fresh thyme leaves",
    "garlic",
    "ginger",
    "granny smith apples",
    "grape tomatoes",
    "grapefruit",
    "green bell pepper",
    "green chili pepper",
    "green grapes",
    "green onions",
    "greens",
    "jalapeno",
    "jicama",
    "kaffir lime leaves",
    "kale",
    "kiwis",
    "leeks",
    "lemon",
    "lemongrass",
    "lime",
    "mango",
    "mandarin oranges",
    "mushroom",
    "napa cabbage",
    "nectarine",
    "navel oranges",
    "new potatoes",
    "okra",
    "orange bell pepper",
    "papaya",
    "parsley",
    "parsnip",
    "peach",
    "pearl onions",
    "peas",
    "peppers",
    "pineapple",
    "pineapple chunks",
    "plum",
    "plum tomatoes",
    "poblano peppers",
    "portabella mushrooms",
    "radicchio",
    "radishes",
    "raspberries",
    "red apples",
    "red bell peppers",
    "red cabbage",
    "red chilli",
    "red delicious apples",
    "red grapes",
    "red onion",
    "red potatoes",
    "rhubarb",
    "roma tomatoes",
    "romaine lettuce",
    "root vegetables",
    "rutabaga",
    "serrano chile",
    "shallot",
    "shiitake mushroom caps",
    "snow peas",
    "spinach",
    "sprouts",
    "squash",
    "stew vegetables",
    "strawberries",
    "sugar snap peas",
    "summer squash",
    "sweet onion",
    "sweet potato",
    "swiss chard",
    "tart apple",
    "thai basil",
    "thai chiles",
    "tomatillos",
    "turnips",
    "watercress",
    "watermelon chunks",
    "white onion",
    "yellow bell pepper",
    "yellow onion",
    "yukon gold potato",
  ],
  Meat: [
    "chicken",
    "beef",
    "andouille sausage",
    "baby-back ribs",
    "bacon",
    "bacon fat",
    "beef chuck roast",
    "beef tenderloin",
    "boneless skinless chicken breast",
    "breakfast links",
    "chicken base",
    "chicken bouillon",
    "chicken bouillon granules",
    "chicken breasts",
    "chicken broth",
    "chicken drumsticks",
    "chicken legs",
    "chicken pieces",
    "chicken sausage",
    "chicken stock",
    "chicken tenders",
    "chicken thighs",
    "chicken wings",
    "chorizo sausage",
    "cooked bacon",
    "cooked chicken breast",
    "cooked ham",
    "corned beef",
    "cornish hens",
    "deli ham",
    "deli turkey",
    "diced ham",
    "dried chorizo",
    "flank steak",
    "ground beef",
    "ground chicken",
    "ground lamb",
    "ground pork",
    "ground pork sausage",
    "ground veal",
    "ham",
    "italian sausages",
    "lamb",
    "lean ground beef",
    "lean ground turkey",
    "lean pork tenderloin",
    "leg of lamb",
    "live lobster",
    "meat",
    "meatballs",
    "pancetta",
    "pork",
    "pork belly",
    "pork butt",
    "pork chops",
    "pork links",
    "pork loin chops",
    "pork loin roast",
    "pork roast",
    "pork shoulder",
    "pork tenderloin",
    "polish sausage",
    "rib tips",
    "roast beef",
    "roasted chicken",
    "rotisserie chicken",
    "round steak",
    "sausage",
    "sausage links",
    "short ribs",
    "sirloin steak",
    "skin-on bone-in chicken leg quarters",
    "skinless boneless chicken breast halves",
    "skinless boneless chicken thighs",
    "stew meat",
    "thick-cut bacon",
    "tilapia fillets",
    "top blade steak",
    "top round steak",
    "Top Sirloin",
    "turkey",
    "turkey breast",
    "turkey kielbasa",
    "unsmoked back bacon",
    "whole chicken",
  ],
  Seafood: [
    "ahi tuna",
    "anchovies",
    "canned tuna",
    "carp",
    "cat fish filets",
    "clam juice",
    "clams",
    "cod",
    "crabmeat",
    "fish",
    "halibut fillet",
    "lump crab",
    "mussels",
    "oysters",
    "raw shrimp",
    "salmon fillet",
    "sea scallops",
    "snapper fillets",
    "smoked salmon",
    "water-packed tuna",
  ],
  "Dairy & Eggs": [
    "milk",
    "eggs",
    "butter",
    "cheese",
    "yogurt",
    "asiago cheese",
    "brie",
    "buttermilk",
    "cheddar",
    "cheese curds",
    "chive & onion cream cheese spread",
    "chocolate milk",
    "clarified butter",
    "colby jack",
    "cottage cheese",
    "cream",
    "cream cheese",
    "cream cheese block",
    "creme fraiche",
    "cremini mushrooms",
    "double cream",
    "dry milk",
    "egg substitute",
    "egg whites",
    "egg yolk",
    "eggnog",
    "extra firm tofu",
    "feta cheese",
    "fontina cheese",
    "free range eggs",
    "fresh mozzarella",
    "goat cheese",
    "gorgonzola",
    "gouda cheese",
    "greek yogurt",
    "grill cheese",
    "gruyere",
    "hard cooked eggs",
    "healthy request cream of celery soup",
    "hemp seeds",
    "italian cheese blend",
    "low fat buttermilk",
    "low fat milk",
    "low fat plain yogurt",
    "low fat ricotta cheese",
    "low fat sour cream",
    "milk chocolate chips",
    "monterey jack cheese",
    "non-fat greek yogurt",
    "nonfat cool whip",
    "nonfat milk",
    "paneer",
    "parmigiano reggiano",
    "part-skim mozzarella cheese",
    "pepper jack cheese",
    "processed american cheese",
    "provolone cheese",
    "queso fresco",
    "reduced fat shredded cheddar cheese",
    "ricotta salata",
    "salted butter",
    "sharp cheddar cheese",
    "sheeps milk cheese",
    "shredded cheddar cheese",
    "shredded cheese",
    "shredded mexican cheese blend",
    "shredded mozzarella",
    "silken tofu",
    "skim milk ricotta",
    "skim vanilla greek yogurt",
    "sour cream",
    "swiss cheese",
    "unsalted butter",
    "vanilla yogurt",
    "vegan cheese",
    "whipped cream",
    "whipped topping",
    "whipping cream",
  ],
  Bakery: [
    "bread",
    "baguette",
    "biscuits",
    "angel food cake mix",
    "angel hair pasta",
    "baking bar",
    "baking powder",
    "baking soda",
    "bread flour",
    "brownie mix",
    "cake flour",
    "cinnamon roll",
    "cinnamon swirl bread",
    "country bread",
    "cornbread",
    "cup cake",
    "english muffin",
    "french bread",
    "graham cracker crumbs",
    "graham cracker pie crust",
    "graham crackers",
    "hamburger buns",
    "italian bread",
    "lb cake",
    "ladyfingers",
    "pizza crust",
    "pizza mix",
    "plain greek yogurt",
    "plain nonfat yogurt",
    "plain yogurt",
    "pita",
    "pretzel sandwiches",
    "puff pastry",
    "refrigerated crescent rolls",
    "refrigerated pizza dough",
    "refrigerated sugar cookie dough",
    "rye bread",
    "sandwich bun",
    "sesame seed hamburger buns",
    "shortbread cookies",
    "shortcrust pastry",
    "sourdough bowl",
    "sourdough bread",
    "sub rolls",
    "toast",
    "tortilla",
    "white bread",
    "whole wheat bread",
    "wraps",
  ],
  Pantry: [
    "rice",
    "pasta",
    "flour",
    "beans",
    "peanut butter",
    "jam",
    "tofu",
    "adobo sauce",
    "agave nectar",
    "alfredo pasta sauce",
    "almond flour",
    "almond milk",
    "almonds",
    "apple butter spread",
    "apple cider",
    "apple juice",
    "apple pie spice",
    "apricot preserves",
    "arborio rice",
    "arrowroot powder",
    "artichoke heart quarters",
    "artichokes",
    "asafoetida",
    "asafoetida powder",
    "balsamic glaze",
    "balsamic vinegar",
    "basmati rice",
    "bbq sauce",
    "beef broth",
    "beef stock",
    "biscuit mix",
    "bittersweet chocolate",
    "black bean sauce",
    "black beans",
    "black olives",
    "black sesame seeds",
    "blanched almonds",
    "brown rice",
    "brown rice flour",
    "brown sugar",
    "bamboo shoots",
    "bay leaves",
    "baking soda",
    "baking powder",
    "black pepper",
    "canned black beans",
    "canned diced tomatoes",
    "canned garbanzo beans",
    "canned green chiles",
    "canned kidney beans",
    "canned mushrooms",
    "canned pinto beans",
    "canned red kidney beans",
    "canned tomatoes",
    "canned white beans",
    "canned white cannellini beans",
    "cannellini beans",
    "capers",
    "caraway seed",
    "cardamom",
    "cardamom pods",
    "caramel sauce",
    "caramels",
    "caesar dressing",
    "cajun seasoning",
    "candy canes",
    "candy coating",
    "candy melts",
    "cereal",
    "chana dal",
    "chia seeds",
    "chile garlic sauce",
    "chili paste",
    "chili peppers",
    "chili powder",
    "chili sauce",
    "chipotle chiles in adobo",
    "chipotle chilies",
    "chipotle peppers in adobo",
    "cider vinegar",
    "cinnamon stick",
    "cinnamon sugar",
    "clove",
    "coarse salt",
    "coarsely ground pepper",
    "cocoa nibs",
    "cocoa powder",
    "coconut",
    "coconut aminos",
    "coconut butter",
    "coconut cream",
    "coconut extract",
    "coconut flour",
    "coconut milk",
    "coconut oil",
    "coconut water",
    "confectioner's swerve",
    "cooked brown rice",
    "cooked long grain rice",
    "cooked pasta",
    "cooked polenta",
    "cooked quinoa",
    "cooked wild rice",
    "corn flour",
    "corn kernels",
    "corn oil",
    "corn tortillas",
    "cornmeal",
    "cornstarch",
    "couscous",
    "cow pea",
    "cracked pepper",
    "cranberries",
    "cream of tartar",
    "creamed corn",
    "creamy peanut butter",
    "creole seasoning",
    "crisp rice cereal",
    "croutons",
    "crystallized ginger",
    "cumin seeds",
    "currants",
    "dark brown sugar",
    "dark chocolate",
    "dark chocolate candy bars",
    "dark chocolate chips",
    "dark sesame oil",
    "dried apricots",
    "dried basil",
    "dried cherries",
    "dried cranberries",
    "dried dill",
    "dried onion",
    "dried porcini mushrooms",
    "dried rubbed sage",
    "dried thyme",
    "dried tomatoes",
    "dry bread crumbs",
    "dry mustard",
    "dry roasted peanuts",
    "duck fat",
    "dutch process cocoa powder",
    "evaporated milk",
    "farro",
    "fennel seeds",
    "fenugreek leaf",
    "fenugreek seeds",
    "fire roasted tomatoes",
    "flax seeds",
    "fleur de sel",
    "flour tortillas",
    "food dye",
    "frank's redhot sauce",
    "garam masala",
    "garbanzo bean flour",
    "garlic paste",
    "garlic powder",
    "garlic salt",
    "gelatin",
    "ginger paste",
    "ginger-garlic paste",
    "gnocchi",
    "golden raisins",
    "granulated garlic",
    "granola",
    "grapeseed oil",
    "gravy",
    "great northern beans",
    "green food coloring",
    "green olives",
    "ground allspice",
    "ground ancho chili",
    "ground chipotle chile pepper",
    "ground cinnamon",
    "ground cloves",
    "ground coriander seeds",
    "ground cumin",
    "ground flaxseed",
    "ground ginger",
    "ground mace",
    "ground nutmeg",
    "ground veal",
    "guacamole",
    "half n half",
    "harissa",
    "hazelnuts",
    "herbes de provence",
    "herbs",
    "hoisin sauce",
    "honey mustard",
    "horseradish",
    "hot sauce",
    "instant chocolate pudding mix",
    "instant coffee powder",
    "instant espresso powder",
    "instant lemon pudding mix",
    "instant yeast",
    "italian seasoning",
    "jaggery",
    "jasmine rice",
    "jelly",
    "juice",
    "kosher salt",
    "lasagna noodles",
    "lemon curd",
    "lemon extract",
    "lemon juice",
    "lemon peel",
    "lemon pepper",
    "lemon wedges",
    "lime juice",
    "lime wedges",
    "lime zest",
    "linguine",
    "liquid smoke",
    "liquid stevia",
    "long-grain rice",
    "low sodium chicken broth",
    "low sodium soy sauce",
    "low-sodium chicken stock",
    "lower sodium beef broth",
    "m&m candies",
    "macadamia nuts",
    "macaroni and cheese mix",
    "madras curry powder",
    "malt drink mix",
    "mandarin orange sections",
    "maple syrup",
    "maraschino cherries",
    "marjoram",
    "marsala wine",
    "marshmallow fluff",
    "marshmallows",
    "masa harina",
    "mascarpone",
    "mat beans",
    "meat",
    "meatballs",
    "mirin",
    "miso",
    "molasses",
    "monterey jack cheese",
    "mustard",
    "mustard seeds",
    "nut butter",
    "nut meal",
    "nutella",
    "nutritional yeast",
    "oat flour",
    "oats",
    "oil",
    "oil packed sun dried tomatoes",
    "old bay seasoning",
    "onion powder",
    "onion soup mix",
    "orange juice",
    "orange juice concentrate",
    "orange liqueur",
    "orange marmalade",
    "orange oil",
    "orange zest",
    "oregano",
    "oreo cookies",
    "orzo",
    "oyster sauce",
    "palm sugar",
    "paneer",
    "panko",
    "paprika",
    "parmigiano reggiano",
    "parsley flakes",
    "pasta salad mix",
    "pasta sauce",
    "pastry flour",
    "peanut butter chips",
    "peanut butter cups",
    "peanut oil",
    "peanuts",
    "pear liqueur",
    "pearl barley",
    "pecan",
    "pecan pieces",
    "pecorino",
    "penne",
    "peperoncino",
    "peppercorns",
    "peppermint baking chips",
    "peppermint extract",
    "pesto",
    "pickle relish",
    "pickles",
    "pico de gallo",
    "pie crust",
    "pimento stuffed olives",
    "pimientos",
    "pine nuts",
    "pineapple in juice",
    "pineapple juice",
    "pink himalayan salt",
    "pinto beans",
    "pistachios",
    "plantain",
    "polenta",
    "pomegranate molasses",
    "pomegranate seeds",
    "popcorn",
    "poppy seeds",
    "Pork & Beans",
    "port",
    "potato chips",
    "potato starch",
    "poultry seasoning",
    "powdered sugar",
    "prosciutto",
    "prunes",
    "pumpkin",
    "pumpkin pie filling",
    "pumpkin pie spice",
    "pumpkin puree",
    "pumpkin seeds",
    "quick cooking oats",
    "quinoa",
    "quinoa flour",
    "rajma masala",
    "ramen noodles",
    "ranch dressing",
    "ranch dressing mix",
    "raspberry jam",
    "raw cashews",
    "ready-to-serve Asian fried rice",
    "real bacon recipe pieces",
    "red kidney beans",
    "red lentils",
    "red pepper flakes",
    "red pepper powder",
    "red velvet cookie",
    "red wine",
    "red wine vinegar",
    "refried beans",
    "rice flour",
    "rice krispies cereal",
    "rice milk",
    "rice noodles",
    "rice paper",
    "rice syrup",
    "rice vinegar",
    "rice wine",
    "ritz crackers",
    "roasted nuts",
    "roasted peanuts",
    "roasted red peppers",
    "rosemary",
    "rotini pasta",
    "rub",
    "rum extract",
    "runny honey",
    "rye meal",
    "saffron threads",
    "sage",
    "sage leaves",
    "salad dressing",
    "salsa",
    "salsa verde",
    "salt and pepper",
    "saltine crackers",
    "sauerkraut",
    "scotch bonnet chili",
    "sea salt",
    "seasoned bread crumbs",
    "seasoned rice vinegar",
    "seasoned salt",
    "seasoning",
    "seasoning blend",
    "seeds",
    "self-rising flour",
    "semi sweet chocolate chips",
    "sesame oil",
    "sesame seeds",
    "sherry",
    "sherry vinegar",
    "short grain rice",
    "short pasta",
    "shortening",
    "shredded coconut",
    "slivered almonds",
    "smoked paprika",
    "smooth peanut butter",
    "soda water",
    "soy protein powder",
    "soy sauce",
    "spaghetti",
    "spaghetti squash",
    "spelt flour",
    "spicy brown mustard",
    "sprite",
    "sriracha sauce",
    "steel cut oats",
    "stevia",
    "stock",
    "store-bought phyllo",
    "stout",
    "strawberry jam",
    "strawberry jello",
    "stuffing",
    "stuffing mix",
    "sugar syrup",
    "sukrin sweetener",
    "sunflower oil",
    "sunflower seeds",
    "sweet chilli sauce",
    "sweet paprika",
    "sweet pickle juice",
    "sweet pickle relish",
    "sweetened coconut",
    "sweetened condensed milk",
    "sweetened shredded coconut",
    "taco seasoning mix",
    "taco shells",
    "tahini",
    "tamari",
    "tapioca flour",
    "tarragon",
    "tea bags",
    "teriyaki sauce",
    "thai red curry paste",
    "toffee bits",
    "tomato juice",
    "tomato paste",
    "tomato puree",
    "tomato sauce",
    "tomato soup",
    "triple sec",
    "truffle oil",
    "turbinado sugar",
    "turmeric",
    "unbleached flour",
    "unsweetened applesauce",
    "unsweetened coconut milk",
    "unsweetened shredded coconut",
    "vanilla bean",
    "vanilla bean paste",
    "vanilla essence",
    "vanilla extract",
    "vanilla frosting",
    "vanilla instant pudding mix",
    "vanilla protein powder",
    "vanilla wafers",
    "vegetable oil",
    "vinaigrette",
    "vinegar",
    "walnuts",
    "water",
    "water chestnuts",
    "wheat bran",
    "wheat germ",
    "white balsamic vinegar",
    "white bread",
    "white cake mix",
    "white cheddar",
    "white chocolate",
    "white chocolate chips",
    "white pepper",
    "white whole wheat flour",
    "whole allspice berries",
    "whole coriander seeds",
    "whole cranberry sauce",
    "whole kernel corn",
    "whole star anise",
    "whole wheat flour",
    "whole wheat tortillas",
    "whole-grain mustard",
    "wine vinegar",
    "winter squash",
    "won ton wraps",
    "worcestershire sauce",
    "xanthan gum",
    "yeast",
  ],
  Beverages: [
    "coffee",
    "tea",
    "apple cider",
    "apple juice",
    "beer",
    "bourbon",
    "brandy",
    "champagne",
    "cola",
    "cranberry juice",
    "diet pop",
    "espresso",
    "ginger ale",
    "juice",
    "orange liqueur",
    "pear liqueur",
    "port",
    "red wine",
    "sparkling wine",
    "sprite",
    "stout",
    "tequila",
    "vodka",
    "whiskey",
    "white wine",
    "wine",
  ],
  "Alcoholic Beverages": [
    "amaretto",
    "angostura bitters",
    "beer",
    "bourbon",
    "brandy",
    "champagne",
    "cognac",
    "dessert wine",
    "dry red wine",
    "gin",
    "grand marnier",
    "irish cream",
    "kahlua",
    "liquor",
    "marsala wine",
    "orange liqueur",
    "pear liqueur",
    "port",
    "red wine",
    "sherry",
    "sparkling wine",
    "stout",
    "tequila",
    "triple sec",
    "vodka",
    "whiskey",
    "white wine",
    "wine",
  ],
  "Frozen Foods": [
    "chocolate ice cream",
    "froyo bars",
    "frozen corn",
    "frozen spinach",
    "hash brown potatoes",
    "ice",
    "ice cream",
    "ready-to-serve Asian fried rice",
    "ice cream",
    "ice",
  ],
  Sweets: [
    "sugar",
    "chocolate",
    "chocolate chip cookies",
    "chocolate chunks",
    "candy canes",
    "candy coating",
    "candy melts",
    "caramel sauce",
    "caramels",
    "butterscotch chips",
    "chocolate sandwich cookies",
    "chocolate syrup",
    "chocolate wafer cookies",
    "cookies",
    "cup cake",
    "fudge",
    "fudge topping",
    "fun size almond joy bar",
    "gf chocolate cake mix",
    "gingersnap cookies",
    "hershey's kisses brand milk chocolates",
    "instant chocolate pudding mix",
    "instant lemon pudding mix",
    "jimmies",
    "oreo cookies",
    "peanut butter cups",
    "peppermint baking chips",
    "pretzel sandwiches",
    "red velvet cookie",
    "semi sweet chocolate chips",
    "shortbread cookies",
    "smooth peanut butter",
    "strawberry jam",
    "strawberry jello",
    "sweetened coconut",
    "sweetened condensed milk",
    "sweetened shredded coconut",
    "toffee bits",
    "vanilla wafers",
    "vegan chocolate chips",
  ],
  Snacks: [
    "chips",
    "crackers",
    "popcorn",
    "nuts",
    "trail mix",
    "corn chips",
    "corn flakes cereal",
    "cranberries",
    "dark chocolate candy bars",
    "dark chocolate chips",
    "dried apricots",
    "dried cherries",
    "dried cranberries",
    "dry roasted peanuts",
    "granola",
    "m&m candies",
    "nutella",
    "peanut butter cups",
    "pretzel sandwiches",
    "popcorn",
    "potato chips",
    "shortbread cookies",
    "tortilla chips",
  ],
  "Spices & Seasonings": [
    "salt",
    "pepper",
    "5 spice powder",
    "almond extract",
    "apple pie spice",
    "arrowroot powder",
    "asafoetida",
    "asafoetida powder",
    "balsamic glaze",
    "balsamic vinegar",
    "bay leaves",
    "black bean sauce",
    "black sesame seeds",
    "celery salt",
    "celery seed",
    "caraway seed",
    "cardamom",
    "cardamom pods",
    "cajun seasoning",
    "chili powder",
    "chipotle chilies",
    "chipotle peppers in adobo",
    "clove",
    "coarse salt",
    "coarsely ground pepper",
    "cumin seeds",
    "coriander",
    "curry leaves",
    "dried basil",
    "dried dill",
    "dried rubbed sage",
    "dried thyme",
    "dried tomatoes",
    "garam masala",
    "ginger",
    "cinnamon stick",
    "cinnamon sugar",
    "cracked pepper",
    "granulated garlic",
    "grill seasoning",
    "ground allspice",
    "ground ancho chili",
    "ground chipotle chile pepper",
    "ground cinnamon",
    "ground cloves",
    "ground coriander seeds",
    "ground cumin",
    "ground ginger",
    "ground mace",
    "ground nutmeg",
    "herbes de provence",
    "italian seasoning",
    "marjoram",
    "mustard seeds",
    "old bay seasoning",
    "oregano",
    "paprika",
    "parsley flakes",
    "peppercorns",
    "peppermint extract",
    "pink himalayan salt",
    "poultry seasoning",
    "poppy seeds",
    "rajma masala",
    "red pepper flakes",
    "red pepper powder",
    "rub",
    "saffron threads",
    "sage",
    "sage leaves",
    "salt and pepper",
    "seasoned salt",
    "seasoning",
    "seasoning blend",
    "smoked paprika",
    "spicy brown mustard",
    "stevia",
    "sukrin sweetener",
    "sweet paprika",
    "taco seasoning mix",
    "tarragon",
    "turbinado sugar",
    "turmeric",
    "vanilla bean",
    "vanilla bean paste",
    "vanilla essence",
    "vanilla extract",
    "white pepper",
    "whole allspice berries",
    "whole coriander seeds",
    "whole star anise",
    "whole-grain mustard",
    "xanthan gum",
  ],
  "Condiments & Sauces": [
    "olive oil",
    "adobo sauce",
    "alfredo pasta sauce",
    "apple butter spread",
    "apricot preserves",
    "bbq sauce",
    "black bean sauce",
    "caramel sauce",
    "caesar dressing",
    "chili paste",
    "chili sauce",
    "chive & onion cream cheese spread",
    "honey",
    "hot sauce",
    "ketchup",
    "mayo",
    "mustard",
    "salsa",
    "soy sauce",
    "steak sauce",
    "teriyaki sauce",
    "vinegar",
    "worcestershire sauce",
    "cider vinegar",
    "coconut aminos",
    "creole seasoning",
    "fish sauce",
    "frank's redhot sauce",
    "dijon mustard",
    "gravy",
    "guacamole",
    "harissa",
    "hoisin sauce",
    "honey mustard",
    "horseradish",
    "hot sauce",
    "ketchup",
    "kitchen bouquet",
    "light mayonnaise",
    "mint chutney",
    "miracle whip",
    "mustard",
    "soy sauce",
    "oyster sauce",
    "pasta sauce",
    "pesto",
    "pickle relish",
    "pickles",
    "pico de gallo",
    "pimento stuffed olives",
    "pimientos",
    "pineapple in juice",
    "pineapple juice",
    "pomegranate molasses",
    "ranch dressing",
    "ranch dressing mix",
    "raspberry jam",
    "red wine vinegar",
    "rice vinegar",
    "salsa",
    "salsa verde",
    "sherry vinegar",
    "soy sauce",
    "sriracha sauce",
    "strawberry jam",
    "sugar syrup",
    "sweet chilli sauce",
    "sweet pickle juice",
    "sweet pickle relish",
    "tahini",
    "tamari",
    "teriyaki sauce",
    "tomato juice",
    "tomato paste",
    "tomato puree",
    "tomato sauce",
    "tomato soup",
    "truffle oil",
    "vinaigrette",
    "vinegar",
    "whole cranberry sauce",
    "wine vinegar",
    "worcestershire sauce",
  ],
  "Health & Specialty Foods": [
    "almond milk",
    "arrowroot powder",
    "asafoetida",
    "asafoetida powder",
    "black sesame seeds",
    "chia seeds",
    "tofu",
    "gluten-free pasta",
    "keto bread",
    "vegan cheese",
    "coconut aminos",
    "dairy free milk",
    "erythritol",
    "extra firm tofu",
    "gluten-free pasta",
    "hemp seeds",
    "instant yeast",
    "low sodium chicken broth",
    "low sodium soy sauce",
    "low-sodium chicken stock",
    "lower sodium beef broth",
    "nutritional yeast",
    "quinoa",
    "quinoa flour",
    "silken tofu",
    "skim vanilla greek yogurt",
    "soy protein powder",
    "stevia",
    "sukrin sweetener",
    "sweetened coconut",
    "sweetened condensed milk",
    "sweetened shredded coconut",
    "tapioca flour",
    "unsweetened coconut milk",
    "unsweetened shredded coconut",
    "vanilla protein powder",
    "vegan cheese",
    "vegan chocolate chips",
    "vegan margarine",
    "vegetarian bacon",
  ],
  "Tea & Coffee": [
    "coffee",
    "tea",
    "espresso",
    "instant coffee powder",
    "instant espresso powder",
    "matcha tea",
    "tea bags",
  ],
  "Personal Care & Household": [
    "toilet paper",
    "paper towels",
    "cleaning supplies",
    "shampoo",
    "conditioner",
    "soap",
    "toothpaste",
  ],
};
