import React, { useMemo, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import Button from "commons/components/Button";
import Steps from "./components/Steps";
import Ingredients from "./components/Ingredients";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useRecipeList } from "App/RecipeListContext";
import Loader from "commons/components/Loader";
import { formatStep } from "commons/util/helpers";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import Notes from "./components/Notes";

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Navigation = styled(Flexbox)`
  position: sticky;
  left: 0;
  right: 0;
  top: 52px;
  padding: 8px 32px;
  border-bottom: 1px solid var(--neutral-180);
  border-radius: var(--border-radius-0);
  z-index: var(--z-index-above);
  background-color: var(--neutral-200);

  @media (max-width: ${BREAKPOINTS.small}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const RecipeTitle = styled(Typography)`
  max-width: 1140px;
  padding: 32px;
  margin: 0 auto;

  @media (max-width: ${BREAKPOINTS.small}) {
    padding-bottom: 8px;
  }
`;

const OverflowContainer = styled.div`
  overflow: auto;
`;

const Container = styled(Flexbox)`
  max-width: 1140px;
  margin: auto auto;
  padding: 8px 32px 120px;
  align-items: flex-start;
  gap: 64px;

  @media (max-width: ${BREAKPOINTS.large}) {
    gap: 40px;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    flex-direction: column-reverse;
    gap: 24px;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: 24px 24px 120px;
  }
`;

const StepsStyled = styled(Steps)`
  margin-top: 20px;
`;

function Player({ className }) {
  const { t } = useTranslation();
  const { recipeId } = useParams();
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);
  const navigate = useNavigate();
  const [activeStepNumber, setActiveStepNumber] = useState(1);

  const { recipeList, isLoading } = useRecipeList();

  const recipe = useMemo(() => {
    return recipeList.find(el => el.id === recipeId) || {};
  }, [recipeId, recipeList]);

  const { title, ingredients, steps = [], servings, notes } = recipe;

  const stepsWithHighlights = steps.map(el => ({ ...el, text: formatStep(el.text, ingredients) }));
  const activeStep = stepsWithHighlights.find(el => el.number === activeStepNumber);

  if (isLoading) return <Loader containerHeight="400px" />;

  return (
    <Box className={className}>
      <Helmet>
        <title>{title} | Cook Your Goose</title>
      </Helmet>
      <Navigation justifyContent="space-between" alignItems="center" gap={8}>
        <Button onClick={() => navigate(-1)} icon="arrow_back" variant="tertiary">
          {t("Recipes.BackToPreview")}
        </Button>
        <Notes notes={notes} recipe={recipe} />
        {/* <Tabs
            activeTabId="steps"
            tabs={[
              { id: "steps", label: "Steps" },
              { id: "diagram", label: "Diagram" },
            ]}
            setActiveTabId={() => {}}
          /> */}
      </Navigation>
      <OverflowContainer>
        <RecipeTitle variant={isScreenMaxMedium ? "h3" : "h2"}>{title}</RecipeTitle>
        <Container>
          <StepsStyled
            steps={stepsWithHighlights}
            activeStepNumber={activeStepNumber}
            setActiveStepNumber={setActiveStepNumber}
          />
          <Ingredients ingredients={ingredients} activeStep={activeStep} servings={servings} />
        </Container>
      </OverflowContainer>
    </Box>
  );
}

Player.propTypes = {
  className: T.string,
};

export default Player;
