import React, { useMemo, useState } from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import { useRecipeList } from "App/RecipeListContext";
import ClampedTextWithTooltip from "commons/components/ClampedTextWithTooltip";
import Popover from "commons/components/Popover";
import Skeleton from "commons/components/Skeleton";
import MealItemPreview from "./MealItemPreview";
import { MEAL_COLORS } from "commons/util/constants";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import Flexbox from "commons/components/Flexbox";
import ActionList from "commons/components/ActionList";
import { useMealPlanDispatch } from "App/MealPlanContext";
import EditItemPopoverContent from "./EditItemPopoverContent";
import { useTranslation } from "react-i18next";

const Box = styled(Flexbox)`
  background-color: ${({ color }) => `var(--${color}-190)`};
  border-left: 4px solid ${({ color }) => `var(--${color}-100)`};
  border-radius: var(--border-radius-1);
  padding: 4px 4px 4px 6px;
  font-size: 14px;
  line-height: 16px;
  transition: box-shadow 0.1s;
  min-height: 40px;

  &:hover {
    box-shadow: var(--shadow-1);
  }

  ${({ isCompact }) =>
    isCompact &&
    css`
      min-height: auto;
    `}
`;

const EditItemPopoverContentStyled = styled(EditItemPopoverContent)`
  position: absolute;
  top: 32px;
`;

const MealItem = ({ className, mealItem, isCompact }) => {
  const { t } = useTranslation();
  const [isEditPopoverOpen, setIsEditPopoverOpen] = useState(false);
  const { recipeList, isLoading } = useRecipeList();
  const { id, recipeId, mealType, label } = mealItem;
  const dispatch = useMealPlanDispatch();

  const recipe = useMemo(() => recipeList.find(r => r.id === recipeId), [recipeId, recipeList]);

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: id,
    data: {
      isNote: !recipeId,
    },
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  function removeItem() {
    dispatch({ type: "REMOVE", mealItemId: id });
  }

  if (recipeId && isLoading) {
    return <Skeleton variant="rectangle" width="100%" height={isCompact ? "24px" : "40px"} />;
  }

  return (
    <>
      <Popover
        trigger={
          <Box
            className={className}
            justifyContent="space-between"
            alignItems="center"
            color={MEAL_COLORS[mealType]}
            ref={setNodeRef}
            style={style}
            {...attributes}
            {...listeners}
            isCompact={isCompact}
          >
            <ClampedTextWithTooltip maxLines={isCompact ? 1 : 2}>{recipe?.title || label}</ClampedTextWithTooltip>
          </Box>
        }
        content={closePopover => {
          if (recipeId) return <MealItemPreview recipe={recipe} mealItemId={id} handleClose={closePopover} />;
          else
            return (
              <ActionList
                actions={[
                  { icon: "edit", label: t("Common.Edit"), onClick: () => setIsEditPopoverOpen(true) },
                  {
                    icon: "delete",
                    label: t("Common.Remove"),
                    onClick: () => {
                      removeItem();
                      closePopover();
                    },
                  },
                ]}
                closePopover={closePopover}
              />
            );
        }}
        placement="bottom-start"
        isTriggerTabbable
        stopPropagation
      />
      {isEditPopoverOpen && (
        <EditItemPopoverContentStyled item={mealItem} handleClose={() => setIsEditPopoverOpen(false)} />
      )}
    </>
  );
};

MealItem.propTypes = {
  className: T.string,
  mealItem: T.object,
  isCompact: T.bool,
};

export default MealItem;
