import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import Button from "commons/components/Button";
// import Tabs from "commons/components/Tabs";
import Flexbox from "commons/components/Flexbox";
import Divider from "commons/components/Divider";
import Typography from "commons/components/Typography";
import Tag from "commons/components/Tag";
import Image from "commons/components/Image";
import { formatDistance } from "date-fns";
import { formatTime, formatStep } from "commons/util/helpers";
import { useNavigate, useParams } from "react-router-dom";
import SanitizedHtml from "commons/components/SanitizedHtml";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import Detail from "../Preview/Detail";
import Steps from "../Preview/components/Steps";
import Ingredients from "../Preview/components/Ingredients";
import callApi from "commons/util/callApi";
import Loader from "commons/components/Loader";
import { useToast } from "commons/util/useToast";
import EmptyState from "commons/components/EmptyState";
import LinkSVG from "commons/images/emptyState/link.svg";
import Logo from "commons/components/Logo";
import RecipeImage from "../components/RecipeImage";
import { useAuth } from "App/AuthContext";
import { useTranslation } from "react-i18next";
import { useCurrentLocale } from "commons/util/useCurrentLocale";
import LinkifyText from "commons/components/LinkifyText";

const Box = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LogoStyled = styled(Logo)`
  position: absolute;
  top: 14px;
  left: 32px;
`;

const TopBarFlexbox = styled(Flexbox)`
  position: sticky;
  top: 0;
  background-color: var(--neutral-200);
  z-index: var(--z-index-above);
  border-radius: var(--border-radius-0);
  border-bottom: 1px solid var(--neutral-180);
  box-shadow: var(--shadow-1);
`;

const OverflowFlexbox = styled(Flexbox)`
  height: 100%;
  overflow: auto;
  padding: 24px 48px 48px;
  border-radius: var(--border-radius-0);
  width: 100%;
  max-width: 1136px;
  margin: auto;

  @media (max-width: ${BREAKPOINTS.small}) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const RecipeImageStyled = styled(RecipeImage)`
  cursor: zoom-in;
`;

const FullscreenImage = styled(Image)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 90%;
  max-height: 90%;
  z-index: 1;
  margin: auto;
  border-radius: var(--border-radius-3);
  cursor: zoom-out;
`;

const FullscreenImageBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--backdrop);
  z-index: var(--z-index-above);
`;

const DividerWithSpacing = styled(Divider)`
  margin-top: 32px;
  margin-bottom: 24px;
  width: calc(100% + 96px);
  margin-left: -48px;
  margin-right: -48px;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
  }
`;

function Shared() {
  const { t } = useTranslation();
  const [recipe, setRecipe] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [isFullscreenImageShown, setIsFullscreenImageShown] = useState(false);
  const currentLocale = useCurrentLocale();

  const overflowBox = useRef();
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);
  const sharingLink = useParams().sharingLink;
  const { user } = useAuth();
  const { showToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (overflowBox.current) overflowBox.current.scrollTop = 240;
  }, []);

  useEffect(() => {
    getRecipe();
  }, [sharingLink]);

  async function getRecipe() {
    try {
      const res = await callApi(`shared/${sharingLink}`, "get");
      const { expirationDate, recipe } = res;
      const expirationDateInMs = expirationDate * 1000;

      setRecipe(recipe);
      showToast(
        t("Recipes.LinkWillExpireIn", {
          expirationDate: formatDistance(new Date(expirationDateInMs), Date.now(), {
            locale: currentLocale,
          }),
        }),
        "info",
        false
      );
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      if (e.response.data === "Share link does not exist") {
        return setError(t("Recipes.ShareLinkNotFound"));
      } else if (e.response.data === "Share link has expired") {
        return setError(t("Recipes.ShareLinkExpired"));
      } else if (e.response.data === "Could not find shared recipe") {
        return setError(t("Recipes.SharedRecipeNotFound"));
      } else {
        navigate("/404");
      }
    }
  }

  if (isLoading) return <Loader containerHeight="100vh" />;

  if (error) {
    return (
      <>
        <LogoStyled link="/" />
        <EmptyState label={error} image={LinkSVG} height="100vh" />
      </>
    );
  }

  const { title, notes, imageURL, servings, tags, ingredients, steps, source, activeTime, totalTime } = recipe;

  const stepsWithHighlights = steps?.map(el => ({ ...el, text: formatStep(el.text, ingredients) }));

  function duplicateRecipeAndNavigate() {
    navigate("/app/recipes", { state: { recipe } });
  }

  if (!isLoading)
    return (
      <Box>
        <TopBarFlexbox alignItems="center" justifyContent="space-between" padding="8px 32px">
          <Logo link="/" />
          {user ? (
            <Button onClick={duplicateRecipeAndNavigate}>{t("Recipes.SaveToMyRecipes")}</Button>
          ) : (
            <Flexbox gap={12}>
              <Button variant="secondary" link="/auth/login">
                {t("Authentication.SignIn")}
              </Button>
              <Button link="/auth/register">{t("Authentication.Register")}</Button>
            </Flexbox>
          )}
        </TopBarFlexbox>

        {imageURL && (
          <RecipeImageStyled
            width="100%"
            height="400px"
            alt={t("Recipes.RecipeImage")}
            src={imageURL}
            onClick={() => setIsFullscreenImageShown(true)}
            isRemote
          />
        )}
        <OverflowFlexbox flexDirection="column" ref={overflowBox}>
          {isFullscreenImageShown && (
            <FullscreenImageBackground onClick={() => setIsFullscreenImageShown(false)}>
              <FullscreenImage width="auto" height="auto" alt={t("Recipes.RecipeImage")} src={imageURL} isRemote />
            </FullscreenImageBackground>
          )}
          <Typography variant={title.length < 42 && !isScreenMaxMedium ? "h1" : "h2"} as="h1" marginBottom={16}>
            {title}
          </Typography>
          {tags && !!tags.length && (
            <Flexbox gap={8} flexWrap="wrap">
              {tags.map(tag => (
                <Tag key={tag.id} icon={tag.icon} label={tag.label} color={tag.color} />
              ))}
            </Flexbox>
          )}

          <Flexbox flexDirection={isScreenMaxSmall ? "column" : "row"} gap={isScreenMaxSmall ? 24 : 40} marginTop={32}>
            <Detail variant="vertical" label={t("Recipes.ActiveTime")} value={formatTime(activeTime)} />
            <Detail variant="vertical" label={t("Recipes.TotalTime")} value={formatTime(totalTime)} />
            <Detail variant="vertical" label={t("Recipes.Source")} value={<LinkifyText>{source || "-"}</LinkifyText>} />
          </Flexbox>

          <DividerWithSpacing />

          <Flexbox
            flexDirection={isScreenMaxMedium ? "column-reverse" : "row"}
            alignItems="flex-start"
            gap={52}
            marginTop={24}
          >
            <Steps steps={stepsWithHighlights} />
            <Ingredients ingredients={ingredients} servings={servings} />
          </Flexbox>

          <DividerWithSpacing />

          <Flexbox flexDirection="column" gap={32}>
            {notes && (
              <Flexbox flexDirection="column" gap={8}>
                <Typography variant="h4">{t("Recipes.NotesAndTips")}</Typography>
                <Typography variant="paragraph">
                  <SanitizedHtml>{notes}</SanitizedHtml>
                </Typography>
              </Flexbox>
            )}
          </Flexbox>
        </OverflowFlexbox>
      </Box>
    );
}

export default Shared;
