import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useTranslation } from "react-i18next";
import { PLANS, PLAN_PRICES, RECIPE_LIMIT } from "commons/util/constants";
import { useFilteredRecipes } from "commons/util/useFilteredRecipes";

const FlexboxStyled = styled(Flexbox)`
  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }
`;

const LeftBox = styled(Flexbox)`
  width: 100%;
`;

const LimitBarWrap = styled.div`
  width: 100%;
  height: 4px;
  background-color: var(--primary-180);
  border-radius: 2px;
`;

const LimitBar = styled.div`
  width: ${({ value }) => value}%;
  height: 4px;
  background-color: var(--primary-100);
  border-radius: 2px;
`;

function PlanSettingContent({ value: plan }) {
  const { t } = useTranslation();

  const { recipeNumber } = useFilteredRecipes();

  const planLabelMap = {
    [PLANS.free]: t("Settings.PlanBasic"),
    [PLANS.premium]: t("Settings.PlanPremium"),
    [PLANS.vip]: t("Settings.PlanVIP"),
  };

  return (
    <FlexboxStyled justifyContent="space-between" alignItems={plan === PLANS.free ? "flex-start" : "center"} gap={48}>
      <LeftBox flexDirection="column" gap={8} flexGrow={1}>
        <Flexbox justifyContent="space-between">
          <Typography variant="body">
            {planLabelMap[plan]} - €{PLAN_PRICES[plan]} {plan === PLANS.premium && t("Pricing.PerMonth")}
          </Typography>
        </Flexbox>
        {plan === PLANS.free && (
          <>
            <Flexbox justifyContent="space-between" marginTop={12}>
              <Typography variant="label" color="neutral-120">
                {t("Pricing.RecipeStorageLimit")}
              </Typography>
              <Typography variant="label" color="neutral-120">{`${recipeNumber}/${RECIPE_LIMIT}`}</Typography>
            </Flexbox>
            <LimitBarWrap>
              <LimitBar value={Math.min(100, (recipeNumber * 100) / RECIPE_LIMIT)} />
            </LimitBarWrap>
          </>
        )}
      </LeftBox>
      <Button variant="secondary" link="/pricing">
        {plan === PLANS.free ? t("Pricing.ExplorePremiumFeatures") : t("Pricing.SeePricing")}
      </Button>
    </FlexboxStyled>
  );
}

PlanSettingContent.propTypes = {
  value: T.string,
};

export default PlanSettingContent;
