import { Extension } from "@tiptap/react";

export const NewParagraphOnShiftEnter = Extension.create({
  addKeyboardShortcuts() {
    return {
      "Shift-Enter": ({ editor }) => {
        editor.commands.enter();
        return true;
      },
    };
  },
});
