import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";
import i18next from "i18next";

const localeMap = {
  en: en,
  pl: pl,
};

export function useCurrentLocale() {
  const currentLanguage = i18next.resolvedLanguage;
  const locale = localeMap[currentLanguage];

  return locale;
}
