import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import TextInput from "commons/components/TextInput";
import Popover from "commons/components/Popover";
import Flexbox from "commons/components/Flexbox";
import Button from "commons/components/Button";
import { add, endOfMonth, format, isSameDay, startOfMonth, startOfWeek, sub, subMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import { useCurrentLocale } from "commons/util/useCurrentLocale";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useMediaQuery } from "commons/util/useMediaQuery";
import DateRangePicker from "commons/components/DateRangePicker";
import DatePickerWithMeals from "commons/components/DatePickerWithMeals";

const Box = styled.div``;

const firstDayOfTheWeek = 1; // Monday

function DateInput({ className, label, placeholder, value, onChange, isRange = false }) {
  const { t } = useTranslation();
  const currentLocale = useCurrentLocale();
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);

  const pastRangePresets = [
    {
      label: t("App.Today"),
      date: [new Date(), new Date()],
    },
    {
      label: t("App.Yesterday"),
      date: [sub(new Date(), { days: 1 }), sub(new Date(), { days: 1 })],
    },
    {
      label: t("App.Last7Days"),
      date: [sub(new Date(), { days: 6 }), new Date()],
    },
    {
      label: t("App.Last30Days"),
      date: [sub(new Date(), { days: 29 }), new Date()],
    },
    {
      label: t("App.ThisWeek"),
      date: [startOfWeek(new Date(), { weekStartsOn: firstDayOfTheWeek }), new Date()],
    },
    {
      label: t("App.ThisMonth"),
      date: [startOfMonth(new Date()), new Date()],
    },
    {
      label: t("App.LastMonth"),
      date: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
    },
  ];

  const singlePresets = [
    {
      label: t("App.Today"),
      date: new Date(),
    },
    {
      label: t("App.Tomorrow"),
      date: add(new Date(), { days: 1 }),
    },
    {
      label: t("App.In2Days"),
      date: add(new Date(), { days: 2 }),
    },
    {
      label: t("App.In3Days"),
      date: add(new Date(), { days: 3 }),
    },
  ];

  const presets = isRange ? pastRangePresets : singlePresets;
  const [dateShorthand, setDateShorthand] = useState(getDateShorthand(value));

  useEffect(() => {
    setDateShorthand(getDateShorthand(value));
  }, [value]);

  const setPreset = (presetDate, presetLabel) => {
    onChange(presetDate);
    setDateShorthand(presetLabel);
  };

  const setDateProxy = date => {
    onChange(date);
    setDateShorthand(getDateShorthand(date));
  };

  function getDateShorthand(date) {
    if (isRange) {
      if (date[0] && date[1]) {
        const foundPresetLabel = pastRangePresets.find(preset => {
          isSameDay(preset.date[0], date[0]) && isSameDay(preset.date[1], date[1]);
        })?.label;
        return (
          foundPresetLabel ||
          `${format(date[0], "d MMM", { locale: currentLocale })} - ${format(date[1], "d MMM", {
            locale: currentLocale,
          })}`
        );
      } else if (date[0] && !date[1]) {
        return `${format(date[0], "d MMM y", { locale: currentLocale })}`;
      }
      return "";
    } else {
      if (date instanceof Date) {
        const foundPresetLabel = singlePresets.find(preset => isSameDay(preset.date, date))?.label;
        return foundPresetLabel || format(date, "d MMM y");
      } else {
        return "";
      }
    }
  }

  return (
    <Box className={className}>
      <Popover
        trigger={
          <TextInput
            value={dateShorthand}
            placeholder={placeholder}
            label={label}
            leftIcon="event"
            rightIcon={dateShorthand ? "close" : ""}
            onRightIconClick={() => setDateProxy(isRange ? [] : null)}
            isShell
          />
        }
        content={
          <Flexbox gap={16} padding={16} flexDirection={isScreenMaxSmall ? "column-reverse" : "row"}>
            <Flexbox flexDirection="column" gap={4}>
              {presets.map(preset => (
                <Button
                  key={preset.label}
                  variant="tertiary"
                  onClick={() => setPreset(preset.date, preset.label)}
                  fullWidth
                >
                  {preset.label}
                </Button>
              ))}
            </Flexbox>
            {isRange ? (
              <DateRangePicker startDate={value[0]} endDate={value[1]} onChange={setDateProxy} />
            ) : (
              <DatePickerWithMeals selectedDate={value} onChange={setDateProxy} />
            )}
          </Flexbox>
        }
      />
    </Box>
  );
}

DateInput.propTypes = {
  className: T.string,
  label: T.string,
  placeholder: T.string,
  value: T.oneOfType([T.array, T.instanceOf(Date)]),
  onChange: T.func,
  isRange: T.bool,
};

export default DateInput;
