import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import { Outlet } from "react-router-dom";
import EmptyState from "commons/components/EmptyState";
import noGroceries from "commons/images/emptyState/groceries.png"; // change later
import SuggestionListSidebar from "./Suggestions/SuggestionListSidebar";
import GroceryItemList from "./components/GroceryItemList";
import PlannedRecipes from "./components/PlannedRecipes";
import { BREAKPOINTS } from "commons/util/breakpoints";
import Typography from "commons/components/Typography";
import Loader from "commons/components/Loader";
import Button from "commons/components/Button";
import { useMediaQuery } from "commons/util/useMediaQuery";
import Popover from "commons/components/Popover";
import ActionList from "commons/components/ActionList";
import Tooltip from "commons/components/Tooltip";
import { useTranslation } from "react-i18next";
import RecipeListSidebar from "App/components/RecipeListSidebar";
import { VIEWS } from "./Groceries.container";
import Tabs from "commons/components/Tabs";

const Box = styled(Flexbox)`
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-0);

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: calc(100vh - 52px);
    flex-direction: column;
  }
`;

const MainWrap = styled.div`
  width: 100%;
  height: calc(100vh - 52px);
  overflow-y: auto;
  border-right: 1px solid var(--neutral-180);
  border-left: 1px solid var(--neutral-180);

  @media (max-width: ${BREAKPOINTS.large}) {
    border-left: none;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: auto;
    flex-grow: 1;
  }
`;

const Main = styled(Flexbox)`
  width: 800px;
  max-width: 100%;
  padding: 0 64px 64px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 0 32px 0;
  }
`;

const Title = styled(Typography)`
  line-height: 40px;
  @media (max-width: ${BREAKPOINTS.large}) {
    margin: 0 auto;
  }
`;

const InvisibleButtonPlaceholder = styled.div`
  visibility: hidden;
  width: 32px;
  height: 32px;
`;

function Groceries({
  className,
  groceryList,
  isGroceryListLoading,
  groupedGroceryList,
  toggleGroceryItem,
  removeGroceryItem,
  editGroceryItem,
  toggleGroceryListRecipe,
  setGroceryList,
  view,
  setView,
  clearAllItems,
  clearAllCheckedItems,
  checkAllItems,
  uncheckAllItems,
  copyListAsText,
  isRecipeListSidebarOpen,
  setIsRecipeListSidebarOpen,
  addToGroceryHistory,
}) {
  const { t } = useTranslation();
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);
  const isScreenMaxLarge = useMediaQuery(BREAKPOINTS.large);

  return (
    <Box className={className}>
      <RecipeListSidebar
        onCornerIconClick={toggleGroceryListRecipe}
        isOpen={isRecipeListSidebarOpen}
        setIsOpen={setIsRecipeListSidebarOpen}
      />

      <MainWrap>
        <Main forwardedAs="main" flexDirection="column" marginX="auto">
          <Flexbox
            alignItems="center"
            justifyContent="space-between"
            gap={16}
            paddingY={24}
            marginBottom={16}
            flexWrap="wrap"
          >
            <Flexbox
              alignItems="center"
              justifyContent="space-between"
              gap={16}
              flexBasis={isScreenMaxSmall ? "100%" : "auto"}
            >
              {/*
               * on mobile: button with no tooltip
               * on small desktop: button with tooltip
               */}
              {isScreenMaxMedium ? (
                <Button icon="view_list" variant="tertiary" onClick={() => setIsRecipeListSidebarOpen(true)} />
              ) : (
                isScreenMaxLarge && (
                  <Tooltip label={t("Groceries.PlanRecipes")} placement="right">
                    <Button icon="view_list" variant="tertiary" onClick={() => setIsRecipeListSidebarOpen(true)} />
                  </Tooltip>
                )
              )}
              <Title variant="h3">{t("Groceries.GroceryList")}</Title>
              {!isGroceryListLoading && !!groceryList.length ? (
                <Popover
                  trigger={<Button variant="tertiary" icon="more_vert" />}
                  content={closePopover => (
                    <ActionList
                      actions={[
                        { label: t("Groceries.CopyAsText"), icon: "content_copy", onClick: copyListAsText },
                        { variant: "divider" },
                        { label: t("Groceries.CheckAll"), icon: "check_circle", onClick: checkAllItems },
                        { label: t("Groceries.UncheckAll"), icon: "radio_button_unchecked", onClick: uncheckAllItems },
                        { variant: "divider" },
                        {
                          label: t("Groceries.ClearChecked"),
                          icon: "checklist",
                          onClick: clearAllCheckedItems,
                        },
                        {
                          label: t("Groceries.ClearAll"),
                          icon: "playlist_remove",
                          onClick: clearAllItems,
                          variant: "destructive",
                        },
                      ]}
                      closePopover={closePopover}
                    />
                  )}
                  placement="bottom-start"
                />
              ) : (
                <InvisibleButtonPlaceholder aria-hidden="true" />
              )}
            </Flexbox>
            <Tabs
              tabs={[
                { id: VIEWS.BY_AISLE, label: "By aisle" },
                { id: VIEWS.BY_RECIPE, label: "By recipe" },
              ]}
              activeTabId={view}
              setActiveTabId={setView}
            />
          </Flexbox>
          <PlannedRecipes groceryList={groceryList} handleRecipeCardClick={toggleGroceryListRecipe} />
          {isGroceryListLoading && <Loader containerHeight="480px" />}
          {!isGroceryListLoading && !groceryList.length && (
            <EmptyState image={noGroceries} label={t("Groceries.GroceriesEmpty")} height="480px" />
          )}
          {!isGroceryListLoading && (
            <GroceryItemList
              groupedGroceryList={groupedGroceryList}
              view={view}
              toggleGroceryItem={toggleGroceryItem}
              removeGroceryItem={removeGroceryItem}
              editGroceryItem={editGroceryItem}
            />
          )}
        </Main>
      </MainWrap>

      <SuggestionListSidebar
        groceryList={groceryList}
        isGroceryListLoading={isGroceryListLoading}
        setGroceryList={setGroceryList}
        addToGroceryHistory={addToGroceryHistory}
      />

      <Outlet />
    </Box>
  );
}

Groceries.propTypes = {
  className: T.string,
  groceryList: T.array,
  isGroceryListLoading: T.bool,
  groupedGroceryList: T.object,
  toggleGroceryItem: T.func,
  removeGroceryItem: T.func,
  editGroceryItem: T.func,
  toggleGroceryListRecipe: T.func,
  setGroceryList: T.func,
  view: T.string,
  setView: T.func,
  clearAllItems: T.func,
  clearAllCheckedItems: T.func,
  checkAllItems: T.func,
  uncheckAllItems: T.func,
  copyListAsText: T.func,
  isRecipeListSidebarOpen: T.bool,
  setIsRecipeListSidebarOpen: T.func,
  addToGroceryHistory: T.func,
};

export default Groceries;
