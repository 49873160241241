import React from "react";
import styled from "styled-components/macro";
import T from "prop-types";
import Icon from "../Icon";
import { getDomainFromUrl } from "commons/util/helpers";

const ExternalLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  color: var(--primary-100);
  transition: color 0.1s;

  &:hover,
  &:focus-visible {
    color: var(--primary-90);
  }
`;

function LinkifyText({ children }) {
  const parts = children.split(/(https?:\/\/[^\s]+)/g);

  return (
    <>
      {parts.map((part, index) => {
        if (/^https?:\/\//.test(part)) {
          const url = new URL(part);
          const domain = getDomainFromUrl(part);

          return (
            <ExternalLink
              key={index}
              href={url.href}
              target="_blank"
              rel="noopener noreferrer"
              className="link-with-icon"
            >
              {domain}
              <Icon name="open_in_new" />
            </ExternalLink>
          );
        }
        return part;
      })}
    </>
  );
}

LinkifyText.propTypes = {
  children: T.string,
};

export default LinkifyText;
