import React from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import MealItem from "./MealItem";
import { useDroppable } from "@dnd-kit/core";
import { isPast, isToday } from "date-fns";
import Popover from "commons/components/Popover";
import AddItemPopoverContent from "./AddItemPopoverContent";
import { MEAL_COLORS, TIME_FRAMES } from "commons/util/constants";
import Flexbox from "commons/components/Flexbox";
import { useTranslation } from "react-i18next";
import Typography from "commons/components/Typography";
import Icon from "commons/components/Icon";

const MealTypeLabel = styled(Typography)`
  color: ${({ color }) => `var(--${color}-100)`};
  line-height: 28px;
  display: flex;
  align-items: center;
  opacity: 0;
`;

const Box = styled(Flexbox)`
  position: relative;
  min-height: 28px;
  border-radius: var(--border-radius-0);

  ${({ isOver }) =>
    isOver &&
    css`
      background-color: ${({ color }) => `var(--${color}-190)`};
    `}

  &:hover {
    background-color: var(--neutral-190);

    ${MealTypeLabel} {
      opacity: 1;
    }
  }

  ${({ timeFrame }) => {
    if (timeFrame === TIME_FRAMES.day) {
      return css`
        border-top: 1px solid var(--neutral-180);
        padding: 24px 16px;
      `;
    } else if (timeFrame === TIME_FRAMES.week) {
      return css`
        border-top: 1px solid var(--neutral-180);
      `;
    }
  }}
`;

function MealBlock({ date, mealType, dateMealItems = [], timeFrame }) {
  const { t } = useTranslation();
  const mealItems = dateMealItems.filter(meal => meal.mealType === mealType);

  const { setNodeRef, isOver } = useDroppable({
    id: `${date}-${mealType}`,
    data: { date: date, mealType: mealType },
  });

  return (
    <Popover
      trigger={
        <Box
          flexDirection="column"
          padding={4}
          gap={4}
          flexShrink={1}
          flexGrow={1}
          ref={setNodeRef}
          isPast={isPast(date) && !isToday(date)}
          isOver={isOver}
          timeFrame={timeFrame}
          color={MEAL_COLORS[mealType]}
        >
          {mealItems.length === 0 && (
            <MealTypeLabel variant="label" color={MEAL_COLORS[mealType]}>
              <Icon name="add" size={16} /> {t(`Calendar.${mealType}`)}
            </MealTypeLabel>
          )}
          {mealItems.map(mealItem => (
            <MealItem key={mealItem.id} mealItem={mealItem} isCompact={timeFrame === TIME_FRAMES.month} />
          ))}
        </Box>
      }
      content={closePopover => <AddItemPopoverContent date={date} mealType={mealType} handleClose={closePopover} />}
      triggerStyles={{ flexGrow: 1, display: "flex" }}
    />
  );
}

MealBlock.propTypes = {
  date: T.instanceOf(Date).isRequired,
  mealType: T.string.isRequired,
  dateMealItems: T.array.isRequired,
  timeFrame: T.string.isRequired,
};

export default MealBlock;
