import React, { useEffect, useMemo, useState } from "react";
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  getMonth,
  getWeeksInMonth,
  isSameDay,
  isSameMonth,
  isSameWeek,
  isThisMonth,
  subDays,
  subMonths,
  subWeeks,
} from "date-fns";
import PlannerDay from "./PlannerDay";
import { MEAL_TYPES, TIME_FRAMES } from "commons/util/constants";
import callLocalStorage from "commons/util/callLocalStorage";
import { useMealPlan, useMealPlanDispatch } from "App/MealPlanContext";
import Planner from "./Planner";
import { useCurrentLocale } from "commons/util/useCurrentLocale";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { getDatesInTimeFrame } from "commons/util/helpers";

const firstDayOfTheWeek = 1; // Monday - To do - user preference

function matchHeightsAcrossMealBlocks(shownDate) {
  const allDays = document.querySelectorAll(".planner-day");
  const numWeeks = getWeeksInMonth(shownDate, { weekStartsOn: firstDayOfTheWeek });
  const numMealBlocks = Object.entries(MEAL_TYPES).length;

  // i = week
  for (let i = 0; i < numWeeks; i++) {
    const days = Array.from(allDays).slice(i * 7, (i + 1) * 7);
    // j = meal block (ignoring first child which is the day number label)
    for (let j = 1; j < numMealBlocks + 1; j++) {
      let height = 0;

      // Determine the max height for the current row across all days in a week
      days.forEach(day => {
        const cell = day.children[j];

        if (cell) {
          // first child is a popover wrapper
          const numberOfMeals = cell.children[0].children.length;
          const baseHeight = 36;
          const additionalHeightPerMeal = 28;
          const additionalMeals = numberOfMeals > 0 ? numberOfMeals - 1 : 0;
          const totalHeight = baseHeight + additionalHeightPerMeal * additionalMeals;
          height = Math.max(height, totalHeight);
        }
      });

      // Set the height for the current row across all days in a week
      days.forEach(day => {
        const cell = day.children[j];
        if (cell) {
          cell.style.flexBasis = `${height}px`;
        }
      });
    }
  }
}

function PlannerContainer() {
  const rememberedTimeFrame = callLocalStorage(`calendar-time-frame`, "get");
  const [shownDate, setShownDate] = useState(new Date());
  const [timeFrame, setTimeFrame] = useState(rememberedTimeFrame || TIME_FRAMES.month);
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);
  const currentLocale = useCurrentLocale();

  const { mealPlan } = useMealPlan();
  const dispatch = useMealPlanDispatch();

  useEffect(() => {
    callLocalStorage(`calendar-time-frame`, "set", timeFrame);
  }, [timeFrame]);

  useEffect(() => {
    if ([TIME_FRAMES.month, TIME_FRAMES.week].includes(timeFrame)) {
      matchHeightsAcrossMealBlocks(shownDate);
    }
  }, [mealPlan, shownDate, timeFrame]);

  useEffect(() => {
    function scrollToCurrentWeek() {
      if (timeFrame === TIME_FRAMES.month && !isScreenMaxSmall) {
        const main = document.querySelector("main");
        if (isThisMonth(shownDate)) {
          const yOffset = -188;
          const element = document.querySelector(`.day-${format(new Date(), "d-MM")}`);
          const y = element?.getBoundingClientRect().top + yOffset;
          main.scrollTo({ top: y });
        } else {
          main.scrollTo({ top: 0 });
        }
      }
    }
    scrollToCurrentWeek();
  }, [timeFrame, shownDate]);

  const goToday = () => setShownDate(new Date());
  const goPrevPeriod = () => {
    if (timeFrame === TIME_FRAMES.month) {
      setShownDate(subMonths(shownDate, 1));
    } else if (timeFrame === TIME_FRAMES.week) {
      setShownDate(subWeeks(shownDate, 1));
    } else {
      setShownDate(subDays(shownDate, 1));
    }
  };
  const goNextPeriod = () => {
    if (timeFrame === TIME_FRAMES.month) {
      setShownDate(addMonths(shownDate, 1));
    } else if (timeFrame === TIME_FRAMES.week) {
      setShownDate(addWeeks(shownDate, 1));
    } else {
      setShownDate(addDays(shownDate, 1));
    }
  };

  function clearTimeFrame() {
    let newMealPlan = mealPlan;
    if (timeFrame === TIME_FRAMES.month) {
      newMealPlan = mealPlan.filter(m => !isSameMonth(m.date, shownDate));
    } else if (timeFrame === TIME_FRAMES.week) {
      newMealPlan = mealPlan.filter(m => !isSameWeek(m.date, shownDate, { weekStartsOn: firstDayOfTheWeek }));
    } else {
      newMealPlan = mealPlan.filter(m => !isSameDay(m.date, shownDate));
    }
    dispatch({ type: "UPDATE", mealPlan: newMealPlan });
  }

  function showDay(date) {
    setShownDate(date);
    setTimeFrame(TIME_FRAMES.day);
  }

  const datesInTimeFrame = getDatesInTimeFrame(timeFrame, shownDate);

  const plannerDays = datesInTimeFrame.map(date => (
    <PlannerDay
      key={format(date, "T", { locale: currentLocale })}
      date={date}
      timeFrame={timeFrame}
      shownDate={shownDate}
      showDay={showDay}
    />
  ));

  const periodLabel = useMemo(() => {
    if (timeFrame === TIME_FRAMES.month) {
      return format(shownDate, "LLLL yyyy", { locale: currentLocale });
    } else if (timeFrame === TIME_FRAMES.week) {
      const dateStart = datesInTimeFrame[0];
      const dateEnd = datesInTimeFrame[datesInTimeFrame.length - 1];

      if (getMonth(dateStart) !== getMonth(dateEnd)) {
        return (
          format(dateStart, "MMM", { locale: currentLocale }) +
          " - " +
          format(dateEnd, "MMM yyyy", { locale: currentLocale })
        );
      } else {
        return format(shownDate, "LLLL yyyy", { locale: currentLocale });
      }
    } else return format(shownDate, "EEEE, d MMMM yyyy", { locale: currentLocale });
  }, [timeFrame, shownDate]);

  return (
    <Planner
      timeFrame={timeFrame}
      setTimeFrame={setTimeFrame}
      plannerDays={plannerDays}
      goToday={goToday}
      goPrevPeriod={goPrevPeriod}
      goNextPeriod={goNextPeriod}
      clearTimeFrame={clearTimeFrame}
      periodLabel={periodLabel}
      shownDate={shownDate}
      setShownDate={setShownDate}
    />
  );
}

export default PlannerContainer;
