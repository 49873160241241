import React, { useEffect, useMemo, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import TextInput from "commons/components/TextInput";
import Button from "commons/components/Button";
import { useTranslation } from "react-i18next";
import { getNextId } from "commons/util/helpers";
import { useMealPlan, useMealPlanDispatch } from "App/MealPlanContext";
import SelectInput from "commons/components/SelectInput";
import { MEAL_TYPES } from "commons/util/constants";
import Tabs from "commons/components/Tabs";
import Combobox from "commons/components/Combobox";
import { useRecipeList } from "App/RecipeListContext";
import DateInput from "commons/components/DateInput";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useToast } from "commons/util/useToast";

const Box = styled(Flexbox)`
  width: 350px;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: auto;
  }
`;

const TabsStyled = styled(Tabs)`
  align-self: flex-start;
`;

const TABS = {
  recipe: "RECIPE",
  note: "NOTE",
  ingredient: "INGREDIENT",
};

function AddItemPopoverContent({ recipeId: recipeIdInit, date: dateInit, mealType: mealTypeInit, handleClose }) {
  const { t } = useTranslation();
  const { showToast } = useToast();

  const [selectedTabId, setSelectedTabId] = useState(TABS.recipe);

  const [selectedRecipeId, setSelectedRecipeId] = useState(recipeIdInit || null);
  const [recipeError, setRecipeError] = useState("");

  const [label, setLabel] = useState("");
  const [labelError, setLabelError] = useState("");

  const [mealType, setMealType] = useState(mealTypeInit || MEAL_TYPES.dinner);
  const [date, setDate] = useState(dateInit);

  const { mealPlan } = useMealPlan();
  const { recipeList } = useRecipeList();
  const dispatch = useMealPlanDispatch();

  const isUsedInRecipeManager = !!recipeIdInit;

  const recipeListOptions = useMemo(() => {
    return recipeList.map(recipe => {
      return {
        id: recipe.id,
        label: recipe.title,
      };
    });
  }, [recipeList]);

  useEffect(() => {
    setLabelError("");
    setRecipeError("");
  }, [selectedTabId]);

  useEffect(() => {
    if (labelError) setLabelError("");
  }, [label]);

  useEffect(() => {
    if (recipeError) setRecipeError("");
  }, [selectedRecipeId]);

  function addNewItem() {
    if (selectedTabId === TABS.recipe) {
      if (selectedRecipeId) {
        dispatch({
          type: "ADD",
          mealItem: { id: getNextId(mealPlan) + 1, recipeId: selectedRecipeId, date, mealType },
        });
        setSelectedRecipeId(null);
        showToast(t("Calendar.RecipeAdded"));
        handleClose();
      } else {
        setRecipeError(t("Calendar.RecipeEmptyError"));
      }
    } else if (selectedTabId === TABS.note) {
      if (label) {
        dispatch({ type: "ADD", mealItem: { id: getNextId(mealPlan) + 1, label: label, date, mealType } });
        setLabel("");
        handleClose();
      } else {
        setLabelError(t("Calendar.NoteEmptyError"));
      }
    } else if (selectedTabId === TABS.ingredient) {
      if (label) {
        dispatch({
          type: "ADD",
          mealItem: { id: getNextId(mealPlan) + 1, label: label, date, mealType, isProduct: true },
        });
        setLabel("");
        handleClose();
      } else {
        setLabelError(t("Calendar.ProductEmptyError"));
      }
    }
  }

  return (
    <Box flexDirection="column" padding={16} gap={16}>
      <Typography variant={isUsedInRecipeManager ? "h3" : "h4"}>{t("Calendar.AddCalendarItem")}</Typography>
      {!isUsedInRecipeManager && (
        <TabsStyled
          tabs={[
            { id: TABS.recipe, label: t("Calendar.Recipe") },
            { id: TABS.note, label: t("Calendar.Note") },
            { id: TABS.ingredient, label: t("Calendar.Product") },
          ]}
          activeTabId={selectedTabId}
          setActiveTabId={setSelectedTabId}
        />
      )}
      {selectedTabId === TABS.recipe ? (
        <Combobox
          label={t("Calendar.Recipe")}
          placeholder={t("Calendar.RecipePlaceholder")}
          options={recipeListOptions}
          value={selectedRecipeId}
          onChange={val => setSelectedRecipeId(val)}
          errorMessage={recipeError}
          maxLength={200}
        />
      ) : (
        <TextInput
          label={selectedTabId === TABS.note ? t("Calendar.Note") : t("Calendar.Product")}
          placeholder={selectedTabId === TABS.note ? t("Calendar.NotePlaceholder") : t("Calendar.ProductPlaceholder")}
          value={label}
          errorMessage={labelError}
          onChange={setLabel}
          onConfirm={addNewItem}
          maxLength={100}
        />
      )}
      <SelectInput
        label={t("Calendar.Meal")}
        value={mealType}
        onChange={setMealType}
        options={[
          {
            id: MEAL_TYPES.breakfast,
            label: t("Calendar.Breakfast"),
          },
          {
            id: MEAL_TYPES.lunch,
            label: t("Calendar.Lunch"),
          },
          {
            id: MEAL_TYPES.dinner,
            label: t("Calendar.Dinner"),
          },
          {
            id: MEAL_TYPES.snacks,
            label: t("Calendar.Snacks"),
          },
        ]}
      />
      <DateInput label={t("Calendar.Date")} placeholder="Select date" value={date} onChange={setDate} />
      <Flexbox justifyContent="flex-end" gap={8} marginTop={16}>
        <Button onClick={handleClose} variant="tertiary">
          {t("Common.Cancel")}
        </Button>
        <Button onClick={addNewItem}>{t("Common.Add")}</Button>
      </Flexbox>
    </Box>
  );
}

AddItemPopoverContent.propTypes = {
  recipeId: T.string,
  date: T.instanceOf(Date),
  mealType: T.string,
  handleClose: T.func.isRequired,
};

export default AddItemPopoverContent;
