import React, { useEffect } from "react";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import RecipeGrid from "./components/RecipeGrid";
import RecipeList from "./components/RecipeList";
import EmptyState from "commons/components/EmptyState";
import noRecipesImage from "commons/images/emptyState/recipes.svg";
import noSearchResultsImage from "commons/images/emptyState/searchResults.svg";
import { useRecipeListDispatch } from "App/RecipeListContext";
import { BREAKPOINTS } from "commons/util/breakpoints";
import callApi from "commons/util/callApi";
import { useToast } from "commons/util/useToast";
import T from "prop-types";
import Toolbar from "./Toolbar";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  width: 944px;
  max-width: 100%;
  margin: 40px auto 80px;

  @media (max-width: ${BREAKPOINTS.large}) {
    margin-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
  }
`;

function Recipes({
  recipeList,
  isLoading,
  filters,
  hasActiveFilters,
  setFilters,
  activeTabId,
  setActiveTabId,
  activeViewTabId,
  setActiveViewTabId,
  searchString,
  setSearchString,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useRecipeListDispatch();
  const { showToast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    async function duplicateRecipe(recipe) {
      const newRecipe = { ...recipe, title: `${recipe.title} ${t("Recipes.Shared")}` };
      delete newRecipe.id;
      try {
        const res = await callApi("recipes", "post", newRecipe);
        dispatch({ type: "CREATE", recipe: { ...newRecipe, id: res.id } });
        showToast(t("Recipes.SharedRecipeSaved"));
        navigate("/app/recipes", { state: null });
      } catch (e) {
        showToast(t("Common.GenericError"), "error");
      }
    }

    const recipeToDuplicate = location.state?.recipe;

    if (recipeToDuplicate) {
      duplicateRecipe(recipeToDuplicate);
    }
  }, [location.state?.recipe]);

  return (
    <Container>
      <Flexbox flexDirection="column" gap={24}>
        <Toolbar
          filters={filters}
          hasActiveFilters={hasActiveFilters}
          setFilters={setFilters}
          activeTabId={activeTabId}
          setActiveTabId={setActiveTabId}
          activeViewTabId={activeViewTabId}
          setActiveViewTabId={setActiveViewTabId}
          searchString={searchString}
          setSearchString={setSearchString}
        />

        {activeViewTabId === "grid" ? (
          <RecipeGrid recipes={recipeList} isLoading={isLoading} />
        ) : (
          <RecipeList recipes={recipeList} isLoading={isLoading} />
        )}

        {!isLoading && !recipeList.length && !(hasActiveFilters || searchString) && (
          <EmptyState image={noRecipesImage} label={t("Recipes.NoRecipes")} height="400px" />
        )}
        {!isLoading && !recipeList.length && (hasActiveFilters || searchString) && (
          <EmptyState image={noSearchResultsImage} label={t("Common.NoSearchResults")} height="400px" />
        )}
      </Flexbox>
      {!isLoading && <Outlet />}
    </Container>
  );
}

Recipes.propTypes = {
  recipeList: T.array,
  isLoading: T.bool,
  filters: T.object,
  hasActiveFilters: T.bool,
  setFilters: T.func,
  activeTabId: T.string,
  setActiveTabId: T.func,
  activeViewTabId: T.string,
  setActiveViewTabId: T.func,
  searchString: T.string,
  setSearchString: T.func,
};

export default Recipes;
