import React, { useMemo, useState } from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Badge from "commons/components/Badge";
import Icon from "commons/components/Icon";
import { AnimatePresence, motion } from "framer-motion";
import { useRecipeList } from "App/RecipeListContext";
import { useTranslation } from "react-i18next";
import RecipeCard from "App/components/RecipeCard";
import Tooltip from "commons/components/Tooltip";

const Box = styled(Flexbox)`
  margin-bottom: 24px;
  background-color: var(--neutral-190);
  cursor: pointer;
  overflow: hidden;
  padding: 8px 12px;

  ${({ $isExpanded }) =>
    $isExpanded &&
    css`
      padding-bottom: 12px;
    `}
`;

function TopBar({ groceryList, handleRecipeCardClick }) {
  const { t } = useTranslation();
  const [arePlannedRecipesExpanded, setArePlannedRecipesShown] = useState(false);
  const { recipeList } = useRecipeList();

  const plannedRecipes = useMemo(() => {
    if (groceryList) {
      return [...new Set(groceryList.map(item => item.recipeId))].filter(Boolean);
    } else {
      return [];
    }
  }, [groceryList]);

  if (!plannedRecipes.length) {
    return null;
  }

  return (
    <Box
      flexDirection="column"
      tabIndex="0"
      onClick={() => setArePlannedRecipesShown(shown => !shown)}
      $isExpanded={arePlannedRecipesExpanded}
    >
      <Flexbox alignItems="center" justifyContent="space-between">
        <Flexbox gap={8} alignItems="center">
          {t("Groceries.PlannedRecipes")}
          <Badge label={plannedRecipes.length} />
        </Flexbox>
        <Icon size={20} name={arePlannedRecipesExpanded ? "expand_less" : "expand_more"} />
      </Flexbox>

      <AnimatePresence>
        {arePlannedRecipesExpanded && (
          <Flexbox
            as={motion.div}
            flexDirection="column"
            gap={8}
            key={arePlannedRecipesExpanded}
            initial={{ opacity: 0, height: 0, marginTop: 0 }}
            animate={{ opacity: 1, height: "auto", marginTop: "16px" }}
            exit={{ opacity: 0, height: 0, marginTop: 0 }}
            transition={{ duration: 0.2 }}
          >
            {plannedRecipes.map(recipeId => {
              const recipe = recipeList.find(recipe => recipe.id === recipeId);
              if (recipe) {
                return (
                  <RecipeCard
                    key={recipe.id}
                    recipe={recipe}
                    CornerIcon={() => {
                      const hasNoIngredients = recipe.ingredients.filter(ingredient => ingredient.label).length === 0;
                      return (
                        <Tooltip
                          label={hasNoIngredients ? t("Groceries.NoIngredientsToAdd") : ""}
                          triggerStyles={{ position: "absolute", top: "8px", right: "8px" }}
                        >
                          <Icon
                            size={20}
                            name="check_circle"
                            color="red-100"
                            isFilled
                            onClick={e => {
                              e.stopPropagation();
                              handleRecipeCardClick(recipe);
                            }}
                          />
                        </Tooltip>
                      );
                    }}
                  />
                );
              }
            })}
          </Flexbox>
        )}
      </AnimatePresence>
    </Box>
  );
}

TopBar.propTypes = {
  className: T.string,
  groceryList: T.array,
  handleRecipeCardClick: T.func,
};

export default TopBar;
