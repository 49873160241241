import React, { useState } from "react";
import T from "prop-types";
import Calendar from "./Calendar";
import { useRecipeList } from "App/RecipeListContext";
import { useMealPlan, useMealPlanDispatch } from "App/MealPlanContext";
import { getNextId } from "commons/util/helpers";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

function CalendarContainer({ className }) {
  const { t } = useTranslation();
  const [isRecipeListSidebarOpen, setIsRecipeListSidebarOpen] = useState(false);
  const { recipeList } = useRecipeList();
  const [draggedRecipe, setDraggedRecipe] = useState(null);
  const [draggedMealItem, setDraggedMealItem] = useState(null);
  const { mealPlan } = useMealPlan();
  const dispatch = useMealPlanDispatch();

  function addRecipeToMealPlan({ recipeId, date, mealType }) {
    dispatch({ type: "ADD", mealItem: { id: getNextId(mealPlan) + 1, date, recipeId: recipeId, mealType } });
  }

  function moveMealPlanItem({ mealItemId, newDate, newMealType }) {
    const newMealPlan = mealPlan.map(m => {
      if (m.id === mealItemId) {
        return { ...m, date: newDate, mealType: newMealType };
      }
      return m;
    });
    dispatch({ type: "UPDATE", mealPlan: newMealPlan });
  }

  function handleDragStart(event) {
    const recipe = recipeList.find(r => r.id === event.active.id);
    if (recipe) {
      setDraggedRecipe(recipe);
    } else {
      setDraggedMealItem(mealPlan.find(m => m.id === event.active.id));
    }
  }

  function handleDragEnd(e) {
    const { active, over } = e;
    if (active && over) {
      const date = over.data.current.date;
      const mealType = over.data.current.mealType;

      if (draggedRecipe) {
        addRecipeToMealPlan({
          recipeId: active.id,
          date: date,
          mealType: mealType,
        });
      } else if (draggedMealItem) {
        moveMealPlanItem({
          mealItemId: active.id,
          newDate: date,
          newMealType: mealType,
        });
      }
    }
    setDraggedRecipe(null);
    setDraggedMealItem(null);
  }

  return (
    <>
      <Helmet>
        <title>{t("Navigation.Calendar")} | Cook Your Goose</title>
      </Helmet>
      <Calendar
        className={className}
        isRecipeListSidebarOpen={isRecipeListSidebarOpen}
        setIsRecipeListSidebarOpen={setIsRecipeListSidebarOpen}
        draggedRecipe={draggedRecipe}
        draggedMealItem={draggedMealItem}
        handleDragStart={handleDragStart}
        handleDragEnd={handleDragEnd}
      />
    </>
  );
}

CalendarContainer.propTypes = {
  className: T.string,
};

export default CalendarContainer;
