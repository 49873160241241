import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import "./i18n";
import { ToastProvider } from "commons/util/useToast";
import { TimerProvider } from "commons/util/useTimer";

import Website from "Website/Website";
import Home from "Website/Home/Home";
import Features from "Website/Features/Features";
import Pricing from "Website/Pricing/Pricing";
import Contact from "Website/Contact/Contact";

import Authentication from "Authentication/Authentication";
import Registration from "Authentication/Registration";
import Login from "Authentication/Login";
import PasswordReset from "Authentication/PasswordReset";

import App from "App/App";
import Recipes from "App/Recipes/Recipes.container";
import Preview from "App/Recipes/Preview";
import Player from "App/Recipes/Player";
import EditorContainer from "App/Recipes/Editor/Editor.container";
import NewWebRecipeModal from "App/Recipes/components/NewWebRecipeModal";

import Calendar from "App/Calendar/Calendar.container";

import Groceries from "App/Groceries/Groceries.container";

import Settings from "App/Settings/Settings";
import Account from "App/Settings/Account/Account";
import Preferences from "App/Settings/Preferences/Preferences";
import TagsContainer from "App/Settings/Tags/Tags.container";

// import Feedback from "App/Feedback";

import Page404 from "Page404";
import { AuthProvider } from "App/AuthContext";
import ShareRecipeModal from "App/Recipes/components/ShareRecipeModal";
import Shared from "App/Recipes/Shared";
import RecipeLimitModal from "App/Recipes/components/RecipeLimitModal";
import AddToCalendarModal from "App/Recipes/components/AddToCalendarModal";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <AuthProvider>
    <ToastProvider>
      <BrowserRouter>
        <TimerProvider>
          <Routes>
            <Route path="/" element={<Website />}>
              <Route index element={<Home />} />
              <Route path="features" element={<Features />} />
              <Route path="pricing" element={<Pricing />} />
              <Route path="contact" element={<Contact />} />
            </Route>

            <Route path="auth" element={<Authentication />}>
              <Route index element={<Registration />} />
              <Route path="register" element={<Registration />} />
              <Route path="login" element={<Login />} />
              <Route path="password-reset" element={<PasswordReset />} />
            </Route>

            <Route path="shared/:sharingLink" element={<Shared />} />

            <Route path="app" element={<App />}>
              <Route index element={<Recipes />} />
              <Route path="recipes" element={<Recipes />}>
                <Route path=":recipeId" element={<Preview />} />
                <Route path=":recipeId/edit" element={<EditorContainer />} />
                <Route path=":recipeId/share" element={<ShareRecipeModal />} />
                <Route path=":recipeId/add-to-calendar" element={<AddToCalendarModal />} />
                <Route path="new" element={<EditorContainer />} />
                <Route path="new-web" element={<NewWebRecipeModal />} />
                <Route path="upgrade" element={<RecipeLimitModal />} />
              </Route>

              <Route path="cooking-mode/:recipeId" element={<Player />} />

              <Route path="calendar" element={<Calendar />}>
                <Route path=":recipeId" element={<Preview />} />
                <Route path=":recipeId/edit" element={<EditorContainer />} />
                <Route path=":recipeId/share" element={<ShareRecipeModal />} />
                <Route path="new" element={<EditorContainer />} />
                <Route path="new-web" element={<NewWebRecipeModal />} />
                <Route path="upgrade" element={<RecipeLimitModal />} />
              </Route>

              <Route path="groceries" element={<Groceries />}>
                <Route path=":recipeId" element={<Preview />} />
                <Route path=":recipeId/edit" element={<EditorContainer />} />
                <Route path=":recipeId/share" element={<ShareRecipeModal />} />
                <Route path="new" element={<EditorContainer />} />
                <Route path="new-web" element={<NewWebRecipeModal />} />
                <Route path="upgrade" element={<RecipeLimitModal />} />
              </Route>

              <Route path="settings" element={<Settings />}>
                <Route index element={<Account />} />
                <Route path="account" element={<Account />} />
                <Route path="preferences" element={<Preferences />} />
                <Route path="tags" element={<TagsContainer />} />
              </Route>

              <Route path="feedback" element={<Contact />} />
            </Route>

            <Route path="*" element={<Page404 />} />
          </Routes>
        </TimerProvider>
      </BrowserRouter>
    </ToastProvider>
  </AuthProvider>
  // </React.StrictMode>
);
