import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Icon from "commons/components/Icon";
import Typography from "commons/components/Typography";
import AddButton from "commons/components/AddButton";
import ColorfulTagGroupSVG from "commons/images/colorfulTagGroup.svg";
import Popover from "commons/components/Popover";
import ActionList from "commons/components/ActionList";
import { Column, Row } from "commons/components/Flexbox/Flexbox";
import TagGroupEditPopoverContent from "./TagGroupEditPopoverContent";
import TagRow from "./TagRow";

import { DndContext, closestCenter, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useTranslation } from "react-i18next";

const Square = styled.div`
  width: 16px;
  height: 16px;
  border-radius: var(--border-radius-1);
  flex-shrink: 0;
  background-color: ${({ color }) => color && `var(--${color}-100)`};
`;

const GroupLabel = styled(Typography)`
  font-weight: 700;
`;

function TagGroup({
  group,
  editedTagGroupId,
  setEditedTagGroupId,
  updateTagGroupLabel,
  updateTagGroupColor,
  deleteTagGroup,
  reorderTags,
  editedTagId,
  setEditedTagId,
  addTag,
  updateTag,
  deleteTag,
  getGroupColor,
}) {
  const { t } = useTranslation();
  const groupColor = getGroupColor(group);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 4,
      },
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = group.tags.findIndex(el => el.id === active.id);
      const newIndex = group.tags.findIndex(el => el.id === over.id);
      reorderTags(group.id, arrayMove(group.tags, oldIndex, newIndex));
    }
  }

  return (
    <Column padding={8} gap={8} isBordered>
      <Row justifyContent="space-between" padding={8} paddingRight={0}>
        <Popover
          trigger={
            <Flexbox gap={8} alignItems="center" onClick={() => setTimeout(() => setEditedTagGroupId(group.id), 0)}>
              {groupColor ? <Square color={groupColor} /> : <img src={ColorfulTagGroupSVG} alt="" />}
              <GroupLabel variant="body">{group.label}</GroupLabel>
            </Flexbox>
          }
          content={closePopover => (
            <TagGroupEditPopoverContent
              label={group.label}
              color={groupColor}
              updateTagGroupLabel={label => updateTagGroupLabel(group.id, label)}
              updateTagGroupColor={color => updateTagGroupColor(group.id, color)}
              closePopover={closePopover}
            />
          )}
          placement="bottom-start"
          onClose={() => setEditedTagGroupId(null)}
          isTriggerTabbable
        />
        <Popover
          trigger={<Icon name="more_vert" size={20} />}
          content={closePopover =>
            editedTagGroupId === group.id ? (
              <TagGroupEditPopoverContent
                label={group.label}
                color={groupColor}
                updateTagGroupLabel={label => updateTagGroupLabel(group.id, label)}
                updateTagGroupColor={color => updateTagGroupColor(group.id, color)}
                closePopover={closePopover}
              />
            ) : (
              <ActionList
                actions={[
                  {
                    icon: "edit",
                    label: t("Settings.EditTagGroup"),
                    onClick: () => {
                      setTimeout(() => setEditedTagGroupId(group.id), 0);
                    },
                  },
                  {
                    variant: "divider",
                  },
                  {
                    icon: "delete",
                    label: t("Settings.DeleteTagGroup"),
                    variant: "destructive",
                    onClick: () => deleteTagGroup(group.id),
                  },
                ]}
              />
            )
          }
          placement="bottom-end"
          onClose={() => setEditedTagGroupId(null)}
          isTriggerTabbable
        />
      </Row>
      <Column padding="8px 6px" gap={4} alignItems="stretch">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext items={group.tags} strategy={verticalListSortingStrategy}>
            {group.tags.map(tag => (
              <TagRow
                key={tag.id}
                id={tag.id}
                icon={tag.icon}
                label={tag.label}
                color={tag.color}
                count={tag.count}
                editedTagId={editedTagId}
                groupId={group.id}
                updateTag={updateTag}
                setEditedTagId={setEditedTagId}
                deleteTag={deleteTag}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Column>
      <AddButton onClick={() => addTag(group.id)} fullWidth>
        Add tag
      </AddButton>
    </Column>
  );
}

TagGroup.propTypes = {
  className: T.string,
  group: T.object,
  editedTagGroupId: T.number,
  setEditedTagGroupId: T.func,
  updateTagGroupLabel: T.func,
  updateTagGroupColor: T.func,
  deleteTagGroup: T.func,
  reorderTags: T.func,
  editedTagId: T.number,
  setEditedTagId: T.func,
  addTag: T.func,
  updateTag: T.func,
  deleteTag: T.func,
  getGroupColor: T.func,
};

export default TagGroup;
