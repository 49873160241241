import React from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Icon from "commons/components/Icon";

const Box = styled(Flexbox)`
  ${({ variant }) =>
    variant === "vertical" &&
    css`
      /* align-items: center; */
    `}
`;

const Label = styled(Typography)`
  ${({ detailVariant }) =>
    detailVariant === "horizontal" &&
    css`
      width: 140px;
    `}

  display: flex;
  gap: 8px;
  align-items: center;
`;

function Detail({ className, label, value, variant = "horizontal", icon }) {
  return (
    <Box
      className={className}
      flexDirection={variant === "horizontal" ? "row" : "column"}
      gap={variant === "horizontal" ? 0 : 4}
      variant={variant}
    >
      <Label color="neutral-140" variant="body" detailVariant={variant}>
        {icon && <Icon name={icon} />}
        {label}
      </Label>
      <Typography variant="body" as="div">
        {value}
      </Typography>
    </Box>
  );
}

Detail.propTypes = {
  className: T.string,
  label: T.string,
  value: T.oneOfType([T.object, T.string, T.node]),
  variant: T.oneOf(["horizontal", "vertical"]),
  icon: T.string,
};

export default Detail;
