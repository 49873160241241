import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import TextInput from "commons/components/TextInput";
import { BREAKPOINTS } from "commons/util/breakpoints";
import ClampedTextWithTooltip from "commons/components/ClampedTextWithTooltip";
import ErrorBlock from "commons/components/ErrorBlock";
import { useToast } from "commons/util/useToast";
import { useAuth } from "App/AuthContext";
import { useTranslation } from "react-i18next";

const FlexboxStyled = styled(Flexbox)`
  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const ErrorBlockStyled = styled(ErrorBlock)`
  align-self: flex-start;
`;

const StringValue = styled(Typography)`
  line-height: 40px;
  max-width: 100%;
`;

function StringSettingContent({ value: initName }) {
  const { t } = useTranslation();
  const [isInputActive, setIsInputActive] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const { showToast } = useToast();
  const { setUserName } = useAuth();

  const [name, setName] = useState(initName);
  const [nameError, setNameError] = useState("");

  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!isInputActive) setName(initName);
  }, [isInputActive]);

  useEffect(() => {
    if (nameError) setNameError("");
  }, [name]);

  function validateName() {
    if (!name.length) {
      setNameError(t("Authentication.EmptyName"));
      return false;
    }
    return true;
  }

  async function handleChangeName() {
    setGeneralError("");
    const isNameValid = validateName(name);

    if (isNameValid) {
      setIsProcessing(true);
      try {
        await setUserName(name);
        setIsInputActive(false);
        showToast(t("Settings.NameChangeSuccess"));
      } catch (e) {
        setGeneralError(e.message);
      } finally {
        setIsProcessing(false);
      }
    }
  }

  return (
    <FlexboxStyled justifyContent="space-between" alignItems="center" gap={16}>
      {isInputActive ? (
        <>
          <ErrorBlockStyled>{generalError}</ErrorBlockStyled>
          <TextInput
            value={name}
            onChange={setName}
            autoFocus
            onConfirm={handleChangeName}
            errorMessage={nameError}
            onBlur={validateName}
            maxLength={40}
          />
          <Flexbox gap={8}>
            <Button variant="tertiary" onClick={() => setIsInputActive(false)}>
              {t("Common.Cancel")}
            </Button>
            <Button variant="primary" onClick={handleChangeName} loading={isProcessing}>
              {t("Common.Save")}
            </Button>
          </Flexbox>
        </>
      ) : (
        <>
          <StringValue variant="body" as="div">
            <ClampedTextWithTooltip>{name}</ClampedTextWithTooltip>
          </StringValue>
          <Button variant="secondary" onClick={() => setIsInputActive(true)}>
            {t("Common.Change")}
          </Button>
        </>
      )}
    </FlexboxStyled>
  );
}

StringSettingContent.propTypes = {
  value: T.string,
  onChange: T.func,
  validate: T.func,
};

export default StringSettingContent;
