import React from "react";
import T from "prop-types";
import { isToday, format, isSameDay, isPast, isSameMonth } from "date-fns";
import styled, { css } from "styled-components/macro";
import { MEAL_COLORS, MEAL_TYPES, TIME_FRAMES } from "commons/util/constants";
import Flexbox from "commons/components/Flexbox";

const DayNumber = styled.span`
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: 500;
  color: var(--neutral-120);
  z-index: var(--z-index-0);

  &:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: -7px;
    left: calc(50% - 15px);
    border-radius: var(--border-radius-1);
    z-index: var(--z-index-below);
  }

  ${({ isPast, isSameMonth }) =>
    (isPast || !isSameMonth) &&
    css`
      color: var(--neutral-160);
    `}

  ${({ isToday }) =>
    isToday &&
    css`
      color: var(--primary-100);

      &:before {
        background-color: var(--primary-190);
      }
    `}

  ${({ isSelectedDate }) =>
    isSelectedDate &&
    css`
      color: var(--neutral-200);

      &:before {
        background-color: var(--primary-100) !important;
      }
    `}
`;

const Box = styled(Flexbox)`
  width: calc(100% / 7);
  height: 42px;
  padding: 8px;
  cursor: pointer;
  color: var(--neutral-120);
  border-radius: var(--border-radius-0);

  ${({ timeFrame }) =>
    timeFrame === TIME_FRAMES.day &&
    css`
      width: 100%;
    `}

  &:hover {
    ${DayNumber} {
      &:before {
        background-color: var(--neutral-190);
      }
    }
  }
`;

const MealDotWrap = styled(Flexbox)`
  background-color: var(--neutral-200);
  padding: 2px;
  z-index: var(--z-index-0);
  width: fit-content;
`;

const MealDot = styled.div`
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ color }) => `var(--${color}-100)`};
  flex-shrink: 0;
  flex-grow: 0;
`;

const DatePickerDay = ({ date, shownDate, selectedDate, timeFrame, onClick, dayMealTypes }) => {
  return (
    <Box
      tabIndex="0"
      className={`planner-day day-${format(date, "d")}`}
      flexDirection="column"
      alignItems="center"
      timeFrame={timeFrame}
      onClick={() => onClick(date)}
    >
      <DayNumber
        isToday={isToday(date)}
        isSelectedDate={isSameDay(date, selectedDate)}
        isPast={isPast(date) && !isToday(date)}
        isSameMonth={isSameMonth(date, shownDate)}
      >
        {format(date, "d")}
      </DayNumber>
      {!!dayMealTypes.length && (
        <MealDotWrap marginTop={3} gap={2} justifyContent="center">
          {dayMealTypes.map(mealType => (
            <MealDot key={mealType} color={MEAL_COLORS[mealType]} />
          ))}
        </MealDotWrap>
      )}
    </Box>
  );
};

DatePickerDay.propTypes = {
  date: T.instanceOf(Date).isRequired,
  shownDate: T.instanceOf(Date).isRequired,
  selectedDate: T.instanceOf(Date),
  timeFrame: T.string.isRequired,
  onClick: T.func.isRequired,
  dayMealTypes: T.arrayOf(T.oneOf(Object.values(MEAL_TYPES))),
};

export default DatePickerDay;
