import React from "react";
import T from "prop-types";
import ActionList from "commons/components/ActionList";
import Button from "commons/components/Button";
import Flexbox from "commons/components/Flexbox";
import Popover from "commons/components/Popover";
import Typography from "commons/components/Typography";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { TIME_FRAMES } from "commons/util/constants";
import Icon from "commons/components/Icon";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from "date-fns";
import { useCurrentLocale } from "commons/util/useCurrentLocale";
import OptionList from "commons/components/OptionList";

const Box = styled.div`
  position: sticky;
  top: 0;
  background-color: var(--neutral-200);
  z-index: var(--z-index-above);
  padding-top: 24px;
  // hiding the border from rows below
  padding-left: 1px;
  margin-left: -1px;
`;

const PeriodLabel = styled(Typography)`
  text-transform: capitalize;
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: ${BREAKPOINTS.medium}) {
    top: 28px;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    top: 72px;
    width: 100%;
    text-align: center;
  }
`;

const WeekdaysWrap = styled(Flexbox)`
  background-color: var(--neutral-200);
  padding-bottom: 16px;
  border-radius: var(--border-radius-0);
  border-bottom: 1px solid var(--neutral-180);

  @media (max-width: ${BREAKPOINTS.small}) {
    padding-bottom: 8px;
    border-bottom: none;
  }
`;

const Weekday = styled.span`
  font-size: 14px;
  line-height: 16px;
  width: calc(100% / 7);
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
`;

const DropdownButton = styled(Button)`
  /* typical dropdown button styling */
  div {
    display: flex;
    padding-right: 0;
    gap: 2px;
  }
`;

const firstDayOfTheWeek = 1; // Monday - To do - user preference

function PlannerHeader({ timeFrame, setTimeFrame, goToday, goPrevPeriod, goNextPeriod, clearTimeFrame, periodLabel }) {
  const { t } = useTranslation();
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);
  const currentLocale = useCurrentLocale();

  const weekdays = eachDayOfInterval({
    start: startOfWeek(new Date(), {
      weekStartsOn: firstDayOfTheWeek,
    }),
    end: endOfWeek(new Date(), {
      weekStartsOn: firstDayOfTheWeek,
    }),
  }).map(day => format(day, isScreenMaxSmall ? "EEEEE" : isScreenMaxMedium ? "E" : "EEEE", { locale: currentLocale }));

  return (
    <Box>
      <Flexbox justifyContent="space-between" paddingBottom={isScreenMaxMedium ? 24 : 48} gap={8}>
        <Flexbox gap={4}>
          <Button variant="tertiary" icon="chevron_left" onClick={goPrevPeriod} />
          <Button variant="secondary" onClick={goToday}>
            {t("Calendar.Today")}
          </Button>
          <Button variant="tertiary" icon="chevron_right" onClick={goNextPeriod} />
        </Flexbox>

        {!isScreenMaxSmall && !(isScreenMaxMedium && timeFrame === TIME_FRAMES.day) && (
          <PeriodLabel variant={isScreenMaxMedium ? "h4" : "h3"}>{periodLabel}</PeriodLabel>
        )}

        <Flexbox gap={4}>
          <Popover
            trigger={
              <DropdownButton variant="tertiary">
                {t(`Calendar.${timeFrame}`)} <Icon name="arrow_drop_down" size={16} />
              </DropdownButton>
            }
            content={closePopover => (
              <OptionList
                selectedOptionId={timeFrame}
                onChange={setTimeFrame}
                closePopover={closePopover}
                options={[
                  {
                    id: TIME_FRAMES.month,
                    label: t("Calendar.Month"),
                  },
                  {
                    id: TIME_FRAMES.week,
                    label: t("Calendar.Week"),
                  },
                  {
                    id: TIME_FRAMES.day,
                    label: t("Calendar.Day"),
                  },
                ]}
              />
            )}
          />
          <Popover
            trigger={<Button variant="tertiary" icon="more_vert" />}
            content={closePopover => (
              <ActionList
                actions={[
                  {
                    label: t(`Calendar.Clear${timeFrame}`),
                    onClick: () => {
                      clearTimeFrame();
                      closePopover();
                    },
                  },
                ]}
              />
            )}
          />
        </Flexbox>
      </Flexbox>

      {!isScreenMaxSmall && timeFrame !== TIME_FRAMES.day && (
        <WeekdaysWrap alignItems="center">
          {weekdays.map((day, index) => (
            <Weekday key={index}>{day}</Weekday>
          ))}
        </WeekdaysWrap>
      )}
    </Box>
  );
}

PlannerHeader.propTypes = {
  timeFrame: T.string,
  setTimeFrame: T.func,
  goToday: T.func,
  goPrevPeriod: T.func,
  goNextPeriod: T.func,
  clearTimeFrame: T.func,
  periodLabel: T.string,
};

export default PlannerHeader;
