import React, { useState } from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import TextInput from "commons/components/TextInput";
import Icon from "commons/components/Icon";
import Flexbox from "commons/components/Flexbox";
import { useUpdateEffect } from "commons/util/useUpdateEffect";
import { TAG_COLOR_LIST } from "commons/util/constants";

const ColorTile = styled.div`
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-1);
  background-color: ${({ color }) => color && `var(--${color}-100)`};
  cursor: pointer;

  ${({ isActive }) => isActive && css``}
`;

function TagGroupEditPopoverContent({
  className,
  label: initLabel,
  color: initColor,
  updateTagGroupLabel,
  updateTagGroupColor,
  closePopover,
}) {
  const [label, setLabel] = useState(initLabel);
  const [color, setColor] = useState(initColor);

  useUpdateEffect(() => {
    updateTagGroupLabel(label);
  }, [label]);

  useUpdateEffect(() => {
    if (color) updateTagGroupColor(color);
  }, [color]);

  return (
    <Flexbox className={className} gap={24} flexDirection="column" padding={24}>
      <TextInput value={label} onChange={setLabel} onConfirm={closePopover} autoFocus maxLength={48} />
      <Flexbox gap={8} flexWrap="wrap">
        {TAG_COLOR_LIST.map(presetColor => (
          <ColorTile
            key={presetColor}
            color={presetColor}
            isActive={presetColor === color}
            onClick={() => setColor(presetColor)}
          >
            {presetColor === color && <Icon name="check" color="neutral-200" size={20} />}
          </ColorTile>
        ))}
      </Flexbox>
    </Flexbox>
  );
}

TagGroupEditPopoverContent.propTypes = {
  className: T.string,
  label: T.string,
  color: T.string,
  updateTagGroupLabel: T.func,
  updateTagGroupColor: T.func,
  closePopover: T.func,
};

export default TagGroupEditPopoverContent;
