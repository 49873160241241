import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import NumberStepper from "commons/components/NumberStepper";
import Ingredient from "./Ingredient";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { IngredientsBox } from "App/Recipes/components/Recipes.styles";
import Tooltip from "commons/components/Tooltip";
import { useTranslation } from "react-i18next";

const IngredientsBoxStyled = styled(IngredientsBox)`
  width: 350px;
  margin-top: -24px;
  min-height: 200px;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }
`;

const Category = styled(Typography)`
  padding: 12px 8px 0;
`;

function Ingredients({ className, ingredients, servings: defaultServings = 1 }) {
  const { t } = useTranslation();
  const [servings, setServings] = useState(defaultServings);

  useEffect(() => {
    setServings(defaultServings);
  }, [defaultServings]);

  return (
    <IngredientsBoxStyled className={className}>
      <Flexbox justifyContent="space-between" alignItems="center" gap={8} flexWrap="wrap" marginX={8} marginBottom={16}>
        <Typography variant="h3">{t("Recipes.Ingredients")}</Typography>
        <Tooltip label={t("Recipes.Servings")}>
          <NumberStepper value={servings} onChange={setServings} />
        </Tooltip>
      </Flexbox>
      <Flexbox flexDirection="column" gap={8}>
        {ingredients.map((ingredient, i) => {
          if (ingredient.isCategory) {
            return (
              <Category key={i} variant="h4">
                {ingredient.label}
              </Category>
            );
          } else {
            const displayedAmount = +(ingredient.amount * (servings / defaultServings)).toFixed(2);
            return (
              <Ingredient
                key={i}
                amount={displayedAmount}
                unit={ingredient.unit}
                label={ingredient.label}
                note={ingredient.note}
              />
            );
          }
        })}
      </Flexbox>
    </IngredientsBoxStyled>
  );
}

Ingredients.propTypes = {
  className: T.string,
  ingredients: T.array.isRequired,
  servings: T.number,
};

export default Ingredients;
