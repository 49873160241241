import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import TextInput from "commons/components/TextInput";
import Button from "commons/components/Button";
import { useTranslation } from "react-i18next";
import { useMealPlan, useMealPlanDispatch } from "App/MealPlanContext";

const Box = styled(Flexbox)`
  width: 350px;
  z-index: var(--z-index-above);
  background-color: var(--neutral-200);
  box-shadow: var(--shadow-3);
  border-radius: var(--border-radius-2);
  margin-top: 4px;
`;

const Actions = styled(Flexbox)`
  width: 100%;
`;

function EditItemPopoverContent({ className, item, handleClose }) {
  const { t } = useTranslation();
  const { label: labelInit, id, isProduct } = item;
  const [label, setLabel] = useState(labelInit);
  const [labelError, setLabelError] = useState("");
  const { mealPlan } = useMealPlan();
  const dispatch = useMealPlanDispatch();

  useEffect(() => {
    if (labelError) setLabelError("");
  }, [label]);

  function addNewItem() {
    if (label) {
      const newMealPlan = mealPlan.map(m => {
        if (m.id === id) {
          return { ...m, label };
        }
        return m;
      });
      dispatch({ type: "UPDATE", mealPlan: newMealPlan });
      setLabel("");
      handleClose();
    } else {
      setLabelError(isProduct ? t("Calendar.ProductEmptyError") : t("Calendar.NoteEmptyError"));
    }
  }

  return (
    <Box className={className} flexDirection="column" alignItems="flex-start" padding={16} gap={16}>
      <Typography variant="h4">{isProduct ? t("Calendar.EditProduct") : t("Calendar.EditNote")}</Typography>
      <TextInput
        placeholder={isProduct ? t("Calendar.ProductPlaceholder") : t("Calendar.NotePlaceholder")}
        value={label}
        errorMessage={labelError}
        onChange={setLabel}
        onConfirm={addNewItem}
        autoFocus
        maxLength={100}
      />
      <Actions justifyContent="flex-end" gap={8} marginTop={12}>
        <Button onClick={handleClose} variant="tertiary">
          {t("Common.Cancel")}
        </Button>
        <Button onClick={addNewItem}>{t("Common.Save")}</Button>
      </Actions>
    </Box>
  );
}

EditItemPopoverContent.propTypes = {
  className: T.string,
  item: T.object.isRequired,
  handleClose: T.func.isRequired,
};

export default EditItemPopoverContent;
