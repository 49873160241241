import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import T from "prop-types";
import Tag from "commons/components/Tag";
import Image from "commons/components/Image";
import Flexbox from "commons/components/Flexbox";
import { useNavigate } from "react-router-dom";
import Typography from "commons/components/Typography";
import ClampedTextWithTooltip from "commons/components/ClampedTextWithTooltip";
import { useDraggable } from "@dnd-kit/core";
import { useLocationBase } from "commons/util/useLocationBase";
import DragHandle from "commons/components/DragHandle";

const Box = styled.div`
  position: relative;
  display: flex;
  border: 1px solid var(--neutral-180);
  border-radius: var(--border-radius-2);
  padding: 8px;
  background-color: var(--neutral-200);
  cursor: pointer;
  transition: box-shadow 0.1s;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: default;
      `;
    } else {
      return css`
        &:hover,
        &:focus-visible {
          box-shadow: var(--shadow-2);
        }
      `;
    }
  }}
`;

const Title = styled(Typography)`
  line-height: 18px;
`;

const RecipeCardImage = styled(Image)`
  background-color: var(--neutral-190);
  border-radius: var(--border-radius-2);
`;

const TopRightIcons = styled(Flexbox)`
  position: absolute;
  top: 4px;
  right: 4px;
`;

const DragHandleStyled = styled(DragHandle)``;

const CornerIconWrap = styled.div`
  padding: 4px;
`;

// const NeverCookedIcon = styled(Icon)`
//   padding: 4px;
//   background-color: var(--neutral-200);
//   border-radius: var(--border-radius-1);
//   color: var(--neutral-120);
//   box-shadow: var(--shadow-2);
//   cursor: default;
// `;

// const FavoriteIcon = styled(Icon)`
//   padding: 2px;
//   background-color: var(--neutral-200);
//   border-radius: 50%;
//   box-shadow: var(--shadow-2);
//   cursor: default;

//   position: absolute;
//   top: 12px;
//   left: 44px;
// `;

const ExtraTag = styled(Tag)`
  width: 20px;
  height: 20px;
  padding: 0;
`;

function RecipeCard({ className, recipe, CornerIcon, isDraggable }) {
  const [width, setWidth] = useState(0);
  const locationBase = useLocationBase();
  const boxRef = useRef(null);
  const navigate = useNavigate();

  const maxNumberOfTags = useMemo(() => {
    return Math.floor((width + 4) / 24);
  }, [width]);

  useLayoutEffect(() => {
    setWidth(boxRef.current.offsetWidth);
  }, []);

  const { id: recipeId, title, tags = [], imageURL } = recipe;

  const { attributes, listeners, setNodeRef, setActivatorNodeRef } = useDraggable({
    id: recipeId,
  });

  const link = `${locationBase}/${recipe.id}`;

  return (
    <Box
      className={className}
      tabIndex="0"
      onClick={e => {
        e.stopPropagation();
        navigate(link);
      }}
      ref={isDraggable ? setNodeRef : null}
      {...(isDraggable ? attributes : {})}
    >
      <RecipeCardImage src={imageURL} height="64px" width="64px" alt={title} isRemote />
      <TopRightIcons>
        {isDraggable && <DragHandleStyled {...listeners} ref={setActivatorNodeRef} />}
        {CornerIcon && (
          <CornerIconWrap>
            <CornerIcon />
          </CornerIconWrap>
        )}
      </TopRightIcons>
      {/* {servings && (
        <Tooltip label="Servings" triggerStyles={{ position: "absolute", bottom: "8px", right: "8px" }}>
          <NumberStepper
            size="small"
            value={servings}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={value => setServings(recipeId, value)}
          />
        </Tooltip>
      )} */}
      {/* {neverCooked && (
        <Tooltip label="Never cooked before" triggerStyles={{ position: "absolute", top: "48px", right: "8px" }}>
          <NeverCookedIcon name="local_dining" onClick={e => e.preventDefault()} />
        </Tooltip>
      )} */}
      {/* {isFavorite && <FavoriteIcon size={17} name="favorite" color="red-100" isFilled />} */}
      <Flexbox flexDirection="column" marginLeft={8} paddingRight={0}>
        <Title variant="label" marginRight={28}>
          <ClampedTextWithTooltip maxLines={tags.length ? 2 : 3}>{title}</ClampedTextWithTooltip>
        </Title>
        <Flexbox alignItems="flex-end" flexWrap="wrap" gap={4} marginTop="auto" ref={boxRef}>
          {tags.slice(0, tags.length > maxNumberOfTags ? maxNumberOfTags - 1 : maxNumberOfTags).map(tag => {
            return <Tag key={tag.id} icon={tag.icon} label={tag.label} color={tag.color} isCompact />;
          })}
          {tags.length > maxNumberOfTags && (
            <ExtraTag label={`+${tags.length - maxNumberOfTags}`} color="grey" isCompact />
          )}
        </Flexbox>
      </Flexbox>
    </Box>
  );
}

RecipeCard.propTypes = {
  className: T.string,
  recipe: T.object,
  CornerIcon: T.oneOfType([T.func, T.object]),
  onRecipeCardClick: T.func,
  isDraggable: T.bool,
};

export default RecipeCard;
