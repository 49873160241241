import React from "react";
import T from "prop-types";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import SanitizedHtml from "commons/components/SanitizedHtml";
import { Marker, Step } from "App/Recipes/components/Recipes.styles";
import { useTranslation } from "react-i18next";

function Steps({ className, steps }) {
  const { t } = useTranslation();
  return (
    <Flexbox className={className} gap={24} flexDirection="column" flexGrow={1}>
      <Flexbox alignItems="center" justifyContent="space-between">
        <Typography variant="h3">{t("Recipes.Directions")}</Typography>
      </Flexbox>
      {steps.map(step => (
        <Step key={step.number} alignItems="flex-start" gap={16}>
          <Marker>{step.number}.</Marker>
          <Typography variant="paragraph" as="div">
            <SanitizedHtml>{step.text}</SanitizedHtml>
          </Typography>
        </Step>
      ))}
    </Flexbox>
  );
}

Steps.propTypes = {
  className: T.string,
  steps: T.arrayOf(T.object),
};

export default Steps;
