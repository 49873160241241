import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import TextInput from "commons/components/TextInput";
import Modal from "commons/components/Modal";
import { useNavigate } from "react-router-dom";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import Flexbox from "commons/components/Flexbox";
import Divider from "commons/components/Divider";
import { useToast } from "commons/util/useToast";
import { useRecipeListDispatch } from "App/RecipeListContext";
import callApi from "commons/util/callApi";
import { mimeMap } from "commons/util/helpers";
import { usePreferences } from "App/PreferencesContext";
import { UNIT_SYSTEMS } from "App/Settings/Preferences/Preferences";
import { useLocationBase } from "commons/util/useLocationBase";
import { useTranslation } from "react-i18next";

const ModalStyled = styled(Modal)`
  max-width: 100%;
  width: 640px;
  height: auto;
`;

// const InfoBox = styled(Flexbox)`
//   background-color: var(--orange-190);
// `;

function NewWebRecipeModal({ className }) {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const locationBase = useLocationBase();
  const { showToast } = useToast();
  const dispatch = useRecipeListDispatch();
  const { preferences } = usePreferences();

  useEffect(() => {
    setUrlErrorMessage("");
  }, [url]);

  function closeModal() {
    navigate(locationBase);
  }

  async function saveAndOpenRecipe(recipe) {
    try {
      const res = await callApi("recipes", "post", recipe);
      dispatch({ type: "CREATE", recipe: { ...recipe, id: res.id } });
      showToast(t("Recipes.RecipeImported"));
      navigate(`${locationBase}/${res.id}`);
    } catch (e) {
      showToast(t("Common.GenericError"), "error");
    }
  }

  async function uploadImageAndGetUrl(originalImageUrl, imageType) {
    if (mimeMap[imageType]) {
      try {
        const { imageUrl } = await callApi(`images?url=${encodeURIComponent(originalImageUrl)}`, "post", null, {
          "content-type": mimeMap[imageType],
        });
        return imageUrl;
      } catch {
        return "";
      }
    }
    return "";
  }

  function getSteps(analyzedInstructions) {
    if (analyzedInstructions.length === 1) {
      return (
        analyzedInstructions[0]?.steps.map(({ number, step }) => ({
          id: number,
          number,
          text: step,
        })) || []
      );
    } else {
      const flattenedSteps = analyzedInstructions.flatMap(({ steps }) => steps.map(step => step));
      return flattenedSteps.map((step, index) => ({
        id: index + 1,
        number: index + 1,
        text: step.step,
      }));
    }
  }

  async function extractRecipeFromWebsite() {
    try {
      setIsProcessing(true);
      const res = await callApi(`spoonacular/recipes/extract?url=${encodeURIComponent(url)}`, "get");

      const {
        title,
        sourceUrl,
        image,
        imageType,
        servings,
        preparationMinutes,
        readyInMinutes,
        analyzedInstructions,
        extendedIngredients,
      } = res;

      if (res.analyzedInstructions.length === 0) {
        throw new Error("Access denied");
      }

      const imageUrl = await uploadImageAndGetUrl(image, imageType);

      const newRecipe = {
        title,
        source: sourceUrl,
        imageURL: imageUrl,
        servings: servings === -1 ? 1 : servings,
        isFavorite: false,
        neverCooked: true,
        activeTime: preparationMinutes === -1 ? 0 : preparationMinutes,
        totalTime: readyInMinutes === -1 ? 0 : readyInMinutes,
        dateCreated: Date.now(),
        dateLastEdited: Date.now(),
        steps: getSteps(analyzedInstructions),
        ingredients: extendedIngredients.map(({ amount, unit, measures, name, meta }, id) => ({
          id: id + 1, // dnd-kit identifiers must be bigger than 0
          amount:
            preferences.unitSystem === UNIT_SYSTEMS.none
              ? amount
              : preferences.unitSystem === UNIT_SYSTEMS.metric
              ? measures.metric.amount
              : measures.us.amount,
          unit:
            preferences.unitSystem === UNIT_SYSTEMS.none
              ? unit
              : preferences.unitSystem === UNIT_SYSTEMS.metric
              ? measures.metric.unitShort
              : measures.us.unitShort,
          label: name,
          note: meta.filter(el => el !== "()").join(", "),
        })),
        notes: "",
        tagIds: [],
      };

      saveAndOpenRecipe(newRecipe);
    } catch (e) {
      if (e.response?.data.message === "URL invalid") {
        setUrlErrorMessage(t("Recipes.RecipeUrlInvalid"));
      } else if (e.message === "Access denied") {
        setUrlErrorMessage(t("Recipes.AccessDeniedError"));
      } else {
        showToast(t("Common.GenericError"), "error");
      }
      setIsProcessing(false);
    }
  }

  function validateUrlAndExtract() {
    if (!url) {
      setUrlErrorMessage(t("Recipes.RecipeUrlRequired"));
      return false;
    } else {
      extractRecipeFromWebsite();
    }
  }

  return (
    <ModalStyled className={className} handleClose={closeModal}>
      <Flexbox flexDirection="column" gap={24} padding={24} paddingBottom={40}>
        <Typography variant="h3">{t("Recipes.ImportRecipeFromWeb")}</Typography>
        <TextInput
          value={url}
          onChange={setUrl}
          label={t("Recipes.RecipeUrl")}
          placeholder="https://"
          errorMessage={urlErrorMessage}
          onConfirm={validateUrlAndExtract}
          autoFocus
          maxLength={800}
        />
        {/* <InfoBox gap={8} padding={8}>
          <Icon name="warning" color="orange-100" size={18} />
          <Typography variant="label" color="orange-100">
            Only import content you have permission to use. You are responsible for any content you import.
          </Typography>
        </InfoBox> */}
      </Flexbox>
      <Divider />
      <Flexbox justifyContent="flex-end" padding="16px 24px" gap={8}>
        <Button onClick={closeModal} variant="tertiary">
          {t("Common.Cancel")}
        </Button>
        <Button onClick={validateUrlAndExtract} loading={isProcessing}>
          {t("Recipes.ImportRecipe")}
        </Button>
      </Flexbox>
    </ModalStyled>
  );
}

NewWebRecipeModal.propTypes = {
  className: T.string,
};

export default NewWebRecipeModal;
