import React, { useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Button from "commons/components/Button";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import { getDatesInTimeFrame } from "commons/util/helpers";
import { addDays, addMonths, addWeeks, format, isSameDay, subDays, subMonths, subWeeks } from "date-fns";
import { MEAL_TYPES, TIME_FRAMES } from "commons/util/constants";
import { useCurrentLocale } from "commons/util/useCurrentLocale";
import { useMealPlan } from "App/MealPlanContext";
import _ from "lodash";
import { useUpdateEffect } from "commons/util/useUpdateEffect";
import DatePickerDay from "./DatePickerDay";

const Box = styled(Flexbox)`
  width: 300px;
`;

const NavigationButton = styled(Button)`
  padding: 4px;
`;

const MonthLabel = styled(Typography)`
  text-transform: capitalize;
`;

const WeekdaysWrap = styled(Flexbox)`
  border-radius: var(--border-radius-0);
  border-bottom: 1px solid var(--neutral-180);
`;

const Weekday = styled.span`
  font-size: 14px;
  line-height: 16px;
  width: calc(100% / 7);
  font-weight: 500;
  text-align: center;
  color: var(--neutral-120);
`;

function DatePickerWithMeals({ className, timeFrame = TIME_FRAMES.month, selectedDate, onChange }) {
  const currentLocale = useCurrentLocale();
  const [shownDate, setShownDate] = useState(selectedDate || new Date());

  useUpdateEffect(() => {
    setShownDate(selectedDate);
  }, [selectedDate]);

  const { mealPlan } = useMealPlan();

  const goPrevPeriod = () => {
    if (timeFrame === TIME_FRAMES.month) {
      setShownDate(subMonths(shownDate, 1));
    } else if (timeFrame === TIME_FRAMES.week) {
      setShownDate(subWeeks(shownDate, 1));
    } else {
      setShownDate(subDays(shownDate, 1));
    }
  };
  const goNextPeriod = () => {
    if (timeFrame === TIME_FRAMES.month) {
      setShownDate(addMonths(shownDate, 1));
    } else if (timeFrame === TIME_FRAMES.week) {
      setShownDate(addWeeks(shownDate, 1));
    } else {
      setShownDate(addDays(shownDate, 1));
    }
  };

  const weekdays = getDatesInTimeFrame(TIME_FRAMES.week, new Date()).map(day =>
    format(day, "EEEEEE", { locale: currentLocale })
  );

  const datesInTimeFrame = getDatesInTimeFrame(timeFrame, shownDate);
  const datesInTimeFrameWithMealTypes = datesInTimeFrame.map(date => {
    const dayMeals = mealPlan?.filter(meal => isSameDay(date, meal.date));
    const dayMealTypes = _.uniq(dayMeals.map(meal => meal.mealType));

    const orderedDayMealTypes = [];
    Object.values(MEAL_TYPES).forEach(type => {
      if (dayMealTypes.includes(type)) {
        orderedDayMealTypes.push(type);
      }
    });

    return {
      date,
      dayMealTypes: orderedDayMealTypes,
    };
  });

  return (
    <Box className={className} flexDirection="column" paddingTop={8} isBordered>
      <Flexbox justifyContent="space-between" alignItems="center" paddingX={8}>
        <NavigationButton variant="tertiary" icon="chevron_left" onClick={goPrevPeriod} />
        <MonthLabel variant="h4">{format(shownDate, "LLLL y", { locale: currentLocale })}</MonthLabel>
        <NavigationButton variant="tertiary" icon="chevron_right" onClick={goNextPeriod} />
      </Flexbox>

      <WeekdaysWrap alignItems="center" paddingTop={20} paddingBottom={12}>
        {weekdays.map((day, index) => (
          <Weekday key={index}>{day}</Weekday>
        ))}
      </WeekdaysWrap>

      <Flexbox flexWrap="wrap" marginTop={5}>
        {datesInTimeFrameWithMealTypes.map(obj => (
          <DatePickerDay
            key={obj.date}
            date={obj.date}
            shownDate={shownDate}
            selectedDate={selectedDate}
            timeFrame={timeFrame}
            onClick={() => onChange(obj.date)}
            dayMealTypes={obj.dayMealTypes}
          />
        ))}
      </Flexbox>
    </Box>
  );
}

DatePickerWithMeals.propTypes = {
  className: T.string,
  timeFrame: T.string,
  selectedDate: T.instanceOf(Date),
  onChange: T.func,
};

export default DatePickerWithMeals;
