import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import TextInput from "commons/components/TextInput";
import { BREAKPOINTS } from "commons/util/breakpoints";
import ClampedTextWithTooltip from "commons/components/ClampedTextWithTooltip";
import ErrorBlock from "commons/components/ErrorBlock";
import { useToast } from "commons/util/useToast";
import { isEmailValid } from "commons/util/helpers";
import { initiateEmailUpdate, verifyAttributeChange } from "commons/util/auth";
import { useAuth } from "App/AuthContext";
import { CODE_LENGTH, MIN_PASSWORD_LENGTH } from "commons/util/constants";
import { Trans, useTranslation } from "react-i18next";

const MultiLineWrap = styled(Flexbox)`
  width: 100%;
`;

const FlexboxStyled = styled(Flexbox)`
  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const ErrorBlockStyled = styled(ErrorBlock)`
  align-self: flex-start;
`;

const StringValue = styled(Typography)`
  line-height: 40px;
  max-width: 100%;
`;

const PasswordInput = styled(TextInput)``;

const Email = styled.span`
  color: var(--primary-100);
  word-break: break-word;
`;

const VIEWS = {
  EMAIL: "EMAIL",
  CODE: "CODE",
};

function EmailSettingContent({ value: initEmail }) {
  const { t } = useTranslation();
  const [view, setView] = useState(VIEWS.EMAIL);
  const [isInputActive, setIsInputActive] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const { showToast } = useToast();
  const { setUserEmail } = useAuth();

  const [email, setEmail] = useState(initEmail);
  const [emailError, setEmailError] = useState("");

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!isInputActive) {
      setEmail(initEmail);
      setPassword("");
    }
  }, [isInputActive]);

  useEffect(() => {
    setGeneralError("");
  }, [view]);

  useEffect(() => {
    if (emailError) setEmailError("");
  }, [email]);

  useEffect(() => {
    if (passwordError) setPasswordError("");
  }, [password]);

  function validateEmail() {
    if (email === initEmail) {
      setEmailError(t("Authentication.EmailIdenticalError"));
      return false;
    }
    if (!isEmailValid(email)) {
      setEmailError(t("Authentication.InvalidEmail"));
      return false;
    }
    return true;
  }

  function validatePassword() {
    if (password.length < MIN_PASSWORD_LENGTH) {
      setPasswordError(t("Authentication.InvalidPassword", { length: MIN_PASSWORD_LENGTH }));
      return false;
    }
    return true;
  }

  async function handleInitiateEmailUpdate() {
    setGeneralError("");
    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (isEmailValid && isPasswordValid) {
      setIsProcessing(true);
      try {
        await initiateEmailUpdate(initEmail, email, password);
        setView(VIEWS.CODE);
      } catch (e) {
        setGeneralError(e.message);
      } finally {
        setIsProcessing(false);
      }
    }
  }

  // ==== new email confirmatioon ====

  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");

  function validateCode() {
    if (!code) {
      setCodeError(t("Authentication.EmptyCode"));
      return false;
    }
    if (code.length !== CODE_LENGTH) {
      setCodeError(t("Authentication.InvalidCode", { length: CODE_LENGTH }));
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (codeError) setCodeError("");
  }, [code]);

  async function handleConfirmNewEmail() {
    setGeneralError("");
    const isValidCode = validateCode();

    if (isValidCode) {
      setIsProcessing(true);
      try {
        await verifyAttributeChange("email", code);
        setUserEmail(email);
        setIsInputActive(false);
        setView(VIEWS.EMAIL);
        showToast(t("Authentication.EmailChangeSuccess"));
      } catch (e) {
        setGeneralError(e.message);
      } finally {
        setIsProcessing(false);
      }
    }
  }

  return (
    <FlexboxStyled justifyContent="space-between" alignItems="center" gap={16}>
      {isInputActive ? (
        <MultiLineWrap flexDirection="column" alignItems="flex-end" gap={16}>
          <ErrorBlockStyled>{generalError}</ErrorBlockStyled>
          {view === VIEWS.EMAIL && (
            <>
              <TextInput
                label={t("Authentication.NewEmail")}
                value={email}
                onChange={setEmail}
                autoFocus
                onConfirm={handleInitiateEmailUpdate}
                errorMessage={emailError}
                onBlur={validateEmail}
                maxLength={128}
              />
              <PasswordInput
                label={t("Common.Password")}
                type={isPasswordVisible ? "text" : "password"}
                value={password}
                onChange={setPassword}
                rightIcon={isPasswordVisible ? "visibility" : "visibility_off"}
                onRightIconClick={() => setIsPasswordVisible(isVisible => !isVisible)}
                errorMessage={passwordError}
                onBlur={validatePassword}
                maxLength={128}
              />
              <Flexbox gap={8} marginTop={12}>
                <Button variant="tertiary" onClick={() => setIsInputActive(false)}>
                  {t("Common.Cancel")}
                </Button>
                <Button variant="primary" onClick={handleInitiateEmailUpdate} loading={isProcessing}>
                  {t("Authentication.ChangeEmail")}
                </Button>
              </Flexbox>
            </>
          )}
          {view === VIEWS.CODE && (
            <>
              <Typography marginY={8} color="neutral-120">
                <Trans i18nKey="Authentication.CheckYourEmailCodeSent" values={{ length: CODE_LENGTH, email }}>
                  {`Please check your email. ${CODE_LENGTH}-digit code has been sent to `}
                  <Email>{email}</Email>
                </Trans>
              </Typography>
              <TextInput
                value={code}
                onChange={setCode}
                onConfirm={handleConfirmNewEmail}
                errorMessage={codeError}
                placeholder={t("Authentication.EnterCode")}
                pattern="\d*"
                autoFocus
                maxLength={10}
              />
              <Flexbox gap={8} marginTop={12}>
                <Button variant="tertiary" onClick={() => setView(VIEWS.EMAIL)}>
                  {t("Common.Back")}
                </Button>
                <Button onClick={handleConfirmNewEmail} loading={isProcessing}>
                  {t("Authentication.VerifyEmail")}
                </Button>
              </Flexbox>
            </>
          )}
        </MultiLineWrap>
      ) : (
        <>
          <StringValue variant="body" as="div">
            <ClampedTextWithTooltip>{initEmail}</ClampedTextWithTooltip>
          </StringValue>
          <Button variant="secondary" onClick={() => setIsInputActive(true)}>
            {t("Common.Change")}
          </Button>
        </>
      )}
    </FlexboxStyled>
  );
}

EmailSettingContent.propTypes = {
  value: T.string,
  validate: T.func,
};

export default EmailSettingContent;
