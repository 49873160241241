import React, { forwardRef } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import TextInput from "commons/components/TextInput";
import Flexbox from "commons/components/Flexbox";
import DragHandle from "commons/components/DragHandle";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Icon from "commons/components/Icon";
import Tooltip from "commons/components/Tooltip";
import NumberInput from "commons/components/NumberInput";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useTranslation } from "react-i18next";

const Box = styled.div``;

const TextInputNarrow = styled(TextInput)`
  width: 128px;
`;

const NumberInputNarrow = styled(NumberInput)`
  width: 128px;
`;

const TextInputWide = styled(TextInput)`
  width: 400px;
`;

const DeleteIcon = styled(Icon)`
  padding: 10px 0;
`;

// eslint-disable-next-line react/display-name
const Ingredient = forwardRef(
  (
    {
      id,
      isCategory,
      amount,
      unit,
      label,
      note,
      className,
      setIngredient,
      setIngredientCategory,
      deleteIngredient,
      handleKeyDown,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({ id });
    const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
    };

    return (
      <Box
        className={className}
        ref={el => {
          setNodeRef(el);
          ref(el);
        }}
        style={style}
        {...attributes}
        tabIndex="-1"
      >
        <Flexbox
          gap={8}
          alignItems={isScreenMaxMedium ? "flex-start" : "center"}
          margin={isScreenMaxMedium ? "0 0 16px 0" : "0"}
        >
          <DragHandle {...listeners} ref={setActivatorNodeRef} />
          {isCategory ? (
            <TextInput
              placeholder={t("Recipes.Category")}
              onChange={value => setIngredientCategory(id, value)}
              value={label}
              onKeyDown={e => handleKeyDown(e, 0)}
              maxLength={64}
            />
          ) : (
            <>
              {isScreenMaxMedium ? (
                <Flexbox flexDirection="column" flexBasis="100%" gap={8}>
                  <TextInput
                    placeholder={t("Recipes.Ingredient")}
                    value={label}
                    onChange={value => setIngredient(id, "label", value)}
                    onKeyDown={e => handleKeyDown(e, 0)}
                    maxLength={64}
                  />
                  <NumberInput
                    placeholder={t("Recipes.Amount")}
                    value={amount}
                    onChange={value => setIngredient(id, "amount", value)}
                    onKeyDown={e => handleKeyDown(e, 1)}
                  />
                  <TextInput
                    placeholder={t("Recipes.Unit")}
                    value={unit}
                    onChange={value => setIngredient(id, "unit", value)}
                    onKeyDown={e => handleKeyDown(e, 2)}
                    maxLength={24}
                  />
                  <TextInput
                    placeholder={t("Recipes.Note")}
                    value={note}
                    onChange={value => setIngredient(id, "note", value)}
                    onKeyDown={e => handleKeyDown(e, 3)}
                    maxLength={64}
                  />
                </Flexbox>
              ) : (
                <>
                  <TextInputWide
                    placeholder={t("Recipes.Ingredient")}
                    value={label}
                    onChange={value => setIngredient(id, "label", value)}
                    onKeyDown={e => handleKeyDown(e, 0)}
                    maxLength={64}
                  />
                  <NumberInputNarrow
                    placeholder={t("Recipes.Amount")}
                    value={amount}
                    onChange={value => setIngredient(id, "amount", value)}
                    onKeyDown={e => handleKeyDown(e, 1)}
                  />
                  <TextInputNarrow
                    placeholder={t("Recipes.Unit")}
                    value={unit}
                    onChange={value => setIngredient(id, "unit", value)}
                    onKeyDown={e => handleKeyDown(e, 2)}
                    maxLength={24}
                  />
                  <TextInputWide
                    placeholder={t("Recipes.Note")}
                    value={note}
                    onChange={value => setIngredient(id, "note", value)}
                    onKeyDown={e => handleKeyDown(e, 3)}
                    maxLength={64}
                  />
                </>
              )}
            </>
          )}
          <Tooltip label={t("Recipes.RemoveIngredient")}>
            <DeleteIcon size={20} name="close" onClick={() => deleteIngredient(id)} />
          </Tooltip>
        </Flexbox>
      </Box>
    );
  }
);

Ingredient.propTypes = {
  className: T.string,
  id: T.number,
  isCategory: T.bool,
  amount: T.oneOfType([T.number, T.string]),
  unit: T.string,
  label: T.string,
  note: T.string,
  setIngredient: T.func,
  setIngredientCategory: T.func,
  deleteIngredient: T.func,
  handleKeyDown: T.func,
};

export default Ingredient;
