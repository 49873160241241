import React, { useMemo, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Popover from "commons/components/Popover";
import Button from "commons/components/Button";
import ActionList from "commons/components/ActionList";
import TextInput from "commons/components/TextInput";
import RecipeCard from "./RecipeCard";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { useTranslation } from "react-i18next";
import { useLocationBase } from "commons/util/useLocationBase";
import { useFilteredRecipes } from "commons/util/useFilteredRecipes";
import Icon from "commons/components/Icon";
import Tooltip from "commons/components/Tooltip";
import { useGroceryList } from "App/GroceryListContext";
import { SkeletonArray } from "commons/components/Skeleton/Skeleton";
import { useRecipeList } from "App/RecipeListContext";
import { PLANS, RECIPE_LIMIT } from "commons/util/constants";
import { useNavigate } from "react-router-dom";
import { useAuth } from "App/AuthContext";

const RecipeListWrap = styled(Flexbox)`
  border-radius: var(--border-radius-0);
  height: calc(100% - 144px);
  overflow-y: auto;
  border-top: 1px solid var(--neutral-180);
`;

const AbsoluteFlexbox = styled(Flexbox)`
  position: absolute;
  background-color: var(--neutral-200);
  border-radius: var(--border-radius-0);

  @media (max-width: ${BREAKPOINTS.large}) {
    box-shadow: var(--shadow-2);
    z-index: var(--z-index-above);
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: calc(100% - 16px);
    max-width: 400px;
  }
`;

const Box = styled.aside`
  width: 340px;
  flex-shrink: 0;
  height: calc(100vh - 52px);

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 100%;
  }
`;

const AddIcon = styled(Icon)`
  &:hover {
    color: var(--primary-100);
  }
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 24px;
  right: -16px;
`;

const PlaceholderBox = styled.div`
  width: 340px;
  flex-shrink: 0;
  height: calc(100vh - 52px);

  @media (max-width: ${BREAKPOINTS.large}) {
    width: 280px;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: 400px;
    max-width: 100%;
  }
`;

function RecipeListSidebar({ isOpen, setIsOpen, onCornerIconClick, isDraggable = false }) {
  const { t } = useTranslation();
  const isScreenMaxLarge = useMediaQuery(BREAKPOINTS.large);
  const locationBase = useLocationBase();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [searchString, setSearchString] = useState("");
  const { recipeList } = useRecipeList();
  const { filteredRecipeList, isLoading } = useFilteredRecipes({ search: searchString });
  const { groceryList } = useGroceryList();

  const filteredRecipeListWithGroceries = useMemo(() => {
    return filteredRecipeList.map(r => ({ ...r, isAdded: groceryList.map(item => item.recipeId).includes(r.id) }));
  }, [filteredRecipeList, groceryList]);

  return (
    <>
      <AnimatePresence>
        {(isOpen || !isScreenMaxLarge) && (
          <AbsoluteFlexbox
            as={motion.div}
            key={isOpen}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Box>
              <Flexbox justifyContent="space-between" padding={24}>
                <Typography variant="h3">{t("Navigation.Recipes")}</Typography>
                <Popover
                  trigger={
                    <Button
                      variant="secondary"
                      icon="add"
                      onClick={e => {
                        if (recipeList.length >= RECIPE_LIMIT && user.accountType === PLANS.free) {
                          e.stopPropagation();
                          navigate(`${locationBase}/upgrade`);
                        }
                      }}
                    >
                      {t("Recipes.AddRecipe")}
                    </Button>
                  }
                  content={closePopover => (
                    <ActionList
                      actions={[
                        {
                          label: t("Recipes.StartFromScratch"),
                          icon: "add",
                          link: `${locationBase}/new`,
                        },
                        {
                          label: t("Recipes.ImportFromWeb"),
                          icon: "language",
                          link: `${locationBase}/new-web`,
                        },
                      ]}
                      closePopover={closePopover}
                    />
                  )}
                />
              </Flexbox>
              <Flexbox gap={8} padding={24} paddingTop={0}>
                <TextInput
                  value={searchString}
                  onChange={setSearchString}
                  placeholder={t("Common.Search")}
                  leftIcon="search"
                  rightIcon={searchString ? "close" : ""}
                  onRightIconClick={() => setSearchString("")}
                  maxLength={200}
                />
                {/* <Button variant="tertiary" icon="filter_list" size="medium">
            			Filter
          			</Button> */}
              </Flexbox>
              <RecipeListWrap padding="16px 24px" flexDirection="column" gap={8}>
                {isLoading && <SkeletonArray count={10} width="100%" height="82px" />}
                {filteredRecipeListWithGroceries.map(recipe => (
                  <RecipeCard
                    key={recipe.id}
                    recipe={recipe}
                    CornerIcon={
                      onCornerIconClick
                        ? () => {
                            const hasNoIngredients =
                              recipe.ingredients.filter(ingredient => ingredient.label).length === 0;
                            return (
                              <Tooltip label={hasNoIngredients ? t("Groceries.NoIngredientsToAdd") : ""}>
                                <AddIcon
                                  size={20}
                                  name={recipe.isAdded ? "check_circle" : "add"}
                                  color={recipe.isAdded ? "red-100" : hasNoIngredients ? "grey-100" : ""}
                                  isFilled={recipe.isAdded}
                                  onClick={e => {
                                    e.stopPropagation();
                                    if (!hasNoIngredients && onCornerIconClick) {
                                      onCornerIconClick(recipe);
                                    }
                                  }}
                                />
                              </Tooltip>
                            );
                          }
                        : null
                    }
                    isDraggable={isDraggable}
                  />
                ))}
              </RecipeListWrap>
            </Box>
            {isScreenMaxLarge && <CloseButton icon="close" variant="tertiary" onClick={() => setIsOpen(false)} />}
          </AbsoluteFlexbox>
        )}
      </AnimatePresence>

      {!isScreenMaxLarge && <PlaceholderBox />}
    </>
  );
}

RecipeListSidebar.propTypes = {
  isOpen: T.bool,
  setIsOpen: T.func,
  onCornerIconClick: T.func,
  isDraggable: T.bool,
};

export default RecipeListSidebar;
