import React from "react";
import styled from "styled-components/macro";
import T from "prop-types";
import RecipeRow from "./RecipeRow";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Skeleton from "commons/components/Skeleton";
import { useTranslation } from "react-i18next";

const Box = styled.div``;

const ListHeader = styled(Flexbox)`
  background-color: var(--neutral-190);
  border: 1px solid var(--neutral-180);
  border-radius: var(--border-radius-0);
  padding: 10px 8px;
`;

const ColumnLabel = styled(Typography)`
  color: var(--neutral-140);
  width: ${({ width }) => width && width};
`;

function RecipeList({ recipes, isLoading }) {
  const { t } = useTranslation();
  return (
    <Box>
      {isLoading ? (
        <Flexbox flexDirection="column" gap={2} marginY={2}>
          <Skeleton width="100%" height="82px" />
          <Skeleton width="100%" height="82px" />
          <Skeleton width="100%" height="82px" />
          <Skeleton width="100%" height="82px" />
          <Skeleton width="100%" height="82px" />
        </Flexbox>
      ) : (
        <>
          {!!recipes.length && (
            <ListHeader gap={16}>
              <ColumnLabel width="64px" variant="tableHeader">
                {t("Recipes.Image")}
              </ColumnLabel>
              <ColumnLabel width="40%" variant="tableHeader">
                {t("Recipes.TitleAndTags")}
              </ColumnLabel>
              <ColumnLabel width="12%" variant="tableHeader">
                {t("Recipes.ActiveTime")}
              </ColumnLabel>
              <ColumnLabel width="12%" variant="tableHeader">
                {t("Recipes.TotalTime")}
              </ColumnLabel>
              <ColumnLabel width="calc(36% - 64px - 24px - 5 * 16px)" variant="tableHeader">
                {t("Recipes.Source")}
              </ColumnLabel>
              <ColumnLabel width="24px" variant="tableHeader"></ColumnLabel>
            </ListHeader>
          )}
          {recipes.map(recipe => (
            <RecipeRow
              key={recipe.id}
              recipeId={recipe.id}
              title={recipe.title}
              tags={recipe.tags}
              activeTime={recipe.activeTime}
              totalTime={recipe.totalTime}
              imageURL={recipe.imageURL}
              source={recipe.source}
              isFavorite={recipe.isFavorite}
              link={`/app/recipes/${recipe.id}`}
            />
          ))}
        </>
      )}
    </Box>
  );
}

RecipeList.propTypes = {
  recipes: T.array,
  isLoading: T.bool,
};

export default RecipeList;
