import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { Outlet } from "react-router-dom";
import { DndContext, PointerSensor, useSensor, useSensors, DragOverlay, pointerWithin } from "@dnd-kit/core";
import RecipeCard from "App/components/RecipeCard";
import RecipeListSidebar from "App/components/RecipeListSidebar";
import MealItem from "./Planner/components/MealItem";
import PlannerContainer from "./Planner/Planner.container";

const Box = styled(Flexbox)`
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-0);
`;

const MainSection = styled.main`
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--neutral-180);
  width: 100%;
  height: calc(100vh - 52px);
  padding: 0 32px 40px 56px;
  overflow-y: auto;
  z-index: var(--z-index-0);

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 0 32px 32px 32px;
  }
  @media (max-width: ${BREAKPOINTS.small}) {
    height: auto;
    min-height: calc(100vh - 52px);
    padding-top: 24px;
  }
`;

const MealItemStyled = styled(MealItem)`
  cursor: grabbing;
`;

function Calendar({
  className,
  isRecipeListSidebarOpen,
  setIsRecipeListSidebarOpen,
  draggedRecipe,
  draggedMealItem,
  handleDragStart,
  handleDragEnd,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 0.1,
      },
    })
  );

  return (
    <Box className={className}>
      <DndContext
        sensors={sensors}
        collisionDetection={pointerWithin}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <RecipeListSidebar isOpen={isRecipeListSidebarOpen} setIsOpen={setIsRecipeListSidebarOpen} isDraggable />

        <DragOverlay dropAnimation={null}>
          {draggedRecipe && <RecipeCard key={draggedRecipe.id} recipe={draggedRecipe} isDraggable />}
          {draggedMealItem && <MealItemStyled key={draggedMealItem.id} mealItem={draggedMealItem} />}
        </DragOverlay>

        <MainSection>
          <PlannerContainer />
        </MainSection>
      </DndContext>

      <Outlet />
    </Box>
  );
}

Calendar.propTypes = {
  className: T.string,
  isRecipeListSidebarOpen: T.bool,
  setIsRecipeListSidebarOpen: T.func,
  draggedRecipe: T.object,
  draggedMealItem: T.object,
  handleDragStart: T.func,
  handleDragEnd: T.func,
};

export default Calendar;
