import React from "react";
import styled from "styled-components/macro";
import Page404SVG from "commons/images/404b.jpeg";
import Button from "commons/components/Button";
import Typography from "commons/components/Typography";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { useTranslation } from "react-i18next";

const Box = styled.div`
  padding: 144px;
  height: 100vh;
  background-position: bottom;
  background-size: cover;

  @media (max-width: ${BREAKPOINTS.medium}) {
    padding: 80px;
  }

  @media (max-width: ${BREAKPOINTS.small}) {
    padding: 56px 32px;
  }
`;

const Subtitle = styled(Typography)`
  font-weight: 500;
`;

const Credit = styled(Typography)`
  position: absolute;
  bottom: 24px;
  right: 24px;
`;

function Page404() {
  const { t } = useTranslation();
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);

  return (
    <Box style={{ backgroundImage: `url(${Page404SVG})` }}>
      <Typography variant={isScreenMaxMedium ? "h3" : "h1"} color="neutral-200" marginBottom={16}>
        {t("404.Title")}
      </Typography>
      <Subtitle variant="paragraph" color="neutral-200" marginBottom={isScreenMaxMedium ? 16 : 32}>
        {t("404.Paragraph")}
      </Subtitle>
      <Button link="/" size={isScreenMaxMedium ? "small" : "medium"}>
        {t("404.Button")}
      </Button>

      {/* <Credit variant="label" color="neutral-200">
        Photo by Damjan Roškar
      </Credit> */}
    </Box>
  );
}

export default Page404;
