import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import EmptyState from "commons/components/EmptyState";
import noTagsImage from "commons/images/emptyState/tags.svg";
import { Column, Row } from "commons/components/Flexbox/Flexbox";
import TagGroup from "./components/TagGroup";
import Skeleton from "commons/components/Skeleton";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useTranslation } from "react-i18next";

const Box = styled.div``;

function Tags({
  className,
  loading,
  tagGroups,
  editedTagGroupId,
  setEditedTagGroupId,
  addTagGroup,
  updateTagGroupLabel,
  updateTagGroupColor,
  deleteTagGroup,
  reorderTags,
  editedTagId,
  setEditedTagId,
  addTag,
  updateTag,
  deleteTag,
  getGroupColor,
}) {
  const { t } = useTranslation();
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);
  const tagGroupProps = {
    editedTagGroupId,
    setEditedTagGroupId,
    updateTagGroupLabel,
    updateTagGroupColor,
    deleteTagGroup,
    reorderTags,
    editedTagId,
    setEditedTagId,
    addTag,
    updateTag,
    deleteTag,
    getGroupColor,
  };

  return (
    <Box className={className}>
      <Flexbox justifyContent="space-between" marginBottom={24}>
        <Typography variant="h3">{t("Settings.Tags")}</Typography>
        <Button icon="add" variant="secondary" onClick={addTagGroup}>
          {t("Settings.AddTagGroup")}
        </Button>
      </Flexbox>
      {loading && (
        <Row gap={12}>
          <Column flexBasis="50%" gap={12}>
            <Skeleton width="100%" height="300px" />
            <Skeleton width="100%" height="240px" />
          </Column>
          <Column flexBasis="50%" gap={12}>
            <Skeleton width="100%" height="240px" />
            <Skeleton width="100%" height="300px" />
          </Column>
        </Row>
      )}
      {!loading && !tagGroups.length ? (
        <EmptyState image={noTagsImage} label={t("Settings.NoTags")} height="480px" />
      ) : (
        <Row gap={12}>
          {isScreenMaxSmall ? (
            <Column flexBasis="100%" gap={12}>
              {tagGroups.map(group => (
                <TagGroup key={group.id} group={group} {...tagGroupProps} />
              ))}
            </Column>
          ) : (
            <>
              <Column flexBasis="50%" gap={12}>
                {tagGroups
                  .filter(g => g.column === 0)
                  .map(group => (
                    <TagGroup key={group.id} group={group} {...tagGroupProps} />
                  ))}
              </Column>
              <Column flexBasis="50%" gap={12}>
                {tagGroups
                  .filter(g => g.column === 1)
                  .map(group => (
                    <TagGroup key={group.id} group={group} {...tagGroupProps} />
                  ))}
              </Column>
            </>
          )}
        </Row>
      )}
    </Box>
  );
}

Tags.propTypes = {
  className: T.string,
  loading: T.bool,
  tagGroups: T.array,
  editedTagGroupId: T.number,
  setEditedTagGroupId: T.func,
  addTagGroup: T.func,
  updateTagGroupLabel: T.func,
  updateTagGroupColor: T.func,
  deleteTagGroup: T.func,
  reorderTags: T.func,
  editedTagId: T.number,
  setEditedTagId: T.func,
  addTag: T.func,
  updateTag: T.func,
  deleteTag: T.func,
  getGroupColor: T.func,
};

export default Tags;
