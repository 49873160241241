import React, { useRef } from "react";
import T from "prop-types";
import styled, { css } from "styled-components/macro";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import Icon from "commons/components/Icon";
import Button from "commons/components/Button";
import Loader from "commons/components/Loader";
import Tooltip from "commons/components/Tooltip";
import RecipeImage from "App/Recipes/components/RecipeImage";
import { useTranslation } from "react-i18next";

const RecipeImageWrap = styled.div`
  position: relative;
`;

const RecipeImageStyled = styled(RecipeImage)`
  & > div {
    border-radius: var(--border-radius-2);
  }
`;

const InvisibleInput = styled.input`
  display: none;
`;

const ImagePlaceholder = styled(Flexbox)`
  border-radius: var(--border-radius-2);
  background-color: var(--neutral-190);
  width: 100%;
  height: 240px;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background-color: var(--neutral-180);
  }

  ${({ isLoading }) =>
    isLoading &&
    css`
      pointer-events: none;
    `}
`;

const ImageSizeError = styled(Typography)``;

function ImageInput({ imageURL, deleteImage, uploadImage, imageSizeError, loading }) {
  const { t } = useTranslation();
  const imageInput = useRef(null);

  return (
    <>
      {imageURL ? (
        <RecipeImageWrap>
          <RecipeImageStyled width="100%" height="240px" alt={t("Recipes.RecipeImage")} src={imageURL} isRemote />
          <Tooltip label={t("Recipes.DeleteImage")} triggerStyles={{ position: "absolute", top: "8px", right: "8px" }}>
            <Button variant="tertiary" icon="delete" onClick={deleteImage} />
          </Tooltip>
        </RecipeImageWrap>
      ) : (
        <>
          <InvisibleInput ref={imageInput} type="file" accept="image/*" onChange={e => uploadImage(e)} />
          <ImagePlaceholder
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={4}
            onClick={() => imageInput.current && imageInput.current.click()}
            isLoading={loading}
          >
            {loading ? (
              <Loader />
            ) : (
              <>
                <Icon name="add_photo_alternate" color="neutral-140" size={20} />
                <Typography variant="label" color="neutral-140">
                  {t("Recipes.AddImage")}
                </Typography>
              </>
            )}
          </ImagePlaceholder>
          {imageSizeError && <ImageSizeError color="red-100">{imageSizeError}</ImageSizeError>}
        </>
      )}
    </>
  );
}

ImageInput.propTypes = {
  imageURL: T.string,
  deleteImage: T.func,
  uploadImage: T.func,
  imageSizeError: T.string,
  loading: T.bool,
};

export default ImageInput;
