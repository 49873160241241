import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import TextInput from "commons/components/TextInput";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { changePassword } from "commons/util/auth";
import ErrorBlock from "commons/components/ErrorBlock";
import { useToast } from "commons/util/useToast";
import { MIN_PASSWORD_LENGTH } from "commons/util/constants";
import { useTranslation } from "react-i18next";

const MultiLineWrap = styled(Flexbox)`
  width: 100%;
`;

const FlexboxStyled = styled(Flexbox)`
  @media (max-width: ${BREAKPOINTS.small}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const ErrorBlockStyled = styled(ErrorBlock)`
  align-self: flex-start;
`;

const PasswordInput = styled(TextInput)``;

const StringValue = styled(Typography)`
  line-height: 40px;
  max-width: 100%;
`;

function PasswordSettingContent() {
  const { t } = useTranslation();
  const [isInputActive, setIsInputActive] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const { showToast } = useToast();

  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordError, setOldPasswordError] = useState("");
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);

  useEffect(() => {
    if (!isInputActive) {
      setOldPassword("");
      setNewPassword("");
    }
  }, [isInputActive]);

  useEffect(() => {
    if (oldPasswordError) setOldPasswordError("");
  }, [oldPassword]);

  useEffect(() => {
    if (newPasswordError) setNewPasswordError("");
  }, [newPassword]);

  function validateOldPassword() {
    if (oldPassword.length < MIN_PASSWORD_LENGTH) {
      setOldPasswordError(t("Authentication.InvalidPassword", { length: MIN_PASSWORD_LENGTH }));
      return false;
    }
    return true;
  }

  function validateNewPassword() {
    if (newPassword === oldPassword) {
      setNewPasswordError(t("Authentication.PasswordIdenticalError"));
      return false;
    }
    if (newPassword.length < MIN_PASSWORD_LENGTH) {
      setNewPasswordError(t("Authentication.InvalidPassword", { length: MIN_PASSWORD_LENGTH }));
      return false;
    }
    return true;
  }

  async function handleChangePassword() {
    setGeneralError("");
    const isValidOldPassword = validateOldPassword();
    const isValidNewPassword = validateNewPassword();

    if (isValidOldPassword && isValidNewPassword) {
      setIsProcessing(true);
      try {
        await changePassword(oldPassword, newPassword);
        setIsInputActive(false);
        showToast(t("Authentication.PasswordChangeSuccess"));
      } catch (e) {
        setGeneralError(e.message);
      } finally {
        setIsProcessing(false);
      }
    }
  }

  return (
    <FlexboxStyled justifyContent="space-between" alignItems="center">
      {isInputActive ? (
        <MultiLineWrap flexDirection="column" alignItems="flex-end" gap={16}>
          <ErrorBlockStyled>{generalError}</ErrorBlockStyled>
          <PasswordInput
            label={t("Authentication.OldPassword")}
            type={isOldPasswordVisible ? "text" : "password"}
            value={oldPassword}
            onChange={setOldPassword}
            errorMessage={oldPasswordError}
            onBlur={validateOldPassword}
            rightIcon={isOldPasswordVisible ? "visibility" : "visibility_off"}
            onRightIconClick={() => setIsOldPasswordVisible(isVisible => !isVisible)}
            maxLength={128}
          />
          <PasswordInput
            label={t("Authentication.NewPassword")}
            type={isNewPasswordVisible ? "text" : "password"}
            value={newPassword}
            onChange={setNewPassword}
            errorMessage={newPasswordError}
            onBlur={validateNewPassword}
            leftHint={t("Authentication.MinimumChars", { length: MIN_PASSWORD_LENGTH })}
            rightIcon={isNewPasswordVisible ? "visibility" : "visibility_off"}
            onRightIconClick={() => setIsNewPasswordVisible(isVisible => !isVisible)}
            maxLength={128}
          />
          <Flexbox gap={8}>
            <Button variant="tertiary" onClick={() => setIsInputActive(false)}>
              {t("Common.Cancel")}
            </Button>
            <Button variant="primary" onClick={handleChangePassword} loading={isProcessing}>
              {t("Common.Save")}
            </Button>
          </Flexbox>
        </MultiLineWrap>
      ) : (
        <>
          <StringValue variant="body" as="div">
            *********
          </StringValue>
          <Button variant="secondary" onClick={() => setIsInputActive(true)}>
            {t("Common.Change")}
          </Button>
        </>
      )}
    </FlexboxStyled>
  );
}

export default PasswordSettingContent;
