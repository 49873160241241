import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import T from "prop-types";
import { useGroceryList, useGroceryListDispatch } from "App/GroceryListContext";
import callApi from "commons/util/callApi";
import { useToast } from "commons/util/useToast";
import { useRecipeList } from "App/RecipeListContext";
import Groceries from "./Groceries";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { sortGroceryAisles } from "commons/util/helpers";
import { predefinedAisles } from "./predefinedAisles.const";
import callLocalStorage from "commons/util/callLocalStorage";

export const VIEWS = {
  BY_AISLE: "by-aisle",
  BY_RECIPE: "by-recipe",
};

function GroceriesContainer({ className }) {
  const { t } = useTranslation();
  const rememberedView = callLocalStorage(`groceries-view`, "get");
  const [view, setView] = useState(rememberedView || VIEWS.BY_AISLE);
  const [isRecipeListSidebarOpen, setIsRecipeListSidebarOpen] = useState(false);
  const { recipeList, isLoading: isRecipeListLoading } = useRecipeList();
  const { showToast } = useToast();

  const { groceryList, isLoading: isGroceryListLoading } = useGroceryList();
  const dispatch = useGroceryListDispatch();
  const initComplete = useRef(false);

  useEffect(() => {
    callLocalStorage(`groceries-view`, "set", view);
  }, [view]);

  function setGroceryList(newGroceryList) {
    dispatch({ type: "UPDATE", groceryList: newGroceryList });
  }

  const syncData = useCallback(
    debounce(async newGroceryList => {
      await saveGroceryList(newGroceryList);
    }, 1000),
    []
  );

  useEffect(() => {
    if (!isGroceryListLoading) {
      if (initComplete.current) {
        syncData(groceryList);
      } else {
        initComplete.current = true;
      }
    }
  }, [groceryList]);

  const [latestGroceryHistory, setLatestGroceryHistory] = useState([]);

  const saveGroceryHistory = useCallback(
    debounce(async history => {
      try {
        await callApi("groceries/purchase-report", "post", history);
        setLatestGroceryHistory([]);
      } catch (e) {
        console.log(e);
      }
    }, 1000),
    []
  );

  function addToGroceryHistory(purchase) {
    setLatestGroceryHistory(items => [...items, purchase]);
  }

  useEffect(() => {
    if (latestGroceryHistory.length) {
      saveGroceryHistory(latestGroceryHistory);
    }
  }, [latestGroceryHistory]);

  const groupedGroceryList = useMemo(() => {
    let result = {};

    if (view === VIEWS.BY_AISLE) {
      for (const item of groceryList) {
        const foundAisleEntry = Object.entries(predefinedAisles).find(([_, value]) => {
          if (value.includes(item.label)) {
            return true;
          }
          return false;
        });

        const aisle = foundAisleEntry ? foundAisleEntry[0] : t("Groceries.Other");

        if (result[aisle]) {
          result[aisle].push(item);
        } else {
          result[aisle] = [item];
        }
      }

      result = sortGroceryAisles(result);
    } else {
      if (groceryList.filter(el => !el.recipeId).length) {
        result[t("Groceries.Staples")] = groceryList.filter(el => !el.recipeId);
      }

      for (const item of groceryList) {
        const recipeTitle = recipeList.find(el => el.id === item.recipeId)?.title;

        if (recipeTitle) {
          if (result[recipeTitle]) {
            result[recipeTitle].push(item);
          } else {
            result[recipeTitle] = [item];
          }
        }
      }
    }

    return result;
  }, [groceryList, recipeList, view]);

  async function saveGroceryList(newGroceryList) {
    try {
      await callApi("groceries", "put", { groceries: newGroceryList });
    } catch (e) {
      showToast(t("Common.GenericErrorExpanded"), "error");
    }
  }

  async function clearAllItems() {
    try {
      setGroceryList([]);
      await callApi("groceries", "delete");
    } catch (e) {
      showToast(t("Common.GenericErrorExpanded"), "error");
    }
  }

  function clearAllCheckedItems() {
    let newGroceryList = groceryList
      .map(item => {
        if (item.isChecked) {
          return null;
        }
        return item;
      })
      .filter(Boolean);
    setGroceryList(newGroceryList);
  }

  function checkAllItems() {
    let newGroceryList = groceryList.map(item => {
      return { ...item, isChecked: true };
    });
    setGroceryList(newGroceryList);
  }

  function uncheckAllItems() {
    let newGroceryList = groceryList.map(item => {
      return { ...item, isChecked: false };
    });
    setGroceryList(newGroceryList);
  }

  function copyListAsText() {
    let listAsText = "";
    for (const [group, items] of Object.entries(groupedGroceryList)) {
      listAsText += `${group}:\n`;
      items.forEach(el => {
        const check = el.isChecked ? "x" : " ";
        const label = el.label;
        const amount = el.amount;
        const unit = el.unit;
        const amountAndUnit = amount && unit ? `${amount} ${unit}` : amount || unit || "";
        listAsText += `- [${check}] ${label}${amountAndUnit ? " (" + amountAndUnit + ")" : ""}\n`;
      });
      listAsText += "\n";
    }
    navigator.clipboard.writeText(listAsText);
    showToast(t("Groceries.GroceriesCopied"), "success");
  }

  function toggleGroceryListRecipe(recipe) {
    const recipeIngredients = recipe.ingredients
      .filter(ingredient => ingredient.label && !ingredient.isCategory)
      .map(ingredient => ({
        id: `${recipe.id}-${ingredient.id}`,
        label: ingredient.label,
        amount: ingredient.amount,
        unit: ingredient.unit,
        recipeId: recipe.id,
      }));

    let newGroceryList;
    if (groceryList.map(el => el.recipeId).includes(recipe.id)) {
      newGroceryList = groceryList.filter(item => item.recipeId !== recipe.id);
    } else {
      newGroceryList = [...recipeIngredients, ...groceryList];
    }
    setGroceryList(newGroceryList);
  }

  function toggleGroceryItem(itemId) {
    let newGroceryList = groceryList.map(item => {
      if (item.id === itemId) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    setGroceryList(newGroceryList);

    const item = groceryList.find(item => item.id === itemId);
    if (!item.recipeId) addToGroceryHistory(item.label);
  }

  function removeGroceryItem(itemId) {
    let newGroceryList = groceryList.filter(item => item.id !== itemId);
    setGroceryList(newGroceryList);
  }

  function editGroceryItem(itemId, newValues) {
    let newGroceryList = groceryList.map(item => {
      if (item.id === itemId) {
        return { ...item, ...newValues };
      }
      return item;
    });
    setGroceryList(newGroceryList);
  }

  return (
    <>
      <Helmet>
        <title>{t("Navigation.Groceries")} | Cook Your Goose</title>
      </Helmet>
      <Groceries
        className={className}
        groceryList={groceryList}
        isGroceryListLoading={isGroceryListLoading || isRecipeListLoading}
        groupedGroceryList={groupedGroceryList}
        toggleGroceryItem={toggleGroceryItem}
        removeGroceryItem={removeGroceryItem}
        editGroceryItem={editGroceryItem}
        toggleGroceryListRecipe={toggleGroceryListRecipe}
        setGroceryList={setGroceryList}
        view={view}
        setView={setView}
        clearAllItems={clearAllItems}
        clearAllCheckedItems={clearAllCheckedItems}
        checkAllItems={checkAllItems}
        uncheckAllItems={uncheckAllItems}
        copyListAsText={copyListAsText}
        isRecipeListSidebarOpen={isRecipeListSidebarOpen}
        setIsRecipeListSidebarOpen={setIsRecipeListSidebarOpen}
        addToGroceryHistory={addToGroceryHistory}
      />
    </>
  );
}

GroceriesContainer.propTypes = {
  className: T.string,
};

export default GroceriesContainer;
