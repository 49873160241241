import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import TextInput from "commons/components/TextInput";
import NumberInput from "commons/components/NumberInput";
import Button from "commons/components/Button";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";

const TextInputNarrow = styled(TextInput)`
  width: 96px;
  flex-shrink: 0;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: calc(50% - 4px);
  }
`;

const NumberInputNarrow = styled(NumberInput)`
  width: 96px;
  flex-shrink: 0;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: calc(50% - 4px);
  }
`;

const Actions = styled(Flexbox)`
  width: 100%;
`;

function EditGroceryItemPopoverContent({
  itemId,
  label: labelInit,
  amount: amountInit,
  unit: unitInit,
  editGroceryItem,
  onClose,
}) {
  const { t } = useTranslation();
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);

  const [label, setLabel] = useState(labelInit);
  const [amount, setAmount] = useState(amountInit);
  const [unit, setUnit] = useState(unitInit);

  useEffect(() => {
    setLabel(labelInit);
    setAmount(amountInit);
    setUnit(unitInit);
  }, [labelInit, amountInit, unitInit]);

  function handleSave() {
    editGroceryItem(itemId, { label, amount, unit });
    onClose();
  }

  return (
    <Flexbox flexDirection="column" padding={16}>
      <Flexbox gap={8} flexWrap={isScreenMaxSmall ? "wrap" : "nowrap"}>
        <TextInput
          label={t("Groceries.Item")}
          value={label || ""}
          onChange={setLabel}
          onConfirm={handleSave}
          maxLength={64}
          autoFocus
        />
        <NumberInputNarrow label={t("Groceries.Amount")} value={amount} onChange={setAmount} onConfirm={handleSave} />
        <TextInputNarrow
          label={t("Groceries.Unit")}
          value={unit || ""}
          onChange={setUnit}
          onConfirm={handleSave}
          maxLength={24}
        />
      </Flexbox>
      <Actions justifyContent="flex-end" gap={8} marginTop={24}>
        <Button onClick={onClose} variant="tertiary">
          {t("Common.Cancel")}
        </Button>
        <Button onClick={handleSave}>{t("Common.Save")}</Button>
      </Actions>
    </Flexbox>
  );
}

EditGroceryItemPopoverContent.propTypes = {
  itemId: T.string,
  label: T.string,
  amount: T.oneOfType([T.string, T.number]),
  unit: T.string,
  editGroceryItem: T.func,
  onClose: T.func,
};

export default EditGroceryItemPopoverContent;
