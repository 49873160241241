import React, { useCallback, useRef } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import Step from "./Step";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import Button from "commons/components/Button";
import { useTranslation } from "react-i18next";

const Box = styled.div``;

const AddStepButton = styled(Button)`
  margin-top: 16px;
`;

function Steps({ className, steps, setSteps, setStepText, deleteStep, addStep }) {
  const { t } = useTranslation();
  const stepRefs = useRef([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setSteps(() => {
        const oldIndex = steps.findIndex(el => el.id === active.id);
        const newIndex = steps.findIndex(el => el.id === over.id);
        return arrayMove(steps, oldIndex, newIndex).map((el, i) => ({ ...el, number: i + 1 }));
      });
    }
  }

  const handleKeyDown = useCallback(
    (e, i, flushEvent) => {
      // TO DO: pressing Enter at the end of the last line should create a new step
      // the solution below doesn't work for multiple lines
      //
      // const { key, shiftKey, target } = e;
      //
      // if (key === "Enter" && !shiftKey && document.getSelection().anchorOffset === target.textContent.length) {
      //   e.stopPropagation();
      //   e.preventDefault();
      //   if (flushEvent) flushEvent();
      //   if (i === steps.length - 1) {
      //     addStep();
      //   }
      //   setTimeout(() => {
      //     const node = stepRefs.current[i + 1]?.querySelectorAll(".ProseMirror")[0];
      //     if (node) node.focus();
      //   }, [10]);
      // }
    },
    [steps.length]
  );

  return (
    <Box className={className}>
      <Typography variant="h3" marginBottom={16}>
        {t("Recipes.Directions")}
      </Typography>
      <Flexbox gap={8} flexDirection="column" flexGrow={1}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext items={steps} strategy={verticalListSortingStrategy}>
            {steps.map((step, i) => (
              <Step
                key={step.id}
                ref={el => (stepRefs.current[i] = el)}
                id={step.id}
                number={step.number}
                text={step.text}
                setStepText={setStepText}
                deleteStep={deleteStep}
                handleKeyDown={(e, flushEvent) => handleKeyDown(e, i, flushEvent)}
                tipTapDependecies={[stepRefs, stepRefs.current, steps.length]}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Flexbox>
      <AddStepButton variant="secondary" icon="add" onClick={addStep}>
        {t("Recipes.AddStep")}
      </AddStepButton>
    </Box>
  );
}

Steps.propTypes = {
  className: T.string,
  steps: T.array,
  setSteps: T.func,
  setStepText: T.func,
  deleteStep: T.func,
  addStep: T.func,
};

export default Steps;
