import React, { useEffect, useRef, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import TextInput from "commons/components/TextInput";
import Modal from "commons/components/Modal";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "commons/components/Typography";
import Button from "commons/components/Button";
import Flexbox from "commons/components/Flexbox";
import Icon from "commons/components/Icon";
import callApi from "commons/util/callApi";
import { useLocationBase } from "commons/util/useLocationBase";
import { useToast } from "commons/util/useToast";
import { Trans, useTranslation } from "react-i18next";

const ModalStyled = styled(Modal)`
  max-width: 100%;
  width: 640px;
  height: auto;
`;

const InfoBox = styled(Flexbox)`
  background-color: var(--info-190);
`;

const InfoIcon = styled(Icon)`
  margin-top: 4px;
`;

function ShareRecipeModal({ className }) {
  const { t } = useTranslation();
  const [url, setUrl] = useState("");
  const [linkCopied, setLinkCopied] = useState(false);
  const navigate = useNavigate();
  const locationBase = useLocationBase();
  const recipeId = useParams().recipeId;
  const timeoutRef = useRef(null);
  const { showToast } = useToast();

  function closeModal() {
    navigate(locationBase);
  }

  useEffect(() => {
    if (!url) {
      generateLink();
    }

    return () => clearTimeout(timeoutRef.current);
  }, []);

  async function generateLink() {
    try {
      const res = await callApi("share", "post", { recipeId }); // try catch
      setUrl(window.location.origin + res.shareUrl);
    } catch (e) {
      showToast(t("Recipes.SharedRecipeLinkError"), "error");
    }
  }

  function copyLinkToClipboard() {
    navigator.clipboard.writeText(url);
    setLinkCopied(true);
    timeoutRef.current = setTimeout(() => {
      setLinkCopied(false);
    }, 5000);
  }

  return (
    <ModalStyled className={className} handleClose={closeModal}>
      <Flexbox flexDirection="column" gap={16} padding={24}>
        <Typography variant="h3">{t("Recipes.ShareRecipe")}</Typography>
        <Flexbox alignItems="flex-end" gap={8}>
          <TextInput
            value={url}
            label={t("Recipes.SharedRecipeUrl")}
            placeholder={t("Recipes.SharedRecipeUrlPlaceholder")}
            disabled
          />
          <Button size="medium" onClick={copyLinkToClipboard} disabled={!url}>
            {linkCopied ? t("Recipes.LinkCopied") : t("Recipes.CopyLink")}
          </Button>
        </Flexbox>
        <InfoBox gap={8} padding={8}>
          <InfoIcon name="info" color="info-100" size={20} />
          <Typography variant="paragraph" color="info-80">
            <Trans>{t("Recipes.SharedRecipeInfoBlock")}</Trans>
          </Typography>
        </InfoBox>
      </Flexbox>
    </ModalStyled>
  );
}

ShareRecipeModal.propTypes = {
  className: T.string,
};

export default ShareRecipeModal;
