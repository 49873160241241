import React from "react";
import T from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import Typography from "commons/components/Typography";
import GroceryItem from "./GroceryItem";
import { VIEWS } from "../Groceries.container";

function GroceryItemList({ groupedGroceryList, view, toggleGroceryItem, removeGroceryItem, editGroceryItem }) {
  return (
    <AnimatePresence mode="popLayout">
      {Object.entries(groupedGroceryList).map(([group, items]) => (
        <motion.div
          key={group}
          // layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.2,
            layout: {
              duration: 0.2,
            },
          }}
          style={{ marginBottom: 24 }}
        >
          <motion.div
            layout
            transition={{
              duration: 0.2,
              layout: {
                duration: 0.2,
              },
            }}
          >
            <Typography variant="h4" marginBottom={8}>
              {group}
            </Typography>
          </motion.div>
          <AnimatePresence mode="popLayout">
            {items.map(item => (
              <motion.div
                layout
                key={item.id}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.2,
                  layout: {
                    duration: 0.2,
                  },
                }}
                style={{ marginTop: 4 }}
              >
                <GroceryItem
                  itemId={item.id}
                  label={item.label}
                  amount={item.amount}
                  unit={item.unit}
                  note={item.note}
                  isChecked={item.isChecked}
                  recipeTitle={view === VIEWS.BY_RECIPE ? "" : item.recipeTitle}
                  recipeId={item.recipeId}
                  toggleGroceryItem={toggleGroceryItem}
                  removeGroceryItem={removeGroceryItem}
                  editGroceryItem={editGroceryItem}
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      ))}
    </AnimatePresence>
  );
}

GroceryItemList.propTypes = {
  className: T.string,
  groupedGroceryList: T.object,
  view: T.string,
  toggleGroceryItem: T.func,
  removeGroceryItem: T.func,
  editGroceryItem: T.func,
};

export default GroceryItemList;
