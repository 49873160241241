import React, { useMemo, useState } from "react";
import T from "prop-types";
import { isToday, format, isSameDay, isPast, isSameMonth } from "date-fns";
import styled, { css } from "styled-components/macro";
import { MEAL_TYPES, TIME_FRAMES } from "commons/util/constants";
import { useMealPlan } from "App/MealPlanContext";
import MealBlock from "./components/MealBlock";
import Flexbox from "commons/components/Flexbox";
import Button from "commons/components/Button";
// import Tooltip from "commons/components/Tooltip";
// import { useTranslation } from "react-i18next";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% / 7 + 1px);
  border: 1px solid var(--neutral-180);
  margin-left: -1px;
  margin-top: -1px;

  ${({ isPast }) =>
    isPast &&
    css`
      ${DayNumber} {
        color: var(--neutral-170);
      }
    `}

  ${({ timeFrame, isSameMonth }) =>
    timeFrame === TIME_FRAMES.month &&
    !isSameMonth &&
    css`
      background-color: var(--neutral-190);
      ${DayNumber} {
        color: var(--neutral-170);
      }
    `}
`;

const ButtonStyled = styled(Button)`
  padding: 4px;

  ${({ isVisible }) =>
    !isVisible &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`;

const DayNumber = styled.span`
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 28px;
  width: 28px;
  height: 28px;
  text-align: center;
  color: var(--neutral-120);
  font-weight: 600;
  z-index: var(--z-index-0);

  ${({ isToday }) =>
    isToday &&
    css`
      color: var(--neutral-200);

      &:before {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        top: 2px;
        left: 2px;
        border-radius: 50%;
        background-color: var(--primary-100);
        z-index: var(--z-index-below);
      }
    `}
`;

const DayNumberRow = styled(Flexbox)`
  border-radius: var(--border-radius-0);
  border-bottom: 1px solid var(--neutral-180);
  padding: 6px;

  &:hover {
    ${ButtonStyled} {
      opacity: 1;
      visibility: visible;
    }
  }

  ${({ timeFrame }) =>
    timeFrame === TIME_FRAMES.day &&
    css`
      top: 0;
      padding-left: 16px;
    `}

  ${({ timeFrame }) =>
    timeFrame === TIME_FRAMES.week &&
    css`
      border-bottom: none;
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;

      &:hover {
        background-color: var(--neutral-190);

        ${DayNumber} {
          color: ${({ isToday }) => (isToday ? "var(--neutral-200)" : "var(--primary-100)")};
        }
      }
    `}
`;

const PlannerDay = ({ date, shownDate, timeFrame, showDay }) => {
  // const { t } = useTranslation();
  const { mealPlan } = useMealPlan();
  // const [isAddedToGroceries, setIsAddedToGroceries] = useState(false);

  const todaysMeals = useMemo(() => mealPlan?.filter(meal => isSameDay(date, meal.date)), [mealPlan]);

  return (
    <Box
      className={`planner-day day-${format(date, "d-MM")}`}
      timeFrame={timeFrame}
      isPast={isPast(date) && !isToday(date)}
      isSameMonth={isSameMonth(date, shownDate)}
    >
      <DayNumberRow
        alignItems="center"
        justifyContent="space-between"
        tabIndex="0"
        timeFrame={timeFrame}
        onClick={timeFrame === TIME_FRAMES.day ? null : () => showDay(date)}
        isToday={isToday(date)}
      >
        <DayNumber isToday={isToday(date)}>{format(date, "d")}</DayNumber>
        {/* <Tooltip label={isAddedToGroceries ? t("Recipes.AddedToGroceries") : t("Recipes.AddToGroceries")}>
          <ButtonStyled
            variant={isAddedToGroceries ? "secondary" : "tertiary"}
            icon="shopping_cart"
            onClick={e => {
              e.stopPropagation();
              setIsAddedToGroceries(isAdded => !isAdded);
            }}
            isVisible={isAddedToGroceries}
          />
        </Tooltip> */}
      </DayNumberRow>
      <MealBlock date={date} mealType={MEAL_TYPES.breakfast} dateMealItems={todaysMeals} timeFrame={timeFrame} />
      <MealBlock date={date} mealType={MEAL_TYPES.lunch} dateMealItems={todaysMeals} timeFrame={timeFrame} />
      <MealBlock date={date} mealType={MEAL_TYPES.dinner} dateMealItems={todaysMeals} timeFrame={timeFrame} />
      <MealBlock date={date} mealType={MEAL_TYPES.snacks} dateMealItems={todaysMeals} timeFrame={timeFrame} />
    </Box>
  );
};

PlannerDay.propTypes = {
  date: T.instanceOf(Date).isRequired,
  shownDate: T.instanceOf(Date).isRequired,
  timeFrame: T.string.isRequired,
  showDay: T.func.isRequired,
};

export default PlannerDay;
