import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import DatePicker, { registerLocale } from "react-datepicker";
import Button from "commons/components/Button";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import pl from "date-fns/locale/pl";
import i18next from "i18next";

import "react-datepicker/dist/react-datepicker.css";
import "./reactDatePicker.css";

const NavigationButton = styled(Button)`
  padding: 4px;
`;

const firstDayOfTheWeek = 1; // Monday

function DateRangePicker({ className, startDate, endDate, onChange }) {
  const currentLanguage = i18next.resolvedLanguage;
  registerLocale("pl", pl);

  return (
    <DatePicker
      className={className}
      locale={currentLanguage || "en-US"}
      selected={startDate}
      addDays
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline
      calendarStartDay={firstDayOfTheWeek}
      maxDate={new Date()}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <Flexbox justifyContent="space-between" alignItems="center" paddingX={8}>
          <NavigationButton
            variant="tertiary"
            icon="chevron_left"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          />
          <Typography variant="h4">{date.toLocaleString(currentLanguage, { month: "long" })}</Typography>
          <NavigationButton
            variant="tertiary"
            icon="chevron_right"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          />
        </Flexbox>
      )}
    />
  );
}

DateRangePicker.propTypes = {
  className: T.string,
  startDate: T.instanceOf(Date),
  endDate: T.instanceOf(Date),
  onChange: T.func,
};

export default DateRangePicker;
