import React, { useMemo } from "react";
import T from "prop-types";
import Flexbox from "commons/components/Flexbox";
import PlannerHeader from "./PlannerHeader";
import styled, { css } from "styled-components/macro";
import { MEAL_COLORS, MEAL_TYPES, TIME_FRAMES } from "commons/util/constants";
import { useMealPlan } from "App/MealPlanContext";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { isSameDay } from "date-fns";
import Typography from "commons/components/Typography";
import { useTranslation } from "react-i18next";
import MobileMealBlock from "./components/MobileMealBlock";
import DatePickerWithMeals from "commons/components/DatePickerWithMeals";

const Label = styled(Typography)`
  display: flex;
  align-items: center;
  margin-top: 8px;

  &:before {
    content: "";
    display: block;
    background-color: ${({ dotColor }) => `var(--${dotColor}-100)`};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
  }
`;

const MealLabelsWrap = styled(Flexbox)`
  position: absolute;
  top: 40px;
  bottom: 0;
  left: -52px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${Label} {
    justify-content: center;
    transform: rotate(-90deg);
  }
`;

const PlannerDaysWrap = styled(Flexbox)`
  position: relative;

  ${({ timeFrame }) =>
    (timeFrame === TIME_FRAMES.week || timeFrame === TIME_FRAMES.day) &&
    css`
      min-height: calc(100% - 137px);
    `}

  @media (max-width: ${BREAKPOINTS.small}) {
    min-height: 0;
    border: 1px solid var(--neutral-170);
    padding-top: 8px;
  }
`;

const DatePickerStyled = styled(DatePickerWithMeals)`
  width: 300px;
  flex-shrink: 0;

  @media (max-width: ${BREAKPOINTS.medium}) {
    width: auto;
  }
`;

function Planner({
  timeFrame,
  setTimeFrame,
  plannerDays,
  goToday,
  goPrevPeriod,
  goNextPeriod,
  clearTimeFrame,
  periodLabel,
  shownDate,
  setShownDate,
}) {
  const { t } = useTranslation();
  const { mealPlan } = useMealPlan();
  const shownMeals = useMemo(() => mealPlan?.filter(meal => isSameDay(shownDate, meal.date)), [shownDate, mealPlan]);

  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);
  const shouldDisplayMobileUi = timeFrame === TIME_FRAMES.day || isScreenMaxSmall;

  return (
    <>
      {!isScreenMaxSmall && (
        <PlannerHeader
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
          plannerDays={plannerDays}
          goToday={goToday}
          goPrevPeriod={goPrevPeriod}
          goNextPeriod={goNextPeriod}
          clearTimeFrame={clearTimeFrame}
          periodLabel={periodLabel}
        />
      )}
      {!shouldDisplayMobileUi && (
        <PlannerDaysWrap timeFrame={timeFrame} flexWrap="wrap">
          {timeFrame === TIME_FRAMES.week && (
            <MealLabelsWrap>
              <Label variant="label" dotColor={MEAL_COLORS[MEAL_TYPES.breakfast]}>
                {t("Calendar.Breakfast")}
              </Label>
              <Label variant="label" dotColor={MEAL_COLORS[MEAL_TYPES.lunch]}>
                {t("Calendar.Lunch")}
              </Label>
              <Label variant="label" dotColor={MEAL_COLORS[MEAL_TYPES.dinner]}>
                {t("Calendar.Dinner")}
              </Label>
              <Label variant="label" dotColor={MEAL_COLORS[MEAL_TYPES.snacks]}>
                {t("Calendar.Snacks")}
              </Label>
            </MealLabelsWrap>
          )}
          {plannerDays}
        </PlannerDaysWrap>
      )}

      {shouldDisplayMobileUi && (
        <Flexbox
          gap={24}
          alignItems={isScreenMaxMedium ? "stretch" : "flex-start"}
          flexDirection={isScreenMaxMedium ? "column-reverse" : "row"}
        >
          <Flexbox flexDirection="column" flexGrow={1} isBordered>
            <MobileMealBlock date={shownDate} mealType={MEAL_TYPES.breakfast} dateMealItems={shownMeals} />
            <MobileMealBlock date={shownDate} mealType={MEAL_TYPES.lunch} dateMealItems={shownMeals} />
            <MobileMealBlock date={shownDate} mealType={MEAL_TYPES.dinner} dateMealItems={shownMeals} />
            <MobileMealBlock date={shownDate} mealType={MEAL_TYPES.snacks} dateMealItems={shownMeals} />
          </Flexbox>
          <DatePickerStyled
            timeFrame={isScreenMaxMedium ? TIME_FRAMES.week : TIME_FRAMES.month}
            selectedDate={shownDate}
            onChange={setShownDate}
          />
        </Flexbox>
      )}
    </>
  );
}

Planner.propTypes = {
  timeFrame: T.string,
  setTimeFrame: T.func,
  plannerDays: T.array,
  goToday: T.func,
  goPrevPeriod: T.func,
  goNextPeriod: T.func,
  clearTimeFrame: T.func,
  periodLabel: T.string,
  shownDate: T.instanceOf(Date),
  setShownDate: T.func,
};

export default Planner;
