import styled, { css } from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";

export const Marker = styled.div`
  font-family: "Ubuntu";
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.02em;
  color: var(--primary-100);
  width: 32px;
  flex-shrink: 0;
  //
  line-height: 28px;
  margin-top: -2px;
`;

export const Step = styled(Flexbox)`
  position: relative;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}

  strong {
    font-weight: 700;
  }

  b {
    font-weight: 700;
    color: var(--primary-100);
  }

  p {
    min-height: 26px;
  }

  .timer {
    position: relative;
    display: inline-flex;
    padding: 2px 8px 2px 20px;
    background: var(--primary-190);
    border-radius: var(--border-radius-1);
    color: var(--primary-100);
    vertical-align: top;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    vertical-align: baseline;
    cursor: default;

    &:before {
      content: "►";
      position: absolute;
      left: 6px;
      font-size: 12px;
    }

    ${({ onClick }) =>
      onClick &&
      css`
        cursor: pointer;
        &:hover {
          background: var(--primary-180);
        }
      `}
  }

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      .timer {
        background: var(--primary-180);
      }

      &:before {
        content: "";
        position: absolute;
        left: -8px;
        top: -4px;
        width: calc(100% + 16px);
        height: calc(100% + 8px);
        background-color: var(--primary-190);
        border: 1px solid var(--primary-180);
        z-index: var(--z-index-below);
        border-radius: var(--border-radius-2);
      }
    `}
`;

export const IngredientsBox = styled.div`
  padding: 20px 16px 24px;
  background-color: var(--neutral-190);
  border: 1px solid var(--neutral-180);
  border-radius: var(--border-radius-3);
  flex-shrink: 0;
  min-height: 200px;
`;

export const IngredientBox = styled(Flexbox)`
  padding: 4px 8px;
  border-radius: var(--border-radius-2);

  ${({ isHighlighted }) =>
    isHighlighted &&
    css`
      background-color: var(--primary-190);
      box-shadow: 0px 0px 0px 3px rgba(255, 214, 219, 0.5);
    `}
`;

export const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--primary-100);
  margin-top: 8px;
  flex-shrink: 0;
`;

export const Amount = styled(Typography)`
  white-space: nowrap;
`;

export const Unit = styled(Typography)`
  white-space: nowrap;
`;

export const Label = styled(Typography)`
  font-weight: 700;
`;

export const Note = styled(Typography)`
  margin-left: 10px;
`;
