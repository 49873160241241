import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components/macro";
import Tooltip from "commons/components/Tooltip";

const Container = styled.div`
  position: relative;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: ${({ maxLines }) => maxLines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function ClampedTextWithTooltip({ className, children, maxLines = 1 }) {
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef.current) {
      if (maxLines === 1) {
        setIsTextOverflowing(containerRef.current.scrollWidth > containerRef.current.offsetWidth);
      } else {
        setIsTextOverflowing(containerRef.current.scrollHeight > containerRef.current.offsetHeight);
      }
    }
  }, [maxLines, containerRef, children]);

  return (
    <Tooltip label={isTextOverflowing ? children : ""}>
      <Container className={className} ref={containerRef} maxLines={maxLines}>
        {children}
      </Container>
    </Tooltip>
  );
}

ClampedTextWithTooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  maxLines: PropTypes.number,
};

export default ClampedTextWithTooltip;
