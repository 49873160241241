export const presetIcons = [
  {
    category: "animals",
    name: "crab",
  },
  {
    category: "animals",
    name: "fish",
  },
  {
    category: "animals",
    name: "fish-1",
  },
  {
    category: "animals",
    name: "pearl",
  },
  {
    category: "animals",
    name: "shrimp",
  },
  {
    category: "appliances",
    name: "air-fryer",
  },
  {
    category: "appliances",
    name: "cooker",
  },
  {
    category: "appliances",
    name: "deep-fryer",
  },
  {
    category: "appliances",
    name: "fridge",
  },
  {
    category: "appliances",
    name: "fridge-1",
  },
  {
    category: "appliances",
    name: "juice-1",
  },
  {
    category: "appliances",
    name: "kettle",
  },
  {
    category: "appliances",
    name: "mixer",
  },
  {
    category: "appliances",
    name: "oven",
  },
  {
    category: "appliances",
    name: "oven-1",
  },
  {
    category: "appliances",
    name: "rice-cooker",
  },
  {
    category: "appliances",
    name: "thermomix",
  },
  {
    category: "appliances",
    name: "toaster",
  },
  {
    category: "appliances",
    name: "waffle",
  },
  {
    category: "drinks",
    name: "alcohol",
  },
  {
    category: "drinks",
    name: "beverage",
  },
  {
    category: "drinks",
    name: "cocktail",
  },
  {
    category: "drinks",
    name: "juice",
  },
  {
    category: "drinks",
    name: "sauce",
  },
  {
    category: "drinks",
    name: "sauce-bottle",
  },
  {
    category: "food",
    name: "appetizer",
  },
  {
    category: "food",
    name: "cheese",
  },
  {
    category: "food",
    name: "chicken-leg",
  },
  {
    category: "food",
    name: "cookie",
  },
  {
    category: "food",
    name: "egg",
  },
  {
    category: "food",
    name: "food",
  },
  {
    category: "food",
    name: "food-tray",
  },
  {
    category: "food",
    name: "hamburger",
  },
  {
    category: "food",
    name: "hive",
  },
  {
    category: "food",
    name: "ice-cream",
  },
  {
    category: "food",
    name: "loaf",
  },
  {
    category: "food",
    name: "milk",
  },
  {
    category: "food",
    name: "milk-box",
  },
  {
    category: "food",
    name: "noodle",
  },
  {
    category: "food",
    name: "peanut",
  },
  {
    category: "food",
    name: "pie",
  },
  {
    category: "food",
    name: "pizza",
  },
  {
    category: "food",
    name: "salad",
  },
  {
    category: "food",
    name: "sandwich",
  },
  {
    category: "food",
    name: "snack",
  },
  {
    category: "food",
    name: "soup",
  },
  {
    category: "misc",
    name: "baby",
  },
  {
    category: "misc",
    name: "baby-boy",
  },
  {
    category: "misc",
    name: "bone",
  },
  {
    category: "misc",
    name: "canned-food",
  },
  {
    category: "misc",
    name: "coins",
  },
  {
    category: "misc",
    name: "defrost",
  },
  {
    category: "misc",
    name: "diner",
  },
  {
    category: "misc",
    name: "dinner",
  },
  {
    category: "misc",
    name: "gluten-free",
  },
  {
    category: "misc",
    name: "healthcare",
  },
  {
    category: "misc",
    name: "heart",
  },
  {
    category: "misc",
    name: "hearts",
  },
  {
    category: "misc",
    name: "money-bag",
  },
  {
    category: "misc",
    name: "morning",
  },
  {
    category: "misc",
    name: "no-sugar",
  },
  {
    category: "misc",
    name: "pacifier",
  },
  {
    category: "misc",
    name: "sleep",
  },
  {
    category: "misc",
    name: "snowflake",
  },
  {
    category: "misc",
    name: "speedometer",
  },
  {
    category: "misc",
    name: "speedometer-1",
  },
  {
    category: "misc",
    name: "speedometer-2",
  },
  {
    category: "misc",
    name: "starry-night",
  },
  {
    category: "misc",
    name: "sunny",
  },
  {
    category: "misc",
    name: "wind",
  },
  {
    category: "occasions",
    name: "balloon",
  },
  {
    category: "occasions",
    name: "bbq",
  },
  {
    category: "occasions",
    name: "birthday-cake",
  },
  {
    category: "occasions",
    name: "christmas-tree",
  },
  {
    category: "occasions",
    name: "clapperboard",
  },
  {
    category: "occasions",
    name: "confetti",
  },
  {
    category: "occasions",
    name: "easter-egg",
  },
  {
    category: "occasions",
    name: "fireworks",
  },
  {
    category: "occasions",
    name: "gingerbread-man",
  },
  {
    category: "produce",
    name: "apple",
  },
  {
    category: "produce",
    name: "artichoke",
  },
  {
    category: "produce",
    name: "avocado",
  },
  {
    category: "produce",
    name: "beans",
  },
  {
    category: "produce",
    name: "carrot",
  },
  {
    category: "produce",
    name: "cereals",
  },
  {
    category: "produce",
    name: "eco-friendly",
  },
  {
    category: "produce",
    name: "mint",
  },
  {
    category: "produce",
    name: "nut",
  },
  {
    category: "produce",
    name: "pumpkin",
  },
  {
    category: "produce",
    name: "rambutan",
  },
  {
    category: "produce",
    name: "toadstool",
  },
  {
    category: "sweets",
    name: "dessert",
  },
  {
    category: "sweets",
    name: "lollipop",
  },
  {
    category: "sweets",
    name: "lollipop-1",
  },
  {
    category: "sweets",
    name: "sweets",
  },
];
