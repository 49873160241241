import React, { useEffect, useMemo, useRef, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import TextInput from "commons/components/TextInput";
import Button from "commons/components/Button";
import { predefinedStaples } from "../predefinedStaples.const";
import SuggestedItem from "./SuggestedItem";
import { BREAKPOINTS } from "commons/util/breakpoints";
import { useMediaQuery } from "commons/util/useMediaQuery";
import callApi from "commons/util/callApi";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { SkeletonArray } from "commons/components/Skeleton/Skeleton";

const Box = styled.aside`
  width: 340px;
  flex-shrink: 0;
  height: calc(100vh - 52px);
  background-color: var(--neutral-200);

  @media (max-width: ${BREAKPOINTS.xlarge}) {
    width: 280px;
  }

  @media (max-width: ${BREAKPOINTS.medium}) {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    height: auto;
    z-index: var(--z-index-0);
    box-shadow: var(--shadow-3);
  }
`;

const SuggestedItemsTitle = styled(Typography)`
  font-weight: 600;
`;

const Suggestions = styled(Flexbox)`
  border-radius: var(--border-radius-0);
  height: calc(100% - 144px);
  border-top: 1px solid var(--neutral-180);

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: auto;
  }
`;

const SuggestedItemsWrap = styled(Flexbox)`
  height: 100%;
  overflow-y: auto;

  @media (max-width: ${BREAKPOINTS.medium}) {
    height: auto;
    flex-direction: row;
  }
`;

function SuggestionListSidebar({ groceryList, isGroceryListLoading, setGroceryList, addToGroceryHistory }) {
  const { t } = useTranslation();
  const textInputRef = useRef();
  const [inputValue, setInputValue] = useState(""); // used only in the input
  const [newItemLabel, setNewItemLabel] = useState(""); // lowercase input value, used everywhere else
  const [highlightedSuggestion, setHighlightedSuggestion] = useState("");
  const [suggestedItems, setSuggestedItems] = useState([]);
  const isScreenMaxMedium = useMediaQuery(BREAKPOINTS.medium);
  const [userStaples, setUserStaples] = useState([]);
  const [isUserStaplesLoading, setIsUserStaplesLoading] = useState(true);

  useEffect(() => {
    async function fetchUserStapleHistory() {
      try {
        const res = await callApi("groceries/user-staples", "get");
        setUserStaples([...res.userStaples, ...res.dormantItems]);
        setSuggestedItems(() => _.uniq([...res.userStaples, ...predefinedStaples, ...res.dormantItems]));
        setIsUserStaplesLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
    fetchUserStapleHistory();
  }, []);

  const filteredSuggestedItems = useMemo(() => {
    if (newItemLabel) {
      return suggestedItems
        .filter(el => el.includes(newItemLabel))
        .filter(el => newItemLabel || !groceryList.map(item => item.label).includes(el))
        .sort((a, b) => {
          // user staples come first
          const userStaplesIndexA = userStaples.indexOf(a);
          const userStaplesIndexB = userStaples.indexOf(b);
          if (userStaplesIndexA !== -1 && userStaplesIndexB !== -1) {
            return userStaplesIndexA - userStaplesIndexB;
          }
          if (userStaplesIndexA !== -1) return -1;
          if (userStaplesIndexB !== -1) return 1;

          // then predefined staples that start with the search phrase, compared to the ones that only contain it
          const startsWithSearchPhraseA = a.startsWith(newItemLabel);
          const startsWithSearchPhraseB = b.startsWith(newItemLabel);
          if (startsWithSearchPhraseA !== startsWithSearchPhraseB) {
            return startsWithSearchPhraseA ? -1 : 1;
          }

          // sorted by length
          return a.length - b.length;
        })
        .slice(0, 30);
    } else {
      return suggestedItems.filter(el => !groceryList.map(item => item.label).includes(el)).slice(0, 30);
    }
  }, [suggestedItems, newItemLabel, groceryList, isGroceryListLoading]);

  function toggleGroceryListItem(id, label) {
    const isAdded = groceryList.map(el => el.label).includes(label);

    let newGroceryList;
    if (isAdded) {
      newGroceryList = groceryList.filter(el => el.id !== id);
    } else {
      const newIngredient = {
        id,
        label,
      };
      newGroceryList = [newIngredient, ...groceryList];
      addToGroceryHistory(label);
    }

    setGroceryList(newGroceryList);
    onInputValueChange("");
    textInputRef.current.focus();
  }

  function addCustomGroceryListItem() {
    const newIngredient = {
      id: Math.random().toString(),
      label: highlightedSuggestion,
    };

    const isAdded = groceryList.map(el => el.label).includes(highlightedSuggestion);

    let newGroceryList;
    if (isAdded) {
      newGroceryList = groceryList.filter(el => el.label !== highlightedSuggestion);
    } else {
      newGroceryList = [newIngredient, ...groceryList];
      addToGroceryHistory(highlightedSuggestion);
    }

    setGroceryList(newGroceryList);
    onInputValueChange("");
    textInputRef.current.focus();
  }

  function handleKeyDown(e) {
    const { key } = e;

    if (key === "ArrowDown") {
      e.preventDefault();
      const highlightedIndex = filteredSuggestedItems.indexOf(highlightedSuggestion);

      if (highlightedIndex !== filteredSuggestedItems.length - 1) {
        setHighlightedSuggestion(filteredSuggestedItems[highlightedIndex + 1]);
      } else {
        setHighlightedSuggestion(newItemLabel);
      }
    }

    if (key === "ArrowUp") {
      e.preventDefault();
      const highlightedIndex = filteredSuggestedItems.indexOf(highlightedSuggestion);

      if (highlightedIndex > 0) {
        setHighlightedSuggestion(filteredSuggestedItems[highlightedIndex - 1]);
      } else if (highlightedIndex !== -1) {
        setHighlightedSuggestion(newItemLabel);
      } else {
        setHighlightedSuggestion(filteredSuggestedItems[filteredSuggestedItems.length - 1]);
      }
    }
  }

  function onInputValueChange(newLabel) {
    setInputValue(newLabel);
    setNewItemLabel(newLabel.toLowerCase().trim());
    setHighlightedSuggestion(newLabel.toLowerCase().trim());
  }

  return (
    <Box>
      {!isScreenMaxMedium && (
        <Flexbox padding={24}>
          <Typography variant="h3">{t("Groceries.AddItems")}</Typography>
        </Flexbox>
      )}
      <Flexbox padding={isScreenMaxMedium ? 16 : "0 24px 24px 24px"} gap={12} alignItems="flex-end">
        <TextInput
          ref={textInputRef}
          value={inputValue}
          onChange={newLabel => onInputValueChange(newLabel)}
          onConfirm={addCustomGroceryListItem}
          placeholder={isScreenMaxMedium ? t("Groceries.AddGroceryItem") : t("Groceries.Item")}
          autoCapitalize="off"
          onKeyDown={handleKeyDown}
          rightIcon={inputValue ? "close" : ""}
          onRightIconClick={() => onInputValueChange("")}
          autoFocus
          maxLength={64}
        />
        <Button onClick={addCustomGroceryListItem} size="medium" disabled={!newItemLabel}>
          {t("Common.Add")}
        </Button>
      </Flexbox>
      <Suggestions flexDirection="column">
        {!isScreenMaxMedium && (
          <SuggestedItemsTitle margin={"16px 24px 8px"}>{t("Groceries.Suggested")}</SuggestedItemsTitle>
        )}
        <SuggestedItemsWrap
          flexDirection="column"
          alignItems={isScreenMaxMedium ? "flex-start" : "stretch"}
          gap={isScreenMaxMedium ? 8 : 2}
          padding={isScreenMaxMedium ? "12px" : "16px 24px"}
        >
          {isUserStaplesLoading || isGroceryListLoading ? (
            <Flexbox flexDirection="column" gap={6}>
              <SkeletonArray count={30} width="100%" height="28px" />
            </Flexbox>
          ) : (
            <>
              {newItemLabel && !suggestedItems.includes(newItemLabel) && (
                <SuggestedItem
                  id={newItemLabel}
                  label={newItemLabel}
                  isAdded={groceryList.map(el => el.label).includes(newItemLabel)}
                  isHighlighted={highlightedSuggestion === newItemLabel}
                  toggleGroceryListItem={toggleGroceryListItem}
                  newItemLabel={newItemLabel}
                />
              )}
              {filteredSuggestedItems.map(item => {
                const isAdded = groceryList.map(el => el.label).includes(item);
                return (
                  <SuggestedItem
                    key={item}
                    id={item}
                    label={item}
                    isAdded={isAdded}
                    isHighlighted={highlightedSuggestion === item}
                    toggleGroceryListItem={toggleGroceryListItem}
                    newItemLabel={newItemLabel}
                  />
                );
              })}
            </>
          )}
        </SuggestedItemsWrap>
      </Suggestions>
    </Box>
  );
}

SuggestionListSidebar.propTypes = {
  groceryList: T.array,
  isGroceryListLoading: T.bool,
  setGroceryList: T.func,
  addToGroceryHistory: T.func,
};

export default SuggestionListSidebar;
