import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Tag from "commons/components/Tag";
import { useLocationBase } from "commons/util/useLocationBase";
import { Link } from "react-router-dom";
import Flexbox from "commons/components/Flexbox";
import Typography from "commons/components/Typography";
import Image from "commons/components/Image";
import ActionList from "commons/components/ActionList";
import { useTranslation } from "react-i18next";
import { useMealPlan, useMealPlanDispatch } from "App/MealPlanContext";
import { getNextId } from "commons/util/helpers";
import { BREAKPOINTS } from "commons/util/breakpoints";

const Box = styled(Flexbox)`
  padding: 12px;
  max-width: 356px;
`;

const ImageAndTags = styled(Flexbox)`
  width: 168px;

  @media (max-width: ${BREAKPOINTS.small}) {
    width: 148px;
  }
`;

const RecipeCardImage = styled(Image)`
  background-color: var(--neutral-190);
  border-radius: var(--border-radius-2);
`;

const Title = styled(Typography)`
  position: relative;
`;

const ExtraTag = styled(Tag)`
  width: 20px;
  height: 20px;
  padding: 0;
`;

const ActionListStyled = styled(ActionList)`
  .action-list-item {
    margin: 0;
  }
`;

function MealItemPreview({ className, recipe, mealItemId, handleClose }) {
  const { t } = useTranslation();
  const [width, setWidth] = useState(0);
  const boxRef = useRef(null);
  const locationBase = useLocationBase();
  const { mealPlan } = useMealPlan();
  const dispatch = useMealPlanDispatch();

  const maxNumberOfTags = useMemo(() => {
    return Math.floor((width + 4) / 24);
  }, [width]);

  useLayoutEffect(() => {
    setWidth(boxRef.current?.offsetWidth);
  }, []);

  function duplicateMealPlanItem() {
    const meal = mealPlan.find(m => m.id === mealItemId);
    const newMealPlanItem = {
      ...meal,
      id: String(getNextId(mealPlan)),
    };
    dispatch({ type: "ADD", mealItem: newMealPlanItem });
    handleClose();
  }

  function removeItemFromMealPlan() {
    dispatch({ type: "REMOVE", mealItemId: mealItemId });
    handleClose();
  }

  return (
    <Box className={className} flexDirection="column" gap={8}>
      <Title variant="h4">{recipe.title}</Title>
      <Flexbox gap={12}>
        <ImageAndTags flexDirection="column" gap={8}>
          <Link to={`${locationBase}/${recipe.id}`} onClick={e => e.stopPropagation()}>
            <RecipeCardImage src={recipe.imageURL} height="144px" width="100%" alt={recipe.title} isRemote />
          </Link>
          {recipe.tags && recipe.tags.length > 0 && (
            <Flexbox flexWrap="wrap" gap={4} marginTop="auto" ref={boxRef}>
              {recipe.tags
                .slice(0, recipe.tags.length > maxNumberOfTags ? maxNumberOfTags - 1 : maxNumberOfTags)
                .map(tag => {
                  return <Tag key={tag.id} icon={tag.icon} label={tag.label} color={tag.color} isCompact />;
                })}
              {recipe.tags.length > maxNumberOfTags && (
                <ExtraTag label={`+${recipe.tags.length - maxNumberOfTags}`} color="grey" isCompact />
              )}
            </Flexbox>
          )}
        </ImageAndTags>

        <ActionListStyled
          actions={[
            { icon: "visibility", label: t("Calendar.ViewRecipe"), link: `${locationBase}/${recipe.id}` },
            {
              icon: "play_arrow",
              label: t("Recipes.StartCooking"),
              onClick: () => window.open(`/app/cooking-mode/${recipe.id}`, "_blank"),
            },
            { icon: "content_copy", label: t("Common.Duplicate"), onClick: duplicateMealPlanItem },
            { icon: "event_busy", label: t("Common.Remove"), onClick: removeItemFromMealPlan },
          ]}
        />
      </Flexbox>
    </Box>
  );
}

MealItemPreview.propTypes = {
  className: T.string,
  recipe: T.object,
  handleClose: T.func,
  mealItemId: T.oneOfType([T.number, T.string]),
};

export default MealItemPreview;
