import React from "react";
import T from "prop-types";
import Flexbox from "commons/components/Flexbox";
import { IngredientBox, Amount, Circle, Label, Note, Unit } from "App/Recipes/components/Recipes.styles";

function Ingredient({ className, amount, unit, label, note, isHighlighted }) {
  return (
    <IngredientBox className={className} flexDirection="column" gap={4} isHighlighted={isHighlighted}>
      <Flexbox gap={6}>
        <Circle />
        <p>
          {(!!amount || unit) && (
            <>
              {!!amount && (
                <Amount forwardedAs="span" variant="body">
                  {amount}
                </Amount>
              )}
              {unit && (
                <Unit forwardedAs="span" variant="body" marginLeft={amount ? 2 : 0}>
                  {unit}
                </Unit>
              )}
            </>
          )}
          <Label forwardedAs="span" variant="body" marginLeft={!!amount || unit ? 6 : 0} color="primary-100">
            {label}
          </Label>
        </p>
      </Flexbox>
      {note && (
        <Note variant="body" color="neutral-160">
          {note}
        </Note>
      )}
    </IngredientBox>
  );
}

Ingredient.propTypes = {
  className: T.string,
  amount: T.oneOfType([T.number, T.string]),
  unit: T.string,
  label: T.string.isRequired,
  note: T.string,
  isHighlighted: T.bool,
};

export default Ingredient;
