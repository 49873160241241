import React from "react";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import Setting from "../components/Setting";
import { useAuth } from "App/AuthContext";
import NameSettingContent from "./NameSettingContent";
import EmailSettingContent from "./EmailSettingContent";
import PasswordSettingContent from "./PasswordSettingContent";
import Divider from "commons/components/Divider";
import DeleteAccount from "./DeleteAccount";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import PlanSettingContent from "./PlanSettingContent";

function Account() {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <>
      <Helmet>
        <title>{t("Navigation.Account")} | Cook Your Goose</title>
      </Helmet>
      <Typography variant="h3">{t("Settings.Account")}</Typography>
      <Flexbox flexDirection="column" gap={8} marginTop={24} isBordered>
        <Setting label={t("Common.Name")} type="custom" content={<NameSettingContent value={user.name} />} />
        <Setting label={t("Common.Email")} type="custom" content={<EmailSettingContent value={user.email} />} />
        <Setting label={t("Common.Password")} type="custom" content={<PasswordSettingContent />} />
        <Divider />
        <Setting label={t("Settings.Plan")} type="custom" content={<PlanSettingContent value={user.accountType} />} />
        <Divider />
        <DeleteAccount />
      </Flexbox>
    </>
  );
}

export default Account;
