import React, { useCallback, useRef } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Typography from "commons/components/Typography";
import Flexbox from "commons/components/Flexbox";
import AddButton from "commons/components/AddButton";

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import Ingredient from "./Ingredient";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import NumberStepper from "commons/components/NumberStepper";
import Tooltip from "commons/components/Tooltip";
import { IngredientsBox } from "App/Recipes/components/Recipes.styles";
import { useTranslation } from "react-i18next";

const NumberStepperStyled = styled(NumberStepper)`
  display: inline-flex;
`;

function Ingredients({
  className,
  servings,
  setServings,
  ingredients,
  setIngredients,
  addIngredient,
  addIngredientCategory,
  setIngredient,
  setIngredientCategory,
  deleteIngredient,
}) {
  const { t } = useTranslation();
  const ingredientRefs = useRef([]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setIngredients(() => {
        const oldIndex = ingredients.findIndex(el => el.id === active.id);
        const newIndex = ingredients.findIndex(el => el.id === over.id);
        return arrayMove(ingredients, oldIndex, newIndex);
      });
    }
  }

  const handleKeyDown = useCallback(
    (e, inputNumber, i) => {
      const { key, target } = e;

      if (key === "ArrowLeft" && target.selectionStart === 0) {
        e.stopPropagation();
        e.preventDefault();
        ingredientRefs.current[i]?.querySelectorAll("input")[inputNumber - 1]?.select();
      }
      if (key === "ArrowRight" && target.selectionStart === target.value.length) {
        e.stopPropagation();
        e.preventDefault();
        ingredientRefs.current[i]?.querySelectorAll("input")[inputNumber + 1]?.select();
      }

      if (key === "ArrowUp") {
        const directNode = ingredientRefs.current[i - 1]?.querySelectorAll("input")[inputNumber];
        const zeroNode = ingredientRefs.current[i - 1]?.querySelectorAll("input")[0];

        e.stopPropagation();
        e.preventDefault();

        if (directNode) {
          directNode.select();
        } else if (zeroNode) {
          zeroNode.select();
        }
      }

      if (key === "ArrowDown" || key === "Enter") {
        const directNode = ingredientRefs.current[i + 1]?.querySelectorAll("input")[inputNumber];
        const zeroNode = ingredientRefs.current[i + 1]?.querySelectorAll("input")[0];

        e.stopPropagation();
        e.preventDefault();

        if (directNode) {
          directNode.select();
        } else if (zeroNode) {
          zeroNode.select();
        }

        if (i === ingredients.length - 1) {
          addIngredient();
          setTimeout(() => {
            ingredientRefs.current[i + 1]?.querySelectorAll("input")[0].select();
          }, [0]);
        }
      }
    },
    [ingredientRefs, ingredients.length]
  );

  return (
    <IngredientsBox className={className}>
      <Flexbox justifyContent="space-between" alignItems="center" gap={8} flexWrap="wrap" marginBottom={16}>
        <Typography variant="h3">{t("Recipes.Ingredients")}</Typography>
        <Tooltip label={t("Recipes.Servings")}>
          <NumberStepperStyled value={servings} onChange={setServings} />
        </Tooltip>
      </Flexbox>
      <Flexbox flexDirection="column" gap={8}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext items={ingredients} strategy={verticalListSortingStrategy}>
            {ingredients.map((ingredient, i) => (
              <Ingredient
                key={ingredient.id}
                ref={el => (ingredientRefs.current[i] = el)}
                id={ingredient.id}
                isCategory={ingredient.isCategory}
                amount={ingredient.amount}
                unit={ingredient.unit}
                label={ingredient.label}
                note={ingredient.note}
                ingredient={ingredient}
                className={className}
                setIngredient={setIngredient}
                setIngredientCategory={setIngredientCategory}
                deleteIngredient={id => deleteIngredient(id)}
                handleKeyDown={(e, inputNumber) => handleKeyDown(e, inputNumber, i)}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Flexbox>
      <Flexbox gap={16} marginTop={16} flexWrap="wrap">
        <AddButton onClick={addIngredient}>{t("Recipes.AddIngredient")}</AddButton>
        <AddButton onClick={addIngredientCategory}>{t("Recipes.AddCategory")}</AddButton>
      </Flexbox>
    </IngredientsBox>
  );
}

Ingredients.propTypes = {
  className: T.string,
  servings: T.number,
  setServings: T.func,
  ingredients: T.array,
  setIngredients: T.func,
  addIngredient: T.func,
  addIngredientCategory: T.func,
  setIngredient: T.func,
  setIngredientCategory: T.func,
  deleteIngredient: T.func,
};

export default Ingredients;
