import React, { useEffect, useState } from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import { BREAKPOINTS } from "commons/util/breakpoints";
import Image from "commons/components/Image";
import { useMediaQuery } from "commons/util/useMediaQuery";
import { getOriginalImageDimensions } from "commons/util/helpers";

const ImageBoxWithBlur = styled.div`
  position: relative;
  height: ${({ $height }) => $height};

  // regular image
  & > div:first-child {
    img {
      margin-left: auto;
      margin-right: auto;
      min-width: 50%;
    }
  }

  // blurred image
  & > div:last-child {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    overflow: hidden;

    img {
      width: calc(100% + 96px);
      filter: blur(40px);
    }
  }
`;

const MAX_CONTAINER_WIDTH = 1028;

function RecipeImage({ className, width, height, src, onClick, ...rest }) {
  const isScreenMaxSmall = useMediaQuery(BREAKPOINTS.small);
  const [originalWidth, setOriginalWidth] = useState(0);
  const [originalHeight, setOriginalHeight] = useState(0);

  const screenWidth = window.screen.width;

  useEffect(() => {
    getOriginalImageDimensions(src, (ogWidth, ogHeight) => {
      setOriginalWidth(ogWidth);
      setOriginalHeight(ogHeight);
    });
  }, [src]);

  if (
    // image is taller than it is wide, and narrower than the max container width
    ((originalWidth < originalHeight && originalWidth < Math.max(MAX_CONTAINER_WIDTH, screenWidth / 2)) ||
      // image is wider than it is tall, and narrower than 640px (arbitrary)
      (originalWidth >= originalHeight && originalWidth < 640)) &&
    // screen is not small
    !isScreenMaxSmall
  ) {
    return (
      <ImageBoxWithBlur className={className} $height={height} onClick={onClick}>
        <Image src={src} width="auto" height={height} {...rest} />
        <Image src={src} width="auto" height={height} {...rest} />
      </ImageBoxWithBlur>
    );
  } else {
    return <Image className={className} src={src} width={width} height={height} onClick={onClick} {...rest} />;
  }
}

RecipeImage.propTypes = {
  className: T.string,
  width: T.string,
  height: T.string,
  src: T.string,
  onClick: T.func,
};

export default RecipeImage;
