import React from "react";
import T from "prop-types";
import styled from "styled-components/macro";
import Modal from "commons/components/Modal";
import { useNavigate, useParams } from "react-router-dom";
import { useLocationBase } from "commons/util/useLocationBase";
import AddItemPopoverContent from "App/Calendar/Planner/components/AddItemPopoverContent";

const ModalStyled = styled(Modal)`
  max-width: 100%;
  width: 350px;
  height: auto;
`;

function AddToCalendarModal({ className }) {
  const navigate = useNavigate();
  const locationBase = useLocationBase();
  const recipeId = useParams().recipeId;

  function closeModal() {
    navigate(locationBase);
  }

  return (
    <ModalStyled className={className} handleClose={closeModal}>
      <AddItemPopoverContent recipeId={recipeId} handleClose={closeModal} />
    </ModalStyled>
  );
}

AddToCalendarModal.propTypes = {
  className: T.string,
};

export default AddToCalendarModal;
