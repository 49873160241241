import React from "react";
import T from "prop-types";
import Flexbox from "commons/components/Flexbox";
import Popover from "commons/components/Popover";
import Tag from "commons/components/Tag";
import TagEditPopoverContent from "./TagEditPopoverContent";
import ActionList from "commons/components/ActionList";
import Typography from "commons/components/Typography";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

const Box = styled.div`
  cursor: grab;
  border-radius: var(--border-radius-1);
  padding-right: 4px;
  margin-right: -4px;

  &:hover {
    background-color: var(--neutral-190);
  }
`;

function TagRow({
  className,
  id,
  icon,
  label,
  color,
  count,
  editedTagId,
  groupId,
  updateTag,
  setEditedTagId,
  deleteTag,
}) {
  const { t } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const openTaggedRecipeListInNewTab = tagId => {
    window.open(`/app/recipes?tagId=${tagId}`, "_blank");
  };

  return (
    <Box className={className} ref={setNodeRef} style={style} {...attributes} {...listeners} tabIndex="-1">
      <Flexbox className={className} justifyContent="space-between" alignItems="center" padding={2} gap={8}>
        <Popover
          trigger={<Tag icon={icon} label={label} color={color} />}
          content={closePopover =>
            editedTagId === id ? (
              <TagEditPopoverContent
                id={id}
                label={label}
                color={color}
                icon={icon}
                updateTag={tagData => updateTag(groupId, id, tagData)}
                closePopover={closePopover}
              />
            ) : (
              <ActionList
                actions={[
                  {
                    label: t("Settings.SeeRecipes"),
                    icon: "open_in_new",
                    disabled: !count,
                    onClick: () => openTaggedRecipeListInNewTab(id),
                  },
                  {
                    label: t("Common.Edit"),
                    icon: "edit",
                    onClick: () => setTimeout(() => setEditedTagId(id), 0),
                  },
                  {
                    variant: "divider",
                  },
                  {
                    label: t("Common.Delete"),
                    icon: "delete",
                    variant: count ? "destructive" : "default",
                    onClick: () => deleteTag(groupId, id),
                  },
                ]}
              />
            )
          }
          placement="bottom-start"
          onClose={() => setEditedTagId(null)}
          isTriggerTabbable
        />
        <Typography variant="label" color="neutral-160">
          {!!count && count}
        </Typography>
      </Flexbox>
    </Box>
  );
}

TagRow.propTypes = {
  className: T.string,
  id: T.number,
  icon: T.string,
  label: T.string,
  color: T.string,
  count: T.number,
  editedTagId: T.number,
  groupId: T.number,
  updateTag: T.func,
  setEditedTagId: T.func,
  deleteTag: T.func,
};

export default TagRow;
